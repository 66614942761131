@import '@angular/cdk/a11y-prebuilt.css';
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 901;
}
.toast-container * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  border-radius: 0.8rem;
  padding: 1.6rem;
  margin: 0.8rem;
  margin-top: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 400;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: nowrap row;
  flex-flow: nowrap row;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  pointer-events: auto;
  cursor: pointer;
  white-space: pre-line;
}
.toast-container .ngx-toastr .toast-title {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  font-weight: bold;
  width: 100%;
}
.toast-container .ngx-toastr .toast-message {
  margin-left: 0.4rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  word-break: break-word;
}
.toast-container .ngx-toastr .toast-close-button {
  padding: 1.6rem;
  background: transparent;
  border: 0;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 600;
  letter-spacing: -0.14px;
  border-radius: 0.8rem;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  margin-left: auto;
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
}
.toast-container .ngx-toastr .toast-close-button span {
  display: none;
}
.toast-container .ngx-toastr .toast-close-button::before {
  font-family: "Font Awesome 6 Free" !important;
  /* FontAwesome fa... styling ======= */
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f00d";
  font-weight: 900;
  -webkit-transition: color 200ms ease-in-out;
  transition: color 200ms ease-in-out;
}
.toast-container .ngx-toastr .toast-close-button:hover, .toast-container .ngx-toastr .toast-close-button:focus {
  text-decoration: none;
  outline: none;
}
.toast-container .ngx-toastr::before {
  font-family: "Font Awesome 6 Free" !important;
  /* FontAwesome fa... styling ======= */
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  padding: 0.8rem;
}
.toast-container .toast-success {
  color: #1c5352;
  background-color: #d2f5ee;
}
.toast-container .toast-success::before,
.toast-container .toast-success .toast-close-button {
  color: #2ba198;
}
.toast-container .toast-success:hover .toast-close-button::before, .toast-container .toast-success:focus .toast-close-button::before {
  color: #1c5352;
}
.toast-container .toast-success .toast-close-button:focus, .toast-container .toast-success .toast-close-button:active {
  -webkit-box-shadow: 0 0 0 0.2rem #64d3c5;
  box-shadow: 0 0 0 0.2rem #64d3c5;
}
.toast-container .toast-success .toast-close-button:active {
  background-color: #a6e9de;
}
.toast-container .toast-info {
  color: #303d74;
  background-color: #b9c7fb;
}
.toast-container .toast-info::before,
.toast-container .toast-info .toast-close-button {
  color: #3456d0;
}
.toast-container .toast-info:hover .toast-close-button::before, .toast-container .toast-info:focus .toast-close-button::before {
  color: #303d74;
}
.toast-container .toast-info .toast-close-button:focus, .toast-container .toast-info .toast-close-button:active {
  -webkit-box-shadow: 0 0 0 0.2rem #7491f7;
  box-shadow: 0 0 0 0.2rem #7491f7;
}
.toast-container .toast-info .toast-close-button:active {
  background-color: #96acf9;
}
.toast-container .toast-error {
  color: #a8092d;
  background-color: #ffe2e4;
}
.toast-container .toast-error::before,
.toast-container .toast-error .toast-close-button {
  color: #ff485e;
}
.toast-container .toast-error:hover .toast-close-button::before, .toast-container .toast-error:focus .toast-close-button::before {
  color: #a8092d;
}
.toast-container .toast-error .toast-close-button:focus, .toast-container .toast-error .toast-close-button:active {
  -webkit-box-shadow: 0 0 0 0.2rem #ff9fa6;
  box-shadow: 0 0 0 0.2rem #ff9fa6;
}
.toast-container .toast-error .toast-close-button:active {
  background-color: #ffcace;
}
.toast-container .toast-warning {
  color: #9b4911;
  background-color: #fff4d5;
}
.toast-container .toast-warning::before,
.toast-container .toast-warning .toast-close-button {
  color: #fb9814;
}
.toast-container .toast-warning:hover .toast-close-button::before, .toast-container .toast-warning:focus .toast-close-button::before {
  color: #9b4911;
}
.toast-container .toast-warning .toast-close-button:focus, .toast-container .toast-warning .toast-close-button:active {
  -webkit-box-shadow: 0 0 0 0.2rem #fed173;
  box-shadow: 0 0 0 0.2rem #fed173;
}
.toast-container .toast-warning .toast-close-button:active {
  background-color: #ffe5a9;
}
.toast-container .toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
.toast-top-full-width .ngx-toastr, .toast-bottom-full-width .ngx-toastr {
  width: 96%;
}
.toast-center-center {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 45px;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* Responsive Design */
@media (min-width: 576px) {
  .toast-top-center .ngx-toastr, .toast-bottom-center .ngx-toastr, .toast-top-full-width .ngx-toastr, .toast-bottom-full-width .ngx-toastr {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .toast-container .ngx-toastr {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .toast-container .ngx-toastr {
    width: 40%;
  }
}
@media (min-width: 1200px) {
  .toast-container .ngx-toastr {
    width: 30%;
  }
}
.tox .tox-editor-container .tox-menu,
.tox .tox-editor-container .tox-pop > .tox-pop__dialog {
  background-color: #fcfcfd !important;
  border-radius: 1rem !important;
  border: 0.1rem solid #dfe3ec !important;
  margin: 0 !important;
  max-height: 40rem !important;
  overflow: auto !important;
  min-width: 15rem !important;
  max-width: 100% !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-weight: 400 !important;
  -webkit-transition: opacity 0.1s ease-in-out !important;
  -o-transition: opacity 0.1s ease-in-out !important;
  transition: opacity 0.1s ease-in-out !important;
  -webkit-transition-delay: 0.5ms !important;
  -o-transition-delay: 0.5ms !important;
  transition-delay: 0.5ms !important;
  font-family: "Inter", sans-serif !important;
  font-size: 1.4rem !important;
  line-height: 1.6rem !important;
}
.tox .tox-editor-container .tox-menu {
  padding: 1.5rem !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.tox .tox-editor-container .tox-pop > .tox-pop__dialog {
  padding: 1rem !important;
  min-width: 0 !important;
}
.tox .tox-editor-container .tox-collection__item,
.tox .tox-editor-container .tox-pop .tox-tbtn {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  color: #4e586f !important;
  border-radius: 0.5rem !important;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out !important;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out !important;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out !important;
  font-family: "Inter", sans-serif !important;
  font-size: 1.4rem !important;
  line-height: 1.6rem !important;
  font-weight: 400 !important;
  padding: 1rem !important;
  border: none !important;
}
.tox .tox-editor-container .tox-collection__item > .tox-collection__item-label, .tox .tox-editor-container .tox-collection__item > .tox-tbtn.tox-tbtn--select,
.tox .tox-editor-container .tox-pop .tox-tbtn > .tox-collection__item-label,
.tox .tox-editor-container .tox-pop .tox-tbtn > .tox-tbtn.tox-tbtn--select {
  line-height: 1 !important;
  padding: 2px 4px !important;
}
.tox .tox-editor-container .tox-collection__item:hover,
.tox .tox-editor-container .tox-pop .tox-tbtn:hover {
  color: #303d74 !important;
  background-color: #dce4fd !important;
}
.tox .tox-editor-container .tox-pop .tox-tbtn {
  height: auto !important;
}
.tox .tox-editor-container .tox-edit-area__iframe,
.tox .tox-editor-container .tox .tox-toolbar {
  background-color: inherit !important;
}
.tox .tox-editor-container .tox-tinymce {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;
  letter-spacing: 0.14px;
  border: none;
}
.tox .tox-editor-container .tox-editor-header {
  border-bottom: 0.1rem solid #dfe3ec !important;
  padding: 0.2rem 0;
}
.tox .tox-editor-container .tox-toolbar,
.tox .tox-editor-container .tox-toolbar__primary {
  background-color: inherit;
  background: inherit;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.tox .tox-editor-container .tox-tbtn {
  padding: 1rem !important;
  width: auto !important;
}
.tox .tox-editor-container .tox .tox-tbtn--bespoke .tox-tbtn__select-label {
  width: auto !important;
}
.tox .tox-editor-container .tox-tbtn.tox-tbtn--select:last-of-type {
  font-weight: 700 !important;
}
.tox .tox-editor-container .tox-menu-nav__js.tox-collection__item:has(pre) {
  display: none !important;
}

.spinner--sm {
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #dce4fd;
  border-top: 2px solid #3d64ed;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
  width: 1.2rem;
  height: 1.2rem;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner--md {
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #dce4fd;
  border-top: 2px solid #3d64ed;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
  width: 2.4rem;
  height: 2.4rem;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner--lg {
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #dce4fd;
  border-top: 2px solid #3d64ed;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
  width: 3.2rem;
  height: 3.2rem;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.responsive-img__wrapper {
  display: inline-block;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  position: relative;
  overflow: hidden;
}
.responsive-img__img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -o-object-fit: cover;
  object-fit: cover;
  height: auto;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.responsive-img__img.landscape, .responsive-img__img.square {
  min-width: 100%;
  height: 100%;
  width: auto;
}
.responsive-img__img.portrait {
  height: auto;
  width: 100%;
  min-height: 100%;
}
:root .responsive-img :-ms-fullscreen__img.landscape, :root .responsive-img :-ms-fullscreen__img.square, :root .responsive-img :-ms-fullscreen__img.portrait {
  min-width: 1px !important;
  min-height: 1px !important;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}
:root .responsive-img :-ms-fullscreen__img.landscape, :root .responsive-img :-ms-fullscreen__img.square {
  height: auto !important;
  width: 100% !important;
}
:root .responsive-img :-ms-fullscreen__img.portrait {
  height: 100% !important;
  width: auto !important;
}

wr-btn-list button {
  border-radius: 0 !important;
}
wr-btn-list wr-btn:first-of-type button {
  border-radius: 0.4rem 0 0 0.4rem !important;
}
wr-btn-list wr-btn:last-of-type button {
  border-radius: 0 0.4rem 0.4rem 0 !important;
}
wr-btn-list wr-btn:first-of-type:last-of-type button {
  border-radius: 0.4rem !important;
}
wr-btn-list wr-btn:not(:first-of-type) {
  margin-left: -0.1rem;
}

wr-angular-table a.cell-link.action-link {
  color: #324cab !important;
}
wr-angular-table a.cell-link.action-link:hover {
  color: #304287 !important;
}

.date-input__container [ngbdropdown].show.dropdown .input:focus + [ngbdropdownmenu].dropdown-menu.show,
.date-input__container [ngbdropdown].show.dropdown .input.focused + [ngbdropdownmenu].dropdown-menu.show,
.date-input__container [ngbdropdown].show.dropdown .input:active + [ngbdropdownmenu].dropdown-menu.show, .date-input__container [ngbdropdown].show.dropup .input:focus + [ngbdropdownmenu].dropdown-menu.show,
.date-input__container [ngbdropdown].show.dropup .input.focused + [ngbdropdownmenu].dropdown-menu.show,
.date-input__container [ngbdropdown].show.dropup .input:active + [ngbdropdownmenu].dropdown-menu.show {
  -webkit-box-shadow: 1px 1px 0 1px #96acf9, -1px 1px 0px 1px #96acf9;
  box-shadow: 1px 1px 0 1px #96acf9, -1px 1px 0px 1px #96acf9;
  border-color: #3d64ed;
}
.date-input__container.natural-form [ngbdropdown].show.dropdown .input:focus + [ngbdropdownmenu].dropdown-menu.show,
.date-input__container.natural-form [ngbdropdown].show.dropdown .input.focused + [ngbdropdownmenu].dropdown-menu.show,
.date-input__container.natural-form [ngbdropdown].show.dropdown .input:active + [ngbdropdownmenu].dropdown-menu.show, .date-input__container.natural-form [ngbdropdown].show.dropup .input:focus + [ngbdropdownmenu].dropdown-menu.show,
.date-input__container.natural-form [ngbdropdown].show.dropup .input.focused + [ngbdropdownmenu].dropdown-menu.show,
.date-input__container.natural-form [ngbdropdown].show.dropup .input:active + [ngbdropdownmenu].dropdown-menu.show {
  border-color: transparent;
  border-radius: 0;
}
.date-input__container.white [ngbdropdownmenu].dropdown-menu {
  background: #fcfcfd;
}

[ngbdropdownmenu] {
  -webkit-transition: box-shadow 200ms ease-in-out, border-color 200ms ease-in-out;
  transition: box-shadow 200ms ease-in-out, border-color 200ms ease-in-out;
}
[ngbdropdownmenu][x-placement^=bottom], [ngbdropdownmenu][x-placement^=top] {
  top: -0.2rem !important;
}
[ngbdropdownmenu][x-placement^=bottom].dropdown-menu.show, [ngbdropdownmenu][x-placement^=top].dropdown-menu.show {
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  border-top: none;
  -webkit-transform: translate(0px, 39px) !important;
  -ms-transform: translate(0px, 39px) !important;
  transform: translate(0px, 39px) !important;
}
[ngbdropdownmenu].dropdown-menu {
  background: #f2f5f7;
  position: absolute;
  left: 0px;
  right: 0px;
  border: 0.1rem solid #dfe3ec;
  border-radius: 0;
  padding: 0;
  margin: 0;
  min-height: 312px;
  -webkit-transition: max-height 200ms ease-in-out, opacity 200ms ease-in-out;
  transition: max-height 200ms ease-in-out, opacity 200ms ease-in-out;
  -webkit-transition-delay: 0.5ms;
  -o-transition-delay: 0.5ms;
  transition-delay: 0.5ms;
  display: block !important;
  opacity: 0;
  visibility: hidden;
}
[ngbdropdownmenu].dropdown-menu.default-picker {
  min-width: 250px;
}
[ngbdropdownmenu].dropdown-menu.range-picker {
  min-width: 550px;
}
[ngbdropdownmenu].dropdown-menu.ranges-picker {
  min-width: 92vw;
  max-width: 100vw;
}
[ngbdropdownmenu].dropdown-menu.hide-calendar {
  min-width: 200px;
}
[ngbdropdownmenu].dropdown-menu.show {
  z-index: 25;
  opacity: 1;
  visibility: visible;
}
[ngbdropdownmenu] ngb-datepicker {
  border: none;
  margin: 0 auto;
  display: block;
  border-radius: 0;
}
[ngbdropdownmenu] ngb-datepicker.default-picker {
  width: 215px;
}
[ngbdropdownmenu] ngb-datepicker.range-picker {
  width: 100%;
}
[ngbdropdownmenu] ngb-datepicker.ranges-picker {
  display: none;
}
[ngbdropdownmenu] ngb-datepicker.hide-calendar {
  width: 195px;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-header, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content {
  overflow: hidden;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-header {
  padding-top: 0.4rem;
  padding-bottom: 0.8rem;
  background: transparent;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-header ngb-datepicker-navigation {
  padding-top: 0.8rem;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-month-name {
  color: #596d97;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;
  letter-spacing: 0.14px;
  text-transform: capitalize;
  height: auto;
  background-color: transparent;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-arrow {
  width: auto;
  height: auto;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-arrow .ngb-dp-arrow-btn {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 600;
  letter-spacing: -0.14px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  background: transparent;
  border: 0;
  cursor: pointer;
  border-radius: 0.8rem;
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-arrow .ngb-dp-arrow-btn::before {
  display: block;
  font-family: "Font Awesome 6 Free" !important;
  /* FontAwesome fa... styling ======= */
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  content: "\f104";
  color: #8898b9;
  -webkit-transition: color 200ms ease-in-out;
  transition: color 200ms ease-in-out;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-arrow .ngb-dp-arrow-btn .ngb-dp-navigation-chevron {
  display: none;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-arrow .ngb-dp-arrow-btn:hover:not(:disabled) {
  background-color: #fafcff;
  color: transparent;
  text-decoration: none;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-arrow .ngb-dp-arrow-btn:hover:not(:disabled)::before {
  color: #2b303b;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-arrow .ngb-dp-arrow-btn:active:not(:disabled) {
  background-color: #e9ecf2;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-arrow .ngb-dp-arrow-btn:active:not(:disabled)::before {
  color: #2b303b;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-arrow .ngb-dp-arrow-btn:focus:not(:disabled),
[ngbdropdownmenu] ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-arrow .ngb-dp-arrow-btn .focus {
  outline: none;
  text-decoration: none;
  -webkit-box-shadow: 0 0 0 0.2rem #dfe3ec;
  box-shadow: 0 0 0 0.2rem #dfe3ec;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .ngb-dp-arrow.ngb-dp-arrow-next .ngb-dp-arrow-btn::before {
  content: "\f105";
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month {
  width: 100%;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month {
  width: 100%;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week {
  margin-bottom: 0.8rem;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week.ngb-dp-weekdays {
  background: transparent;
  border: none;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week.ngb-dp-weekdays .ngb-dp-weekday {
  color: #bcc5d7;
  font-style: initial;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-day, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-week-number, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-weekday,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp [ngbDatepickerDayView],
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp .inner-day {
  color: #596d97;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-day, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-week-number, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-weekday {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-day .inner-day, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-week-number .inner-day, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-weekday .inner-day {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin: 0;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-today {
  z-index: unset !important;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-today [ngbDatepickerDayView],
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-today .inner-day {
  background-color: #fafcff;
  color: #8898b9;
  font-weight: 700;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-day.first > div {
  border-radius: 50px 0px 0px 50px !important;
  border: 1px solid #3d64ed;
  border-right: none !important;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-day.last > div {
  border-radius: 0px 50px 50px 0px;
  border: 1px solid #3d64ed;
  border-left: none;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView],
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day {
  border-radius: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-color: transparent;
  width: 100%;
  height: 3rem;
  -webkit-transition: color 200ms ease-in-out, background-color 200ms ease-in-out, border-color 200ms ease-in-out;
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out, border-color 200ms ease-in-out;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView][ng-reflect-selected=true], [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView][ng-reflect-focused=true], [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].selected, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView]:focus, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].range,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day[ng-reflect-selected=true],
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day[ng-reflect-focused=true],
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.selected,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day:focus,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.range {
  background-color: #dce4fd;
  color: #304287;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView][ng-reflect-selected=true].range-start, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView][ng-reflect-selected=true].range-end, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView][ng-reflect-focused=true].range-start, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView][ng-reflect-focused=true].range-end, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].selected.range-start, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].selected.range-end, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView]:focus.range-start, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView]:focus.range-end, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].range.range-start, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].range.range-end,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day[ng-reflect-selected=true].range-start,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day[ng-reflect-selected=true].range-end,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day[ng-reflect-focused=true].range-start,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day[ng-reflect-focused=true].range-end,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.selected.range-start,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.selected.range-end,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day:focus.range-start,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day:focus.range-end,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.range.range-start,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.range.range-end {
  border: 1px solid #3d64ed;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView][ng-reflect-selected=true].range-start, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView][ng-reflect-focused=true].range-start, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].selected.range-start, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView]:focus.range-start, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].range.range-start,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day[ng-reflect-selected=true].range-start,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day[ng-reflect-focused=true].range-start,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.selected.range-start,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day:focus.range-start,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.range.range-start {
  border-radius: 50px 0px 0px 50px;
  border-right: none;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView][ng-reflect-selected=true].range-end, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView][ng-reflect-focused=true].range-end, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].selected.range-end, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView]:focus.range-end, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].range.range-end,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day[ng-reflect-selected=true].range-end,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day[ng-reflect-focused=true].range-end,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.selected.range-end,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day:focus.range-end,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.range.range-end {
  border-radius: 0px 50px 50px 0px;
  border-left: none;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView][ng-reflect-selected=true].range-start.range-end, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView][ng-reflect-focused=true].range-start.range-end, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].selected.range-start.range-end, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView]:focus.range-start.range-end, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].range.range-start.range-end,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day[ng-reflect-selected=true].range-start.range-end,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day[ng-reflect-focused=true].range-start.range-end,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.selected.range-start.range-end,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day:focus.range-start.range-end,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.range.range-start.range-end {
  border-radius: 50px 50px 50px 50px;
  border: 1px solid #3d64ed;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView][ng-reflect-focused=true], [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView]:focus,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day[ng-reflect-focused=true],
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #96acf9;
  box-shadow: 0 0 0 0.2rem #96acf9;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView]:hover:not(.disabled),
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day:hover:not(.disabled) {
  background-color: #dce4fd;
  color: #324cab;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].disabled,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.disabled {
  color: #bcc5d7;
  opacity: 1;
  cursor: default;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].outside,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.outside {
  opacity: 0.5;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].faded,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.faded {
  background-color: #dce4fd;
  color: #324cab;
  border-top: 1px solid #3d64ed;
  border-bottom: 1px solid #3d64ed;
  border-radius: 0;
  position: relative;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].faded::before, [ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].faded::after,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.faded::before,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.faded::after {
  content: "";
  position: absolute;
  background-color: #eaeffe;
  width: 2.4rem;
  height: 2.4rem;
  z-index: -1;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].faded::before,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.faded::before {
  left: -1.6rem;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].faded::after,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.faded::after {
  right: -1.6rem;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].faded:last-of-type::after,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.faded:last-of-type::after {
  background-color: transparent;
}
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week [ngbDatepickerDayView].faded:first-of-type::before,
[ngbdropdownmenu] ngb-datepicker .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-week .inner-day.faded:first-of-type::before {
  background-color: transparent;
}

@media (min-width: 576px) {
  [ngbdropdownmenu].dropdown-menu ngb-datepicker.range-picker {
    width: 545px;
  }
  [ngbdropdownmenu].dropdown-menu ngb-datepicker.ranges-picker {
    display: block;
    width: 545px;
    margin: 0 10px 0 0;
  }
  [ngbdropdownmenu].dropdown-menu ngb-datepicker .ngb-dp-content ngb-datepicker-month .ngb-dp-day, [ngbdropdownmenu].dropdown-menu ngb-datepicker .ngb-dp-content ngb-datepicker-month .ngb-dp-week-number, [ngbdropdownmenu].dropdown-menu ngb-datepicker .ngb-dp-content ngb-datepicker-month .ngb-dp-weekday {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
  [ngbdropdownmenu].dropdown-menu ngb-datepicker .ngb-dp-content ngb-datepicker-month .ngb-dp-day .inner-day, [ngbdropdownmenu].dropdown-menu ngb-datepicker .ngb-dp-content ngb-datepicker-month .ngb-dp-week-number .inner-day, [ngbdropdownmenu].dropdown-menu ngb-datepicker .ngb-dp-content ngb-datepicker-month .ngb-dp-weekday .inner-day {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    margin: 0;
  }
}
@media (min-width: 768px) {
  [ngbdropdownmenu].dropdown-menu.ranges-picker {
    min-width: 730px;
  }
}
/*
  popper or ng-bootstrap adds an inline position: static on hide,
  making position absolute important,
  since it needs to be out of the natural DOM placement to prevent seing a blank space below the input
*/
[ngbdropdownmenu] {
  position: absolute !important;
}

[data-popper-placement] {
  margin-top: 0.8rem !important;
}
[data-popper-placement] .dropdown-menu.show.date-input__ddm {
  border-top: 0.1rem solid #dfe3ec !important;
  border-radius: 0.4rem !important;
  background-color: white;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  padding: 1.6rem;
}
[data-popper-placement] .dropdown-menu.show.date-input__ddm::before {
  border-radius: 0.8rem;
  border: 0.1rem solid #dfe3ec;
  content: "";
  height: 1.75rem;
  width: 1.75rem;
  background-color: inherit;
  border-bottom: solid transparent;
  border-right: solid transparent;
  position: absolute;
}
[data-popper-placement][data-popper-placement=bottom-end] .dropdown-menu.show.date-input__ddm::before {
  top: -0.75rem;
  right: 1.25rem;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
[data-popper-placement][data-popper-placement=bottom-start] .dropdown-menu.show.date-input__ddm::before {
  top: -0.75rem;
  left: 1.25rem;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
[data-popper-placement][data-popper-placement=top-end] .dropdown-menu.show.date-input__ddm::before {
  bottom: 1.25rem;
  right: 1.25rem;
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}
[data-popper-placement][data-popper-placement=top-start] .dropdown-menu.show.date-input__ddm::before {
  bottom: 1.25rem;
  left: 1.25rem;
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

/* custom svg icons */
@font-face {
  font-family: "custom-icon";
  src: url("https://wio.blob.core.windows.net/cdn/assets/fonts/custom-icon/custom-icon.eot") format("embedded-opentype"), url("https://wio.blob.core.windows.net/cdn/assets/fonts/custom-icon/custom-icon.ttf") format("truetype"), url("https://wio.blob.core.windows.net/cdn/assets/fonts/custom-icon/custom-icon.woff") format("woff"), url("https://wio.blob.core.windows.net/cdn/assets/fonts/custom-icon/custom-icon.svg") format("svg");
}
.redactor-modal-box {
  background-color: rgba(136, 152, 185, 0.7);
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;
  letter-spacing: 0.14px;
}
.redactor-modal-box .redactor-modal {
  border-radius: 1.2rem;
  border: 0.1rem solid #dfe3ec;
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  margin-top: 3.2rem !important;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(24, 26, 32, 0.03), 0px 8px 8px 0px rgba(24, 26, 32, 0.01), 0px 16px 16px 0px rgba(24, 26, 32, 0.01), 0px 24px 24px 0px rgba(24, 26, 32, 0.01), 0px 32px 32px 0px rgba(24, 26, 32, 0.01), 0px 48px 48px 0px rgba(24, 26, 32, 0.01), 0px 64px 64px 0px rgba(24, 26, 32, 0.01), 0px 15px 50px 0px rgba(24, 26, 32, 0.05);
  box-shadow: 0px 2px 2px 0px rgba(24, 26, 32, 0.03), 0px 8px 8px 0px rgba(24, 26, 32, 0.01), 0px 16px 16px 0px rgba(24, 26, 32, 0.01), 0px 24px 24px 0px rgba(24, 26, 32, 0.01), 0px 32px 32px 0px rgba(24, 26, 32, 0.01), 0px 48px 48px 0px rgba(24, 26, 32, 0.01), 0px 64px 64px 0px rgba(24, 26, 32, 0.01), 0px 15px 50px 0px rgba(24, 26, 32, 0.05);
  background: #fcfcfd;
  overflow: hidden;
}
.redactor-modal-box .redactor-modal button,
.redactor-modal-box .redactor-modal .redactor-close {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 600;
  letter-spacing: -0.14px;
  -webkit-transition: color 200ms ease-in-out, border-color 200ms ease-in-out, background-color 200ms ease-in-out;
  transition: color 200ms ease-in-out, border-color 200ms ease-in-out, background-color 200ms ease-in-out;
  border-radius: 0.8rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  white-space: nowrap;
  margin: 0;
  border: none;
  cursor: pointer;
}
.redactor-modal-box .redactor-modal button {
  color: #fcfcfd;
  background-color: #3456d0;
}
.redactor-modal-box .redactor-modal button:hover:not(:disabled) {
  background-color: #324cab;
  text-decoration: none;
}
.redactor-modal-box .redactor-modal button:not(:first-of-type) {
  margin-right: 0.8rem;
}
.redactor-modal-box .redactor-modal button:active:not(:disabled), .redactor-modal-box .redactor-modal button:focus:not(:disabled) {
  background-color: #304287;
}
.redactor-modal-box .redactor-modal button:focus:not(:disabled) {
  -webkit-box-shadow: 0 0 0 0.2rem #96acf9;
  box-shadow: 0 0 0 0.2rem #96acf9;
}
.redactor-modal-box .redactor-modal button.redactor-button-unstyled {
  color: #3e4556;
  background-color: transparent;
}
.redactor-modal-box .redactor-modal button.redactor-button-unstyled:hover:not(:disabled) {
  background-color: #f2f5f7;
  color: #181a20;
}
.redactor-modal-box .redactor-modal button.redactor-button-unstyled:active:not(:disabled), .redactor-modal-box .redactor-modal button.redactor-button-unstyled:focus:not(:disabled) {
  background-color: #e9ecf2;
  color: #181a20;
}
.redactor-modal-box .redactor-modal button.redactor-button-unstyled:focus:not(:disabled) {
  -webkit-box-shadow: 0 0 0 0.2rem #e9ecf2;
  box-shadow: 0 0 0 0.2rem #e9ecf2;
}
.redactor-modal-box .redactor-modal button.redactor-button-danger,
.redactor-modal-box .redactor-modal .redactor-close {
  color: #3e4556;
  background-color: transparent;
}
.redactor-modal-box .redactor-modal button.redactor-button-danger:hover:not(:disabled),
.redactor-modal-box .redactor-modal .redactor-close:hover:not(:disabled) {
  background-color: #fff0f0;
  color: #ed1136;
}
.redactor-modal-box .redactor-modal button.redactor-button-danger:active:not(:disabled), .redactor-modal-box .redactor-modal button.redactor-button-danger:focus:not(:disabled),
.redactor-modal-box .redactor-modal .redactor-close:active:not(:disabled),
.redactor-modal-box .redactor-modal .redactor-close:focus:not(:disabled) {
  background-color: #ffe2e4;
  color: #a8092d;
}
.redactor-modal-box .redactor-modal button.redactor-button-danger:focus:not(:disabled),
.redactor-modal-box .redactor-modal .redactor-close:focus:not(:disabled) {
  -webkit-box-shadow: 0 0 0 0.2rem #ffcace;
  box-shadow: 0 0 0 0.2rem #ffcace;
}
.redactor-modal-box .redactor-modal .redactor-close {
  opacity: 1;
  top: 0.8rem;
}
.redactor-modal-box .redactor-modal .redactor-close::before {
  font-family: "Font Awesome 6 Free" !important;
  /* FontAwesome fa... styling ======= */
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  font-size: 1.2rem;
  content: "\f00d";
}
.redactor-modal-box .redactor-modal .upload-redactor-box {
  border-radius: 0.8rem;
  background-color: #f2f5f7;
  border: 5px dashed #96acf9;
}
.redactor-modal-box .redactor-modal .redactor-modal-header {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #f2f5f7;
  color: #3e4556;
  border: none;
}
.redactor-modal-box .redactor-modal .redactor-modal-body,
.redactor-modal-box .redactor-modal .redactor-modal-footer {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item label {
  margin: 0;
  padding-bottom: 0.4rem;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;
  letter-spacing: 0.14px;
  display: block;
  color: #4e586f;
}
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item label.checkbox {
  margin-bottom: 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item input[type=text],
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item select {
  border: 0.1rem solid #dfe3ec;
  border-radius: 0.8rem;
  -webkit-transition: border 200ms ease-in-out, box-shadow 200ms ease-in-out;
  transition: border 200ms ease-in-out, box-shadow 200ms ease-in-out;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;
  letter-spacing: 0.14px;
  width: 100%;
  padding: 0.8rem 1.6rem;
  line-height: 0.8rem;
  color: #4e586f;
  background-color: #f2f5f7;
}
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item input[type=text]::-webkit-input-placeholder,
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item select::-webkit-input-placeholder {
  color: #8898b9;
  opacity: 0.85;
}
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item input[type=text]::-moz-placeholder,
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item select::-moz-placeholder {
  color: #8898b9;
  opacity: 0.85;
}
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item input[type=text]:-ms-input-placeholder,
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item select:-ms-input-placeholder {
  color: #8898b9;
  opacity: 0.85;
}
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item input[type=text]::-ms-input-placeholder,
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item select::-ms-input-placeholder {
  color: #8898b9;
  opacity: 0.85;
}
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item input[type=text]::placeholder,
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item select::placeholder {
  color: #8898b9;
  opacity: 0.85;
}
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item input[type=text]:focus, .redactor-modal-box .redactor-modal .redactor-modal-body .form-item input[type=text].focused, .redactor-modal-box .redactor-modal .redactor-modal-body .form-item input[type=text]:active,
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item select:focus,
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item select.focused,
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item select:active {
  -webkit-box-shadow: 0 0 0 0.2rem #96acf9;
  box-shadow: 0 0 0 0.2rem #96acf9;
  border-color: #3d64ed;
  z-index: 5;
}
.redactor-modal-box .redactor-modal .redactor-modal-body .form-item input[type=checkbox] {
  margin-right: 0.8rem;
}
.redactor-modal-box .redactor-modal .redactor-modal-footer {
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-flow: row-reverse wrap;
  flex-flow: row-reverse wrap;
}
.redactor-modal-box .redactor-modal .redactor-modal-footer button.redactor-button-danger {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  margin-right: auto;
}
.redactor-modal-box .redactor-modal .redactor-modal-footer button.redactor-button-unstyled {
  float: none;
}

wr-asset-usages .cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}

/* include border and padding in element width and height */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Wrap new scrollbar properties in @supports rule for browsers without `::-webkit-scrollbar-*` support */
/* This way chrome won't override `::-webkit-scrollbar-*` selectors */
@supports not selector(::-webkit-scrollbar) {
  html,
  body {
    scrollbar-width: auto;
    scrollbar-color: #dfe3ec #f2f5f7;
  }
}
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: #dfe3ec;
  border-radius: 5px;
}

html::-webkit-scrollbar-thumb:hover,
body::-webkit-scrollbar-thumb:hover {
  background-color: #bcc5d7;
}

html::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background-color: #f2f5f7;
  border-radius: 5px;
}

@supports not selector(::-webkit-scrollbar) {
  html body .wr-app,
  html body wr-overlay {
    scrollbar-width: thin;
    scrollbar-color: #dfe3ec #f2f5f7;
  }
}
html body .wr-app *::-webkit-scrollbar,
html body wr-overlay *::-webkit-scrollbar {
  width: 7px;
  height: 6px;
}

html body .wr-app *::-webkit-scrollbar-thumb,
html body wr-overlay *::-webkit-scrollbar-thumb {
  background-color: #dfe3ec;
  border-radius: 5px;
}

html body .wr-app *::-webkit-scrollbar-thumb:hover,
html body wr-overlay *::-webkit-scrollbar-thumb:hover {
  background-color: #bcc5d7;
}

html body .wr-app *::-webkit-scrollbar-track,
html body wr-overlay *::-webkit-scrollbar-track {
  background-color: #f2f5f7;
  border-radius: 5px;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  height: 100vh;
}
html ::-moz-selection {
  background-color: #3456d0;
  color: #fcfcfd;
}
html ::selection {
  background-color: #3456d0;
  color: #fcfcfd;
}
html body {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: #3e4556;
  background-color: #f0f1f9;
  position: relative;
  width: 100vw;
  min-height: 100%;
  overflow-x: hidden;
}
html body p {
  margin: 0;
}
html body.dialog-open, html body.sm-nav-open {
  overflow: hidden;
}
html body .tox-tinymce-aux {
  z-index: 25;
}
html body :last-child.tox-tinymce-aux {
  z-index: 802;
}
html body .nav__container__left .holding .btn__content {
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
html wr-root {
  height: 100%;
}

.wr-app.secNavDisplayed main {
  height: calc(100vh - 6.4rem * 2) !important;
}
.wr-app.secNavDisplayed .col-layout .sticky .col-layout__left-panel__content, .wr-app.secNavDisplayed .col-layout .sticky .col-layout__right-panel__content {
  top: calc(6.4rem * 2) !important;
}
.wr-app.secNavDisplayed .col-layout .scrollable {
  top: calc(6.4rem * 2) !important;
  height: calc(100vh - 6.4rem * 2) !important;
}

.form-item:not(:last-of-type) {
  margin-bottom: 1.6rem;
}

.tooltip {
  z-index: 1101;
  white-space: break-spaces;
  max-width: 50vw;
}
.tooltip-inner, .tooltip > .arrow {
  display: none;
}
.tooltip-inner {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
  padding: 0.4rem;
  color: #fcfcfd;
  background-color: #181a20;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.tooltip-inner a {
  text-decoration: underline;
}
.tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #181a20;
}
.tooltip.bs-tooltip-right .arrow::before {
  border-right-color: #181a20;
}
.tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #181a20;
}
.tooltip.bs-tooltip-left .arrow::before {
  border-left-color: #181a20;
}

a:hover {
  text-decoration: underline;
}

a,
.default-link {
  cursor: pointer;
  color: #3456d0;
  -webkit-transition: color 200ms ease-in-out;
  transition: color 200ms ease-in-out;
  text-decoration: none;
}
a:hover,
.default-link:hover {
  color: #303d74;
}

.btn-link {
  text-decoration: none;
}

label {
  margin-bottom: 0;
}

.page-title {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 3.2rem;
  line-height: 120%;
  font-weight: 600;
  letter-spacing: -0.64px;
  color: #3e4556;
}

.section-title {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 700;
  color: #3e4556;
}

.modal__section-title {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 600;
  letter-spacing: -0.14px;
  color: #4e586f;
}

.overflow--hidden {
  overflow: hidden;
}

.cursor-default {
  cursor: default !important;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}
.cursor .invisible {
  visibility: hidden;
}

.invisible {
  visibility: hidden;
}

.txt-decoration--hover--underline:hover {
  text-decoration: underline !important;
}
.txt-decoration--underline {
  text-decoration: underline !important;
}
.txt-decoration--none {
  text-decoration: none !important;
}

.txt--truncate {
  max-width: 100%;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.txt--uppercase {
  text-transform: uppercase;
}

.txt--line-through {
  text-decoration-line: line-through;
  text-decoration-color: inherit;
}

.txt--disabled {
  color: #8898b9;
}

.m--b--intercom {
  margin-bottom: 9rem;
}

.whitespace--nowrap {
  white-space: nowrap;
}

.flex-grow-2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}
.flex-grow-3 {
  -webkit-box-flex: 3;
  -ms-flex-positive: 3;
  flex-grow: 3;
}

.full-opacity {
  opacity: 1 !important;
}

.mandatory {
  color: #ffcace;
  margin-right: 5px;
}

.list-style-none {
  padding: 0;
}
.list-style-none, .list-style-none li {
  list-style-type: none;
}

.reset-min-width {
  min-width: 1px;
}

.bullet-sticker {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 1.6rem;
}
.bullet-sticker--sm {
  width: 0.4rem;
  height: 0.4rem;
}
.bullet-sticker--lg {
  width: 1.2rem;
  height: 1.2rem;
}

input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0.1rem solid #bcc5d7;
  border-radius: 0.3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  width: 1.8rem !important;
  height: 1.8rem !important;
  cursor: pointer;
  background-color: #f2f5f7;
  overflow: hidden;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  margin-right: 0.8rem;
}
input[type=checkbox]:checked {
  border: 0.1rem solid #3d64ed;
  background-color: #3d64ed !important;
}
input[type=checkbox]:checked::after {
  color: #fcfcfd;
  font-family: "Font Awesome 6 Free" !important;
  /* FontAwesome fa... styling ======= */
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f00c";
  font-weight: 900;
  font-size: 1.1rem;
  position: absolute;
  line-height: 1.2;
}
input[type=checkbox]:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #96acf9;
  box-shadow: 0 0 0 0.2rem #96acf9;
}
input[type=checkbox]:disabled, input[type=checkbox].disabled {
  cursor: not-allowed;
  background-color: #e9ecf2;
}
input[type=checkbox]:disabled:checked, input[type=checkbox].disabled:checked {
  border: 0.1rem solid #bcc5d7;
  background-color: #8898b9 !important;
}

.select__wrapper .select__option__container wr-checkbox .checkbox__container {
  color: #596d97;
}

.bg--primary--1 {
  background-color: #2e3862;
}
.bg--primary--2 {
  background-color: #303d74;
}
.bg--primary--3 {
  background-color: #304287;
}
.bg--primary--4 {
  background-color: #324cab;
}
.bg--primary--5 {
  background-color: #3456d0;
}
.bg--primary--6 {
  background-color: #3d64ed;
}
.bg--primary--7 {
  background-color: #7491f7;
}
.bg--primary--8 {
  background-color: #96acf9;
}
.bg--primary--9 {
  background-color: #b9c7fb;
}
.bg--primary--10 {
  background-color: #dce4fd;
}
.bg--primary--11 {
  background-color: #eef4ff;
}
.bg--primary--12 {
  background-color: #fafcff;
}
.bg--secondary--1 {
  background-color: #7e2210;
}
.bg--secondary--2 {
  background-color: #9d2b0f;
}
.bg--secondary--3 {
  background-color: #c62e08;
}
.bg--secondary--4 {
  background-color: #dd370e;
}
.bg--secondary--5 {
  background-color: #fa420f;
}
.bg--secondary--6 {
  background-color: #ff6136;
}
.bg--secondary--7 {
  background-color: #ff9271;
}
.bg--secondary--8 {
  background-color: #ffbea8;
}
.bg--secondary--9 {
  background-color: #ffdfd4;
}
.bg--secondary--10 {
  background-color: #fff2ed;
}
.bg--secondary--11 {
  background-color: #fffbfa;
}
.bg--success--1 {
  background-color: #1b4644;
}
.bg--success--2 {
  background-color: #1c5352;
}
.bg--success--3 {
  background-color: #1d6864;
}
.bg--success--4 {
  background-color: #20817c;
}
.bg--success--5 {
  background-color: #2ba198;
}
.bg--success--6 {
  background-color: #44bdb2;
}
.bg--success--7 {
  background-color: #64d3c5;
}
.bg--success--8 {
  background-color: #a6e9de;
}
.bg--success--9 {
  background-color: #d2f5ee;
}
.bg--success--10 {
  background-color: #f2fbf9;
}
.bg--warning--1 {
  background-color: #7d3d11;
}
.bg--warning--2 {
  background-color: #9b4911;
}
.bg--warning--3 {
  background-color: #c45e0a;
}
.bg--warning--4 {
  background-color: #f5810b;
}
.bg--warning--5 {
  background-color: #fb9814;
}
.bg--warning--6 {
  background-color: #fdb23a;
}
.bg--warning--7 {
  background-color: #fed173;
}
.bg--warning--8 {
  background-color: #ffe5a9;
}
.bg--warning--9 {
  background-color: #fff4d5;
}
.bg--warning--10 {
  background-color: #fffaed;
}
.bg--danger--1 {
  background-color: #8f0c2d;
}
.bg--danger--2 {
  background-color: #a8092d;
}
.bg--danger--3 {
  background-color: #c8082d;
}
.bg--danger--4 {
  background-color: #ed1136;
}
.bg--danger--5 {
  background-color: #ff485e;
}
.bg--danger--6 {
  background-color: #ff6977;
}
.bg--danger--7 {
  background-color: #ff9fa6;
}
.bg--danger--8 {
  background-color: #ffcace;
}
.bg--danger--9 {
  background-color: #ffe2e4;
}
.bg--danger--10 {
  background-color: #fff0f0;
}
.bg--neutral--0 {
  background-color: #181a20;
}
.bg--neutral--1 {
  background-color: #2b303b;
}
.bg--neutral--2 {
  background-color: #3e4556;
}
.bg--neutral--3 {
  background-color: #4e586f;
}
.bg--neutral--4 {
  background-color: #596d97;
}
.bg--neutral--5 {
  background-color: #8898b9;
}
.bg--neutral--6 {
  background-color: #bcc5d7;
}
.bg--neutral--7 {
  background-color: #dfe3ec;
}
.bg--neutral--8 {
  background-color: #e9ecf2;
}
.bg--neutral--9 {
  background-color: #f2f5f7;
}
.bg--neutral--10 {
  background-color: #fcfcfd;
}
.bg--social--facebook {
  background-color: #3b5998;
}
.bg--social--twitter {
  background-color: #1da1f2;
}
.bg--social--youtube {
  background-color: #cd201f;
}
.bg--social--linkedin {
  background-color: #0077b5;
}
.bg--social--frenchtex {
  background-color: #152f4a;
}
.bg--mpleo--background {
  background-color: #f0f1f9;
}

.txt--primary--1 {
  color: #2e3862;
}
.txt--primary--2 {
  color: #303d74;
}
.txt--primary--3 {
  color: #304287;
}
.txt--primary--4 {
  color: #324cab;
}
.txt--primary--5 {
  color: #3456d0;
}
.txt--primary--6 {
  color: #3d64ed;
}
.txt--primary--7 {
  color: #7491f7;
}
.txt--primary--8 {
  color: #96acf9;
}
.txt--primary--9 {
  color: #b9c7fb;
}
.txt--primary--10 {
  color: #dce4fd;
}
.txt--primary--11 {
  color: #eef4ff;
}
.txt--primary--12 {
  color: #fafcff;
}
.txt--secondary--1 {
  color: #7e2210;
}
.txt--secondary--2 {
  color: #9d2b0f;
}
.txt--secondary--3 {
  color: #c62e08;
}
.txt--secondary--4 {
  color: #dd370e;
}
.txt--secondary--5 {
  color: #fa420f;
}
.txt--secondary--6 {
  color: #ff6136;
}
.txt--secondary--7 {
  color: #ff9271;
}
.txt--secondary--8 {
  color: #ffbea8;
}
.txt--secondary--9 {
  color: #ffdfd4;
}
.txt--secondary--10 {
  color: #fff2ed;
}
.txt--secondary--11 {
  color: #fffbfa;
}
.txt--success--1 {
  color: #1b4644;
}
.txt--success--2 {
  color: #1c5352;
}
.txt--success--3 {
  color: #1d6864;
}
.txt--success--4 {
  color: #20817c;
}
.txt--success--5 {
  color: #2ba198;
}
.txt--success--6 {
  color: #44bdb2;
}
.txt--success--7 {
  color: #64d3c5;
}
.txt--success--8 {
  color: #a6e9de;
}
.txt--success--9 {
  color: #d2f5ee;
}
.txt--success--10 {
  color: #f2fbf9;
}
.txt--warning--1 {
  color: #7d3d11;
}
.txt--warning--2 {
  color: #9b4911;
}
.txt--warning--3 {
  color: #c45e0a;
}
.txt--warning--4 {
  color: #f5810b;
}
.txt--warning--5 {
  color: #fb9814;
}
.txt--warning--6 {
  color: #fdb23a;
}
.txt--warning--7 {
  color: #fed173;
}
.txt--warning--8 {
  color: #ffe5a9;
}
.txt--warning--9 {
  color: #fff4d5;
}
.txt--warning--10 {
  color: #fffaed;
}
.txt--danger--1 {
  color: #8f0c2d;
}
.txt--danger--2 {
  color: #a8092d;
}
.txt--danger--3 {
  color: #c8082d;
}
.txt--danger--4 {
  color: #ed1136;
}
.txt--danger--5 {
  color: #ff485e;
}
.txt--danger--6 {
  color: #ff6977;
}
.txt--danger--7 {
  color: #ff9fa6;
}
.txt--danger--8 {
  color: #ffcace;
}
.txt--danger--9 {
  color: #ffe2e4;
}
.txt--danger--10 {
  color: #fff0f0;
}
.txt--neutral--0 {
  color: #181a20;
}
.txt--neutral--1 {
  color: #2b303b;
}
.txt--neutral--2 {
  color: #3e4556;
}
.txt--neutral--3 {
  color: #4e586f;
}
.txt--neutral--4 {
  color: #596d97;
}
.txt--neutral--5 {
  color: #8898b9;
}
.txt--neutral--6 {
  color: #bcc5d7;
}
.txt--neutral--7 {
  color: #dfe3ec;
}
.txt--neutral--8 {
  color: #e9ecf2;
}
.txt--neutral--9 {
  color: #f2f5f7;
}
.txt--neutral--10 {
  color: #fcfcfd;
}
.txt--social--facebook {
  color: #3b5998;
}
.txt--social--twitter {
  color: #1da1f2;
}
.txt--social--youtube {
  color: #cd201f;
}
.txt--social--linkedin {
  color: #0077b5;
}
.txt--social--frenchtex {
  color: #152f4a;
}
.txt--mpleo--background {
  color: #f0f1f9;
}
.txt--hover--primary--1:hover {
  color: #2e3862 !important;
}
.txt--hover--primary--2:hover {
  color: #303d74 !important;
}
.txt--hover--primary--3:hover {
  color: #304287 !important;
}
.txt--hover--primary--4:hover {
  color: #324cab !important;
}
.txt--hover--primary--5:hover {
  color: #3456d0 !important;
}
.txt--hover--primary--6:hover {
  color: #3d64ed !important;
}
.txt--hover--primary--7:hover {
  color: #7491f7 !important;
}
.txt--hover--primary--8:hover {
  color: #96acf9 !important;
}
.txt--hover--primary--9:hover {
  color: #b9c7fb !important;
}
.txt--hover--primary--10:hover {
  color: #dce4fd !important;
}
.txt--hover--primary--11:hover {
  color: #eef4ff !important;
}
.txt--hover--primary--12:hover {
  color: #fafcff !important;
}
.txt--hover--secondary--1:hover {
  color: #7e2210 !important;
}
.txt--hover--secondary--2:hover {
  color: #9d2b0f !important;
}
.txt--hover--secondary--3:hover {
  color: #c62e08 !important;
}
.txt--hover--secondary--4:hover {
  color: #dd370e !important;
}
.txt--hover--secondary--5:hover {
  color: #fa420f !important;
}
.txt--hover--secondary--6:hover {
  color: #ff6136 !important;
}
.txt--hover--secondary--7:hover {
  color: #ff9271 !important;
}
.txt--hover--secondary--8:hover {
  color: #ffbea8 !important;
}
.txt--hover--secondary--9:hover {
  color: #ffdfd4 !important;
}
.txt--hover--secondary--10:hover {
  color: #fff2ed !important;
}
.txt--hover--secondary--11:hover {
  color: #fffbfa !important;
}
.txt--hover--success--1:hover {
  color: #1b4644 !important;
}
.txt--hover--success--2:hover {
  color: #1c5352 !important;
}
.txt--hover--success--3:hover {
  color: #1d6864 !important;
}
.txt--hover--success--4:hover {
  color: #20817c !important;
}
.txt--hover--success--5:hover {
  color: #2ba198 !important;
}
.txt--hover--success--6:hover {
  color: #44bdb2 !important;
}
.txt--hover--success--7:hover {
  color: #64d3c5 !important;
}
.txt--hover--success--8:hover {
  color: #a6e9de !important;
}
.txt--hover--success--9:hover {
  color: #d2f5ee !important;
}
.txt--hover--success--10:hover {
  color: #f2fbf9 !important;
}
.txt--hover--warning--1:hover {
  color: #7d3d11 !important;
}
.txt--hover--warning--2:hover {
  color: #9b4911 !important;
}
.txt--hover--warning--3:hover {
  color: #c45e0a !important;
}
.txt--hover--warning--4:hover {
  color: #f5810b !important;
}
.txt--hover--warning--5:hover {
  color: #fb9814 !important;
}
.txt--hover--warning--6:hover {
  color: #fdb23a !important;
}
.txt--hover--warning--7:hover {
  color: #fed173 !important;
}
.txt--hover--warning--8:hover {
  color: #ffe5a9 !important;
}
.txt--hover--warning--9:hover {
  color: #fff4d5 !important;
}
.txt--hover--warning--10:hover {
  color: #fffaed !important;
}
.txt--hover--danger--1:hover {
  color: #8f0c2d !important;
}
.txt--hover--danger--2:hover {
  color: #a8092d !important;
}
.txt--hover--danger--3:hover {
  color: #c8082d !important;
}
.txt--hover--danger--4:hover {
  color: #ed1136 !important;
}
.txt--hover--danger--5:hover {
  color: #ff485e !important;
}
.txt--hover--danger--6:hover {
  color: #ff6977 !important;
}
.txt--hover--danger--7:hover {
  color: #ff9fa6 !important;
}
.txt--hover--danger--8:hover {
  color: #ffcace !important;
}
.txt--hover--danger--9:hover {
  color: #ffe2e4 !important;
}
.txt--hover--danger--10:hover {
  color: #fff0f0 !important;
}
.txt--hover--neutral--0:hover {
  color: #181a20 !important;
}
.txt--hover--neutral--1:hover {
  color: #2b303b !important;
}
.txt--hover--neutral--2:hover {
  color: #3e4556 !important;
}
.txt--hover--neutral--3:hover {
  color: #4e586f !important;
}
.txt--hover--neutral--4:hover {
  color: #596d97 !important;
}
.txt--hover--neutral--5:hover {
  color: #8898b9 !important;
}
.txt--hover--neutral--6:hover {
  color: #bcc5d7 !important;
}
.txt--hover--neutral--7:hover {
  color: #dfe3ec !important;
}
.txt--hover--neutral--8:hover {
  color: #e9ecf2 !important;
}
.txt--hover--neutral--9:hover {
  color: #f2f5f7 !important;
}
.txt--hover--neutral--10:hover {
  color: #fcfcfd !important;
}
.txt--hover--social--facebook:hover {
  color: #3b5998 !important;
}
.txt--hover--social--twitter:hover {
  color: #1da1f2 !important;
}
.txt--hover--social--youtube:hover {
  color: #cd201f !important;
}
.txt--hover--social--linkedin:hover {
  color: #0077b5 !important;
}
.txt--hover--social--frenchtex:hover {
  color: #152f4a !important;
}
.txt--hover--mpleo--background:hover {
  color: #f0f1f9 !important;
}
.txt--important--primary--1 {
  color: #2e3862 !important;
}
.txt--important--primary--2 {
  color: #303d74 !important;
}
.txt--important--primary--3 {
  color: #304287 !important;
}
.txt--important--primary--4 {
  color: #324cab !important;
}
.txt--important--primary--5 {
  color: #3456d0 !important;
}
.txt--important--primary--6 {
  color: #3d64ed !important;
}
.txt--important--primary--7 {
  color: #7491f7 !important;
}
.txt--important--primary--8 {
  color: #96acf9 !important;
}
.txt--important--primary--9 {
  color: #b9c7fb !important;
}
.txt--important--primary--10 {
  color: #dce4fd !important;
}
.txt--important--primary--11 {
  color: #eef4ff !important;
}
.txt--important--primary--12 {
  color: #fafcff !important;
}
.txt--important--secondary--1 {
  color: #7e2210 !important;
}
.txt--important--secondary--2 {
  color: #9d2b0f !important;
}
.txt--important--secondary--3 {
  color: #c62e08 !important;
}
.txt--important--secondary--4 {
  color: #dd370e !important;
}
.txt--important--secondary--5 {
  color: #fa420f !important;
}
.txt--important--secondary--6 {
  color: #ff6136 !important;
}
.txt--important--secondary--7 {
  color: #ff9271 !important;
}
.txt--important--secondary--8 {
  color: #ffbea8 !important;
}
.txt--important--secondary--9 {
  color: #ffdfd4 !important;
}
.txt--important--secondary--10 {
  color: #fff2ed !important;
}
.txt--important--secondary--11 {
  color: #fffbfa !important;
}
.txt--important--success--1 {
  color: #1b4644 !important;
}
.txt--important--success--2 {
  color: #1c5352 !important;
}
.txt--important--success--3 {
  color: #1d6864 !important;
}
.txt--important--success--4 {
  color: #20817c !important;
}
.txt--important--success--5 {
  color: #2ba198 !important;
}
.txt--important--success--6 {
  color: #44bdb2 !important;
}
.txt--important--success--7 {
  color: #64d3c5 !important;
}
.txt--important--success--8 {
  color: #a6e9de !important;
}
.txt--important--success--9 {
  color: #d2f5ee !important;
}
.txt--important--success--10 {
  color: #f2fbf9 !important;
}
.txt--important--warning--1 {
  color: #7d3d11 !important;
}
.txt--important--warning--2 {
  color: #9b4911 !important;
}
.txt--important--warning--3 {
  color: #c45e0a !important;
}
.txt--important--warning--4 {
  color: #f5810b !important;
}
.txt--important--warning--5 {
  color: #fb9814 !important;
}
.txt--important--warning--6 {
  color: #fdb23a !important;
}
.txt--important--warning--7 {
  color: #fed173 !important;
}
.txt--important--warning--8 {
  color: #ffe5a9 !important;
}
.txt--important--warning--9 {
  color: #fff4d5 !important;
}
.txt--important--warning--10 {
  color: #fffaed !important;
}
.txt--important--danger--1 {
  color: #8f0c2d !important;
}
.txt--important--danger--2 {
  color: #a8092d !important;
}
.txt--important--danger--3 {
  color: #c8082d !important;
}
.txt--important--danger--4 {
  color: #ed1136 !important;
}
.txt--important--danger--5 {
  color: #ff485e !important;
}
.txt--important--danger--6 {
  color: #ff6977 !important;
}
.txt--important--danger--7 {
  color: #ff9fa6 !important;
}
.txt--important--danger--8 {
  color: #ffcace !important;
}
.txt--important--danger--9 {
  color: #ffe2e4 !important;
}
.txt--important--danger--10 {
  color: #fff0f0 !important;
}
.txt--important--neutral--0 {
  color: #181a20 !important;
}
.txt--important--neutral--1 {
  color: #2b303b !important;
}
.txt--important--neutral--2 {
  color: #3e4556 !important;
}
.txt--important--neutral--3 {
  color: #4e586f !important;
}
.txt--important--neutral--4 {
  color: #596d97 !important;
}
.txt--important--neutral--5 {
  color: #8898b9 !important;
}
.txt--important--neutral--6 {
  color: #bcc5d7 !important;
}
.txt--important--neutral--7 {
  color: #dfe3ec !important;
}
.txt--important--neutral--8 {
  color: #e9ecf2 !important;
}
.txt--important--neutral--9 {
  color: #f2f5f7 !important;
}
.txt--important--neutral--10 {
  color: #fcfcfd !important;
}
.txt--important--social--facebook {
  color: #3b5998 !important;
}
.txt--important--social--twitter {
  color: #1da1f2 !important;
}
.txt--important--social--youtube {
  color: #cd201f !important;
}
.txt--important--social--linkedin {
  color: #0077b5 !important;
}
.txt--important--social--frenchtex {
  color: #152f4a !important;
}
.txt--important--mpleo--background {
  color: #f0f1f9 !important;
}
.txt--caption {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
}
.txt--caption--bold {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.24px;
}
.txt--caption--sm {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
}
.txt--content {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;
  letter-spacing: 0.14px;
}
.txt--content--bold {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 600;
  letter-spacing: -0.14px;
}
.txt--content--lg {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 400;
}
.txt--content--lg--bold {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 700;
}
.txt--title {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 2.2rem;
  line-height: 2.4rem;
  font-weight: 600;
  color: #4e586f;
}
.txt--title--xs {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 400;
}
.txt--title--xs--bold {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 500;
}
.txt--title--sm {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 500;
}
.txt--display {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 3.2rem;
  line-height: 120%;
  font-weight: 600;
  letter-spacing: -0.64px;
}

.custom-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "custom-icon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.custom-icon.checkbox::before {
  content: "\e907";
}
.custom-icon.move-down::before {
  content: "\ea47";
}
.custom-icon.move-up::before {
  content: "\ea46";
}
.custom-icon.radio::before {
  content: "\e909";
}
.custom-icon.text::before {
  content: "\e90a";
}
.custom-icon.thumbs-up::before {
  content: "\e904";
}
.custom-icon.thumb-up::before {
  content: "\e905";
}
.custom-icon.thumb-middle::before {
  content: "\e903";
}
.custom-icon.thumb-down::before {
  content: "\e906";
}
.custom-icon.yes-no::before {
  content: "\e908";
}
.custom-icon.magnifying-glass-people::before {
  content: "\e900";
}
.custom-icon.hand-holding-people::before {
  content: "\e901";
}
.custom-icon.file-people::before {
  content: "\e902";
}

.custom-icon.thumb-down {
  transform: scale(-1, 1);
}

.custom-icon.lucca {
  background: url("./../images/lucca.svg") no-repeat center center;
}

.ico {
  vertical-align: middle;
  -webkit-transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
}
.ico--xs {
  font-size: 0.8rem;
}
.ico--xs.ico--primary {
  color: #3456d0;
}
.ico--xs.ico--secondary {
  color: #fa420f;
}
.ico--xs.ico--success {
  color: #2ba198;
}
.ico--xs.ico--warning {
  color: #fb9814;
}
.ico--xs.ico--danger {
  color: #ff485e;
}
.ico--xs.ico--neutral {
  color: #8898b9;
}
.ico--xs.ico--primary--1 {
  color: #2e3862;
}
.ico--xs.ico--primary--2 {
  color: #303d74;
}
.ico--xs.ico--primary--3 {
  color: #304287;
}
.ico--xs.ico--primary--4 {
  color: #324cab;
}
.ico--xs.ico--primary--5 {
  color: #3456d0;
}
.ico--xs.ico--primary--6 {
  color: #3d64ed;
}
.ico--xs.ico--primary--7 {
  color: #7491f7;
}
.ico--xs.ico--primary--8 {
  color: #96acf9;
}
.ico--xs.ico--primary--9 {
  color: #b9c7fb;
}
.ico--xs.ico--primary--10 {
  color: #dce4fd;
}
.ico--xs.ico--primary--11 {
  color: #eef4ff;
}
.ico--xs.ico--primary--12 {
  color: #fafcff;
}
.ico--xs.ico--secondary--1 {
  color: #7e2210;
}
.ico--xs.ico--secondary--2 {
  color: #9d2b0f;
}
.ico--xs.ico--secondary--3 {
  color: #c62e08;
}
.ico--xs.ico--secondary--4 {
  color: #dd370e;
}
.ico--xs.ico--secondary--5 {
  color: #fa420f;
}
.ico--xs.ico--secondary--6 {
  color: #ff6136;
}
.ico--xs.ico--secondary--7 {
  color: #ff9271;
}
.ico--xs.ico--secondary--8 {
  color: #ffbea8;
}
.ico--xs.ico--secondary--9 {
  color: #ffdfd4;
}
.ico--xs.ico--secondary--10 {
  color: #fff2ed;
}
.ico--xs.ico--secondary--11 {
  color: #fffbfa;
}
.ico--xs.ico--success--1 {
  color: #1b4644;
}
.ico--xs.ico--success--2 {
  color: #1c5352;
}
.ico--xs.ico--success--3 {
  color: #1d6864;
}
.ico--xs.ico--success--4 {
  color: #20817c;
}
.ico--xs.ico--success--5 {
  color: #2ba198;
}
.ico--xs.ico--success--6 {
  color: #44bdb2;
}
.ico--xs.ico--success--7 {
  color: #64d3c5;
}
.ico--xs.ico--success--8 {
  color: #a6e9de;
}
.ico--xs.ico--success--9 {
  color: #d2f5ee;
}
.ico--xs.ico--success--10 {
  color: #f2fbf9;
}
.ico--xs.ico--warning--1 {
  color: #7d3d11;
}
.ico--xs.ico--warning--2 {
  color: #9b4911;
}
.ico--xs.ico--warning--3 {
  color: #c45e0a;
}
.ico--xs.ico--warning--4 {
  color: #f5810b;
}
.ico--xs.ico--warning--5 {
  color: #fb9814;
}
.ico--xs.ico--warning--6 {
  color: #fdb23a;
}
.ico--xs.ico--warning--7 {
  color: #fed173;
}
.ico--xs.ico--warning--8 {
  color: #ffe5a9;
}
.ico--xs.ico--warning--9 {
  color: #fff4d5;
}
.ico--xs.ico--warning--10 {
  color: #fffaed;
}
.ico--xs.ico--danger--1 {
  color: #8f0c2d;
}
.ico--xs.ico--danger--2 {
  color: #a8092d;
}
.ico--xs.ico--danger--3 {
  color: #c8082d;
}
.ico--xs.ico--danger--4 {
  color: #ed1136;
}
.ico--xs.ico--danger--5 {
  color: #ff485e;
}
.ico--xs.ico--danger--6 {
  color: #ff6977;
}
.ico--xs.ico--danger--7 {
  color: #ff9fa6;
}
.ico--xs.ico--danger--8 {
  color: #ffcace;
}
.ico--xs.ico--danger--9 {
  color: #ffe2e4;
}
.ico--xs.ico--danger--10 {
  color: #fff0f0;
}
.ico--xs.ico--neutral--0 {
  color: #181a20;
}
.ico--xs.ico--neutral--1 {
  color: #2b303b;
}
.ico--xs.ico--neutral--2 {
  color: #3e4556;
}
.ico--xs.ico--neutral--3 {
  color: #4e586f;
}
.ico--xs.ico--neutral--4 {
  color: #596d97;
}
.ico--xs.ico--neutral--5 {
  color: #8898b9;
}
.ico--xs.ico--neutral--6 {
  color: #bcc5d7;
}
.ico--xs.ico--neutral--7 {
  color: #dfe3ec;
}
.ico--xs.ico--neutral--8 {
  color: #e9ecf2;
}
.ico--xs.ico--neutral--9 {
  color: #f2f5f7;
}
.ico--xs.ico--neutral--10 {
  color: #fcfcfd;
}
.ico--xs.ico--social--facebook {
  color: #3b5998;
}
.ico--xs.ico--social--twitter {
  color: #1da1f2;
}
.ico--xs.ico--social--youtube {
  color: #cd201f;
}
.ico--xs.ico--social--linkedin {
  color: #0077b5;
}
.ico--xs.ico--social--frenchtex {
  color: #152f4a;
}
.ico--xs.ico--mpleo--background {
  color: #f0f1f9;
}
.ico--sm {
  font-size: 1.2rem;
}
.ico--sm.ico--primary {
  color: #3456d0;
}
.ico--sm.ico--secondary {
  color: #fa420f;
}
.ico--sm.ico--success {
  color: #2ba198;
}
.ico--sm.ico--warning {
  color: #fb9814;
}
.ico--sm.ico--danger {
  color: #ff485e;
}
.ico--sm.ico--neutral {
  color: #8898b9;
}
.ico--sm.ico--primary--1 {
  color: #2e3862;
}
.ico--sm.ico--primary--2 {
  color: #303d74;
}
.ico--sm.ico--primary--3 {
  color: #304287;
}
.ico--sm.ico--primary--4 {
  color: #324cab;
}
.ico--sm.ico--primary--5 {
  color: #3456d0;
}
.ico--sm.ico--primary--6 {
  color: #3d64ed;
}
.ico--sm.ico--primary--7 {
  color: #7491f7;
}
.ico--sm.ico--primary--8 {
  color: #96acf9;
}
.ico--sm.ico--primary--9 {
  color: #b9c7fb;
}
.ico--sm.ico--primary--10 {
  color: #dce4fd;
}
.ico--sm.ico--primary--11 {
  color: #eef4ff;
}
.ico--sm.ico--primary--12 {
  color: #fafcff;
}
.ico--sm.ico--secondary--1 {
  color: #7e2210;
}
.ico--sm.ico--secondary--2 {
  color: #9d2b0f;
}
.ico--sm.ico--secondary--3 {
  color: #c62e08;
}
.ico--sm.ico--secondary--4 {
  color: #dd370e;
}
.ico--sm.ico--secondary--5 {
  color: #fa420f;
}
.ico--sm.ico--secondary--6 {
  color: #ff6136;
}
.ico--sm.ico--secondary--7 {
  color: #ff9271;
}
.ico--sm.ico--secondary--8 {
  color: #ffbea8;
}
.ico--sm.ico--secondary--9 {
  color: #ffdfd4;
}
.ico--sm.ico--secondary--10 {
  color: #fff2ed;
}
.ico--sm.ico--secondary--11 {
  color: #fffbfa;
}
.ico--sm.ico--success--1 {
  color: #1b4644;
}
.ico--sm.ico--success--2 {
  color: #1c5352;
}
.ico--sm.ico--success--3 {
  color: #1d6864;
}
.ico--sm.ico--success--4 {
  color: #20817c;
}
.ico--sm.ico--success--5 {
  color: #2ba198;
}
.ico--sm.ico--success--6 {
  color: #44bdb2;
}
.ico--sm.ico--success--7 {
  color: #64d3c5;
}
.ico--sm.ico--success--8 {
  color: #a6e9de;
}
.ico--sm.ico--success--9 {
  color: #d2f5ee;
}
.ico--sm.ico--success--10 {
  color: #f2fbf9;
}
.ico--sm.ico--warning--1 {
  color: #7d3d11;
}
.ico--sm.ico--warning--2 {
  color: #9b4911;
}
.ico--sm.ico--warning--3 {
  color: #c45e0a;
}
.ico--sm.ico--warning--4 {
  color: #f5810b;
}
.ico--sm.ico--warning--5 {
  color: #fb9814;
}
.ico--sm.ico--warning--6 {
  color: #fdb23a;
}
.ico--sm.ico--warning--7 {
  color: #fed173;
}
.ico--sm.ico--warning--8 {
  color: #ffe5a9;
}
.ico--sm.ico--warning--9 {
  color: #fff4d5;
}
.ico--sm.ico--warning--10 {
  color: #fffaed;
}
.ico--sm.ico--danger--1 {
  color: #8f0c2d;
}
.ico--sm.ico--danger--2 {
  color: #a8092d;
}
.ico--sm.ico--danger--3 {
  color: #c8082d;
}
.ico--sm.ico--danger--4 {
  color: #ed1136;
}
.ico--sm.ico--danger--5 {
  color: #ff485e;
}
.ico--sm.ico--danger--6 {
  color: #ff6977;
}
.ico--sm.ico--danger--7 {
  color: #ff9fa6;
}
.ico--sm.ico--danger--8 {
  color: #ffcace;
}
.ico--sm.ico--danger--9 {
  color: #ffe2e4;
}
.ico--sm.ico--danger--10 {
  color: #fff0f0;
}
.ico--sm.ico--neutral--0 {
  color: #181a20;
}
.ico--sm.ico--neutral--1 {
  color: #2b303b;
}
.ico--sm.ico--neutral--2 {
  color: #3e4556;
}
.ico--sm.ico--neutral--3 {
  color: #4e586f;
}
.ico--sm.ico--neutral--4 {
  color: #596d97;
}
.ico--sm.ico--neutral--5 {
  color: #8898b9;
}
.ico--sm.ico--neutral--6 {
  color: #bcc5d7;
}
.ico--sm.ico--neutral--7 {
  color: #dfe3ec;
}
.ico--sm.ico--neutral--8 {
  color: #e9ecf2;
}
.ico--sm.ico--neutral--9 {
  color: #f2f5f7;
}
.ico--sm.ico--neutral--10 {
  color: #fcfcfd;
}
.ico--sm.ico--social--facebook {
  color: #3b5998;
}
.ico--sm.ico--social--twitter {
  color: #1da1f2;
}
.ico--sm.ico--social--youtube {
  color: #cd201f;
}
.ico--sm.ico--social--linkedin {
  color: #0077b5;
}
.ico--sm.ico--social--frenchtex {
  color: #152f4a;
}
.ico--sm.ico--mpleo--background {
  color: #f0f1f9;
}
.ico--md {
  font-size: 1.8rem;
}
.ico--md.ico--primary {
  color: #3456d0;
}
.ico--md.ico--secondary {
  color: #fa420f;
}
.ico--md.ico--success {
  color: #2ba198;
}
.ico--md.ico--warning {
  color: #fb9814;
}
.ico--md.ico--danger {
  color: #ff485e;
}
.ico--md.ico--neutral {
  color: #8898b9;
}
.ico--md.ico--primary--1 {
  color: #2e3862;
}
.ico--md.ico--primary--2 {
  color: #303d74;
}
.ico--md.ico--primary--3 {
  color: #304287;
}
.ico--md.ico--primary--4 {
  color: #324cab;
}
.ico--md.ico--primary--5 {
  color: #3456d0;
}
.ico--md.ico--primary--6 {
  color: #3d64ed;
}
.ico--md.ico--primary--7 {
  color: #7491f7;
}
.ico--md.ico--primary--8 {
  color: #96acf9;
}
.ico--md.ico--primary--9 {
  color: #b9c7fb;
}
.ico--md.ico--primary--10 {
  color: #dce4fd;
}
.ico--md.ico--primary--11 {
  color: #eef4ff;
}
.ico--md.ico--primary--12 {
  color: #fafcff;
}
.ico--md.ico--secondary--1 {
  color: #7e2210;
}
.ico--md.ico--secondary--2 {
  color: #9d2b0f;
}
.ico--md.ico--secondary--3 {
  color: #c62e08;
}
.ico--md.ico--secondary--4 {
  color: #dd370e;
}
.ico--md.ico--secondary--5 {
  color: #fa420f;
}
.ico--md.ico--secondary--6 {
  color: #ff6136;
}
.ico--md.ico--secondary--7 {
  color: #ff9271;
}
.ico--md.ico--secondary--8 {
  color: #ffbea8;
}
.ico--md.ico--secondary--9 {
  color: #ffdfd4;
}
.ico--md.ico--secondary--10 {
  color: #fff2ed;
}
.ico--md.ico--secondary--11 {
  color: #fffbfa;
}
.ico--md.ico--success--1 {
  color: #1b4644;
}
.ico--md.ico--success--2 {
  color: #1c5352;
}
.ico--md.ico--success--3 {
  color: #1d6864;
}
.ico--md.ico--success--4 {
  color: #20817c;
}
.ico--md.ico--success--5 {
  color: #2ba198;
}
.ico--md.ico--success--6 {
  color: #44bdb2;
}
.ico--md.ico--success--7 {
  color: #64d3c5;
}
.ico--md.ico--success--8 {
  color: #a6e9de;
}
.ico--md.ico--success--9 {
  color: #d2f5ee;
}
.ico--md.ico--success--10 {
  color: #f2fbf9;
}
.ico--md.ico--warning--1 {
  color: #7d3d11;
}
.ico--md.ico--warning--2 {
  color: #9b4911;
}
.ico--md.ico--warning--3 {
  color: #c45e0a;
}
.ico--md.ico--warning--4 {
  color: #f5810b;
}
.ico--md.ico--warning--5 {
  color: #fb9814;
}
.ico--md.ico--warning--6 {
  color: #fdb23a;
}
.ico--md.ico--warning--7 {
  color: #fed173;
}
.ico--md.ico--warning--8 {
  color: #ffe5a9;
}
.ico--md.ico--warning--9 {
  color: #fff4d5;
}
.ico--md.ico--warning--10 {
  color: #fffaed;
}
.ico--md.ico--danger--1 {
  color: #8f0c2d;
}
.ico--md.ico--danger--2 {
  color: #a8092d;
}
.ico--md.ico--danger--3 {
  color: #c8082d;
}
.ico--md.ico--danger--4 {
  color: #ed1136;
}
.ico--md.ico--danger--5 {
  color: #ff485e;
}
.ico--md.ico--danger--6 {
  color: #ff6977;
}
.ico--md.ico--danger--7 {
  color: #ff9fa6;
}
.ico--md.ico--danger--8 {
  color: #ffcace;
}
.ico--md.ico--danger--9 {
  color: #ffe2e4;
}
.ico--md.ico--danger--10 {
  color: #fff0f0;
}
.ico--md.ico--neutral--0 {
  color: #181a20;
}
.ico--md.ico--neutral--1 {
  color: #2b303b;
}
.ico--md.ico--neutral--2 {
  color: #3e4556;
}
.ico--md.ico--neutral--3 {
  color: #4e586f;
}
.ico--md.ico--neutral--4 {
  color: #596d97;
}
.ico--md.ico--neutral--5 {
  color: #8898b9;
}
.ico--md.ico--neutral--6 {
  color: #bcc5d7;
}
.ico--md.ico--neutral--7 {
  color: #dfe3ec;
}
.ico--md.ico--neutral--8 {
  color: #e9ecf2;
}
.ico--md.ico--neutral--9 {
  color: #f2f5f7;
}
.ico--md.ico--neutral--10 {
  color: #fcfcfd;
}
.ico--md.ico--social--facebook {
  color: #3b5998;
}
.ico--md.ico--social--twitter {
  color: #1da1f2;
}
.ico--md.ico--social--youtube {
  color: #cd201f;
}
.ico--md.ico--social--linkedin {
  color: #0077b5;
}
.ico--md.ico--social--frenchtex {
  color: #152f4a;
}
.ico--md.ico--mpleo--background {
  color: #f0f1f9;
}
.ico--lg, .ico--xl {
  display: block;
  font-size: 1.6rem;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  text-align: center;
  line-height: 3.5rem;
}
.ico--lg.ico--primary, .ico--xl.ico--primary {
  color: #3456d0;
  background-color: #b9c7fb;
}
.ico--lg.ico--secondary, .ico--xl.ico--secondary {
  color: #fa420f;
  background-color: #ffdfd4;
}
.ico--lg.ico--success, .ico--xl.ico--success {
  color: #2ba198;
  background-color: #d2f5ee;
}
.ico--lg.ico--warning, .ico--xl.ico--warning {
  color: #fb9814;
  background-color: #fff4d5;
}
.ico--lg.ico--danger, .ico--xl.ico--danger {
  color: #ff485e;
  background-color: #ffe2e4;
}
.ico--lg.ico--neutral, .ico--xl.ico--neutral {
  color: #8898b9;
  background-color: #f2f5f7;
}
.ico--lg.ico--primary--1, .ico--xl.ico--primary--1 {
  color: #2e3862;
  background-color: #3d64ed;
}
.ico--lg.ico--primary--1.bor, .ico--xl.ico--primary--1.bor {
  border: 1px solid #3456d0;
}
.ico--lg.ico--primary--2, .ico--xl.ico--primary--2 {
  color: #303d74;
  background-color: #7491f7;
}
.ico--lg.ico--primary--2.bor, .ico--xl.ico--primary--2.bor {
  border: 1px solid #3d64ed;
}
.ico--lg.ico--primary--3, .ico--xl.ico--primary--3 {
  color: #304287;
  background-color: #96acf9;
}
.ico--lg.ico--primary--3.bor, .ico--xl.ico--primary--3.bor {
  border: 1px solid #7491f7;
}
.ico--lg.ico--primary--4, .ico--xl.ico--primary--4 {
  color: #324cab;
  background-color: #b9c7fb;
}
.ico--lg.ico--primary--4.bor, .ico--xl.ico--primary--4.bor {
  border: 1px solid #96acf9;
}
.ico--lg.ico--primary--5, .ico--xl.ico--primary--5 {
  color: #3456d0;
  background-color: #dce4fd;
}
.ico--lg.ico--primary--5.bor, .ico--xl.ico--primary--5.bor {
  border: 1px solid #b9c7fb;
}
.ico--lg.ico--primary--6, .ico--xl.ico--primary--6 {
  color: #3d64ed;
  background-color: #eef4ff;
}
.ico--lg.ico--primary--6.bor, .ico--xl.ico--primary--6.bor {
  border: 1px solid #dce4fd;
}
.ico--lg.ico--primary--7, .ico--xl.ico--primary--7 {
  color: #304287;
  background-color: #7491f7;
}
.ico--lg.ico--primary--7.bor, .ico--xl.ico--primary--7.bor {
  border: 1px solid #3456d0;
}
.ico--lg.ico--primary--8, .ico--xl.ico--primary--8 {
  color: #324cab;
  background-color: #96acf9;
}
.ico--lg.ico--primary--8.bor, .ico--xl.ico--primary--8.bor {
  border: 1px solid #3d64ed;
}
.ico--lg.ico--primary--9, .ico--xl.ico--primary--9 {
  color: #3456d0;
  background-color: #b9c7fb;
}
.ico--lg.ico--primary--9.bor, .ico--xl.ico--primary--9.bor {
  border: 1px solid #7491f7;
}
.ico--lg.ico--primary--10, .ico--xl.ico--primary--10 {
  color: #3d64ed;
  background-color: #dce4fd;
}
.ico--lg.ico--primary--10.bor, .ico--xl.ico--primary--10.bor {
  border: 1px solid #96acf9;
}
.ico--lg.ico--primary--11, .ico--xl.ico--primary--11 {
  color: #2e3862;
  background-color: #eef4ff;
}
.ico--lg.ico--primary--11.bor, .ico--xl.ico--primary--11.bor {
  border: 1px solid #dce4fd;
}
.ico--lg.ico--primary--12, .ico--xl.ico--primary--12 {
  color: #96acf9;
  background-color: #fafcff;
}
.ico--lg.ico--primary--12.bor, .ico--xl.ico--primary--12.bor {
  border: 1px solid #dce4fd;
}
.ico--lg.ico--secondary--1, .ico--xl.ico--secondary--1 {
  color: #7e2210;
  background-color: #ff6136;
}
.ico--lg.ico--secondary--1.bor, .ico--xl.ico--secondary--1.bor {
  border: 1px solid #fa420f;
}
.ico--lg.ico--secondary--2, .ico--xl.ico--secondary--2 {
  color: #9d2b0f;
  background-color: #ff9271;
}
.ico--lg.ico--secondary--2.bor, .ico--xl.ico--secondary--2.bor {
  border: 1px solid #ff6136;
}
.ico--lg.ico--secondary--3, .ico--xl.ico--secondary--3 {
  color: #c62e08;
  background-color: #ffbea8;
}
.ico--lg.ico--secondary--3.bor, .ico--xl.ico--secondary--3.bor {
  border: 1px solid #ff9271;
}
.ico--lg.ico--secondary--4, .ico--xl.ico--secondary--4 {
  color: #dd370e;
  background-color: #ffdfd4;
}
.ico--lg.ico--secondary--4.bor, .ico--xl.ico--secondary--4.bor {
  border: 1px solid #ffbea8;
}
.ico--lg.ico--secondary--5, .ico--xl.ico--secondary--5 {
  color: #fa420f;
  background-color: #fff2ed;
}
.ico--lg.ico--secondary--5.bor, .ico--xl.ico--secondary--5.bor {
  border: 1px solid #ffdfd4;
}
.ico--lg.ico--secondary--6, .ico--xl.ico--secondary--6 {
  color: #ff6136;
  background-color: #fffbfa;
}
.ico--lg.ico--secondary--6.bor, .ico--xl.ico--secondary--6.bor {
  border: 1px solid #fff2ed;
}
.ico--lg.ico--secondary--7, .ico--xl.ico--secondary--7 {
  color: #c62e08;
  background-color: #ff9271;
}
.ico--lg.ico--secondary--7.bor, .ico--xl.ico--secondary--7.bor {
  border: 1px solid #fa420f;
}
.ico--lg.ico--secondary--8, .ico--xl.ico--secondary--8 {
  color: #dd370e;
  background-color: #ffbea8;
}
.ico--lg.ico--secondary--8.bor, .ico--xl.ico--secondary--8.bor {
  border: 1px solid #ff6136;
}
.ico--lg.ico--secondary--9, .ico--xl.ico--secondary--9 {
  color: #fa420f;
  background-color: #ffdfd4;
}
.ico--lg.ico--secondary--9.bor, .ico--xl.ico--secondary--9.bor {
  border: 1px solid #ff9271;
}
.ico--lg.ico--secondary--10, .ico--xl.ico--secondary--10 {
  color: #ff6136;
  background-color: #fff2ed;
}
.ico--lg.ico--secondary--10.bor, .ico--xl.ico--secondary--10.bor {
  border: 1px solid #ffbea8;
}
.ico--lg.ico--secondary--11, .ico--xl.ico--secondary--11 {
  color: #7e2210;
  background-color: #fffbfa;
}
.ico--lg.ico--secondary--11.bor, .ico--xl.ico--secondary--11.bor {
  border: 1px solid #fff2ed;
}
.ico--lg.ico--success--1, .ico--xl.ico--success--1 {
  color: #1b4644;
  background-color: #44bdb2;
}
.ico--lg.ico--success--1.bor, .ico--xl.ico--success--1.bor {
  border: 1px solid #2ba198;
}
.ico--lg.ico--success--2, .ico--xl.ico--success--2 {
  color: #1c5352;
  background-color: #64d3c5;
}
.ico--lg.ico--success--2.bor, .ico--xl.ico--success--2.bor {
  border: 1px solid #44bdb2;
}
.ico--lg.ico--success--3, .ico--xl.ico--success--3 {
  color: #1d6864;
  background-color: #a6e9de;
}
.ico--lg.ico--success--3.bor, .ico--xl.ico--success--3.bor {
  border: 1px solid #64d3c5;
}
.ico--lg.ico--success--4, .ico--xl.ico--success--4 {
  color: #20817c;
  background-color: #d2f5ee;
}
.ico--lg.ico--success--4.bor, .ico--xl.ico--success--4.bor {
  border: 1px solid #a6e9de;
}
.ico--lg.ico--success--5, .ico--xl.ico--success--5 {
  color: #2ba198;
  background-color: #f2fbf9;
}
.ico--lg.ico--success--5.bor, .ico--xl.ico--success--5.bor {
  border: 1px solid #d2f5ee;
}
.ico--lg.ico--success--6, .ico--xl.ico--success--6 {
  color: #44bdb2;
}
.ico--lg.ico--success--6.bor, .ico--xl.ico--success--6.bor {
  border: 1px solid #f2fbf9;
}
.ico--lg.ico--success--7, .ico--xl.ico--success--7 {
  color: #1d6864;
  background-color: #64d3c5;
}
.ico--lg.ico--success--7.bor, .ico--xl.ico--success--7.bor {
  border: 1px solid #2ba198;
}
.ico--lg.ico--success--8, .ico--xl.ico--success--8 {
  color: #20817c;
  background-color: #a6e9de;
}
.ico--lg.ico--success--8.bor, .ico--xl.ico--success--8.bor {
  border: 1px solid #44bdb2;
}
.ico--lg.ico--success--9, .ico--xl.ico--success--9 {
  color: #2ba198;
  background-color: #d2f5ee;
}
.ico--lg.ico--success--9.bor, .ico--xl.ico--success--9.bor {
  border: 1px solid #64d3c5;
}
.ico--lg.ico--success--10, .ico--xl.ico--success--10 {
  color: #44bdb2;
  background-color: #f2fbf9;
}
.ico--lg.ico--success--10.bor, .ico--xl.ico--success--10.bor {
  border: 1px solid #a6e9de;
}
.ico--lg.ico--warning--1, .ico--xl.ico--warning--1 {
  color: #7d3d11;
  background-color: #fdb23a;
}
.ico--lg.ico--warning--1.bor, .ico--xl.ico--warning--1.bor {
  border: 1px solid #fb9814;
}
.ico--lg.ico--warning--2, .ico--xl.ico--warning--2 {
  color: #9b4911;
  background-color: #fed173;
}
.ico--lg.ico--warning--2.bor, .ico--xl.ico--warning--2.bor {
  border: 1px solid #fdb23a;
}
.ico--lg.ico--warning--3, .ico--xl.ico--warning--3 {
  color: #c45e0a;
  background-color: #ffe5a9;
}
.ico--lg.ico--warning--3.bor, .ico--xl.ico--warning--3.bor {
  border: 1px solid #fed173;
}
.ico--lg.ico--warning--4, .ico--xl.ico--warning--4 {
  color: #f5810b;
  background-color: #fff4d5;
}
.ico--lg.ico--warning--4.bor, .ico--xl.ico--warning--4.bor {
  border: 1px solid #ffe5a9;
}
.ico--lg.ico--warning--5, .ico--xl.ico--warning--5 {
  color: #fb9814;
  background-color: #fffaed;
}
.ico--lg.ico--warning--5.bor, .ico--xl.ico--warning--5.bor {
  border: 1px solid #fff4d5;
}
.ico--lg.ico--warning--6, .ico--xl.ico--warning--6 {
  color: #fdb23a;
}
.ico--lg.ico--warning--6.bor, .ico--xl.ico--warning--6.bor {
  border: 1px solid #fffaed;
}
.ico--lg.ico--warning--7, .ico--xl.ico--warning--7 {
  color: #c45e0a;
  background-color: #fed173;
}
.ico--lg.ico--warning--7.bor, .ico--xl.ico--warning--7.bor {
  border: 1px solid #fb9814;
}
.ico--lg.ico--warning--8, .ico--xl.ico--warning--8 {
  color: #f5810b;
  background-color: #ffe5a9;
}
.ico--lg.ico--warning--8.bor, .ico--xl.ico--warning--8.bor {
  border: 1px solid #fdb23a;
}
.ico--lg.ico--warning--9, .ico--xl.ico--warning--9 {
  color: #fb9814;
  background-color: #fff4d5;
}
.ico--lg.ico--warning--9.bor, .ico--xl.ico--warning--9.bor {
  border: 1px solid #fed173;
}
.ico--lg.ico--warning--10, .ico--xl.ico--warning--10 {
  color: #fdb23a;
  background-color: #fffaed;
}
.ico--lg.ico--warning--10.bor, .ico--xl.ico--warning--10.bor {
  border: 1px solid #ffe5a9;
}
.ico--lg.ico--danger--1, .ico--xl.ico--danger--1 {
  color: #8f0c2d;
  background-color: #ff6977;
}
.ico--lg.ico--danger--1.bor, .ico--xl.ico--danger--1.bor {
  border: 1px solid #ff485e;
}
.ico--lg.ico--danger--2, .ico--xl.ico--danger--2 {
  color: #a8092d;
  background-color: #ff9fa6;
}
.ico--lg.ico--danger--2.bor, .ico--xl.ico--danger--2.bor {
  border: 1px solid #ff6977;
}
.ico--lg.ico--danger--3, .ico--xl.ico--danger--3 {
  color: #c8082d;
  background-color: #ffcace;
}
.ico--lg.ico--danger--3.bor, .ico--xl.ico--danger--3.bor {
  border: 1px solid #ff9fa6;
}
.ico--lg.ico--danger--4, .ico--xl.ico--danger--4 {
  color: #ed1136;
  background-color: #ffe2e4;
}
.ico--lg.ico--danger--4.bor, .ico--xl.ico--danger--4.bor {
  border: 1px solid #ffcace;
}
.ico--lg.ico--danger--5, .ico--xl.ico--danger--5 {
  color: #ff485e;
  background-color: #fff0f0;
}
.ico--lg.ico--danger--5.bor, .ico--xl.ico--danger--5.bor {
  border: 1px solid #ffe2e4;
}
.ico--lg.ico--danger--6, .ico--xl.ico--danger--6 {
  color: #ff6977;
}
.ico--lg.ico--danger--6.bor, .ico--xl.ico--danger--6.bor {
  border: 1px solid #fff0f0;
}
.ico--lg.ico--danger--7, .ico--xl.ico--danger--7 {
  color: #c8082d;
  background-color: #ff9fa6;
}
.ico--lg.ico--danger--7.bor, .ico--xl.ico--danger--7.bor {
  border: 1px solid #ff485e;
}
.ico--lg.ico--danger--8, .ico--xl.ico--danger--8 {
  color: #ed1136;
  background-color: #ffcace;
}
.ico--lg.ico--danger--8.bor, .ico--xl.ico--danger--8.bor {
  border: 1px solid #ff6977;
}
.ico--lg.ico--danger--9, .ico--xl.ico--danger--9 {
  color: #ff485e;
  background-color: #ffe2e4;
}
.ico--lg.ico--danger--9.bor, .ico--xl.ico--danger--9.bor {
  border: 1px solid #ff9fa6;
}
.ico--lg.ico--danger--10, .ico--xl.ico--danger--10 {
  color: #ff6977;
  background-color: #fff0f0;
}
.ico--lg.ico--danger--10.bor, .ico--xl.ico--danger--10.bor {
  border: 1px solid #ffcace;
}
.ico--lg.ico--neutral--0, .ico--xl.ico--neutral--0 {
  color: #181a20;
  background-color: #bcc5d7;
}
.ico--lg.ico--neutral--0.bor, .ico--xl.ico--neutral--0.bor {
  border: 1px solid #8898b9;
}
.ico--lg.ico--neutral--1, .ico--xl.ico--neutral--1 {
  color: #2b303b;
  background-color: #dfe3ec;
}
.ico--lg.ico--neutral--1.bor, .ico--xl.ico--neutral--1.bor {
  border: 1px solid #bcc5d7;
}
.ico--lg.ico--neutral--2, .ico--xl.ico--neutral--2 {
  color: #3e4556;
  background-color: #e9ecf2;
}
.ico--lg.ico--neutral--2.bor, .ico--xl.ico--neutral--2.bor {
  border: 1px solid #dfe3ec;
}
.ico--lg.ico--neutral--3, .ico--xl.ico--neutral--3 {
  color: #4e586f;
  background-color: #f2f5f7;
}
.ico--lg.ico--neutral--3.bor, .ico--xl.ico--neutral--3.bor {
  border: 1px solid #e9ecf2;
}
.ico--lg.ico--neutral--4, .ico--xl.ico--neutral--4 {
  color: #596d97;
  background-color: #fcfcfd;
}
.ico--lg.ico--neutral--4.bor, .ico--xl.ico--neutral--4.bor {
  border: 1px solid #f2f5f7;
}
.ico--lg.ico--neutral--5, .ico--xl.ico--neutral--5 {
  color: #8898b9;
}
.ico--lg.ico--neutral--5.bor, .ico--xl.ico--neutral--5.bor {
  border: 1px solid #fcfcfd;
}
.ico--lg.ico--neutral--6, .ico--xl.ico--neutral--6 {
  color: #4e586f;
  background-color: #bcc5d7;
}
.ico--lg.ico--neutral--6.bor, .ico--xl.ico--neutral--6.bor {
  border: 1px solid #8898b9;
}
.ico--lg.ico--neutral--7, .ico--xl.ico--neutral--7 {
  color: #596d97;
  background-color: #dfe3ec;
}
.ico--lg.ico--neutral--7.bor, .ico--xl.ico--neutral--7.bor {
  border: 1px solid #bcc5d7;
}
.ico--lg.ico--neutral--8, .ico--xl.ico--neutral--8 {
  color: #8898b9;
  background-color: #e9ecf2;
}
.ico--lg.ico--neutral--8.bor, .ico--xl.ico--neutral--8.bor {
  border: 1px solid #dfe3ec;
}
.ico--lg.ico--neutral--9, .ico--xl.ico--neutral--9 {
  color: #bcc5d7;
  background-color: #f2f5f7;
}
.ico--lg.ico--neutral--9.bor, .ico--xl.ico--neutral--9.bor {
  border: 1px solid #e9ecf2;
}
.ico--lg.ico--neutral--10, .ico--xl.ico--neutral--10 {
  color: #2b303b;
  background-color: #fcfcfd;
}
.ico--lg.ico--neutral--10.bor, .ico--xl.ico--neutral--10.bor {
  border: 1px solid #fcfcfd;
}
.ico--lg.ico--mpleo--background, .ico--xl.ico--mpleo--background {
  color: #f0f1f9;
}
.ico--lg.ico--mpleo--background.bor, .ico--xl.ico--mpleo--background.bor {
  border: 1px solid;
}
.ico--xl {
  font-size: 1.8rem;
  width: 3.8rem;
  height: 3.8rem;
  line-height: 3.8rem;
}
.ico--xxl {
  font-size: 4rem;
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
}
.ico--xxl.ico--primary {
  color: #3456d0;
}
.ico--xxl.ico--secondary {
  color: #fa420f;
}
.ico--xxl.ico--success {
  color: #2ba198;
}
.ico--xxl.ico--warning {
  color: #fb9814;
}
.ico--xxl.ico--danger {
  color: #ff485e;
}
.ico--xxl.ico--neutral {
  color: #8898b9;
}
.ico--xxl.ico--primary--1 {
  color: #2e3862;
}
.ico--xxl.ico--primary--2 {
  color: #303d74;
}
.ico--xxl.ico--primary--3 {
  color: #304287;
}
.ico--xxl.ico--primary--4 {
  color: #324cab;
}
.ico--xxl.ico--primary--5 {
  color: #3456d0;
}
.ico--xxl.ico--primary--6 {
  color: #3d64ed;
}
.ico--xxl.ico--primary--7 {
  color: #7491f7;
}
.ico--xxl.ico--primary--8 {
  color: #96acf9;
}
.ico--xxl.ico--primary--9 {
  color: #b9c7fb;
}
.ico--xxl.ico--primary--10 {
  color: #dce4fd;
}
.ico--xxl.ico--primary--11 {
  color: #eef4ff;
}
.ico--xxl.ico--primary--12 {
  color: #fafcff;
}
.ico--xxl.ico--secondary--1 {
  color: #7e2210;
}
.ico--xxl.ico--secondary--2 {
  color: #9d2b0f;
}
.ico--xxl.ico--secondary--3 {
  color: #c62e08;
}
.ico--xxl.ico--secondary--4 {
  color: #dd370e;
}
.ico--xxl.ico--secondary--5 {
  color: #fa420f;
}
.ico--xxl.ico--secondary--6 {
  color: #ff6136;
}
.ico--xxl.ico--secondary--7 {
  color: #ff9271;
}
.ico--xxl.ico--secondary--8 {
  color: #ffbea8;
}
.ico--xxl.ico--secondary--9 {
  color: #ffdfd4;
}
.ico--xxl.ico--secondary--10 {
  color: #fff2ed;
}
.ico--xxl.ico--secondary--11 {
  color: #fffbfa;
}
.ico--xxl.ico--success--1 {
  color: #1b4644;
}
.ico--xxl.ico--success--2 {
  color: #1c5352;
}
.ico--xxl.ico--success--3 {
  color: #1d6864;
}
.ico--xxl.ico--success--4 {
  color: #20817c;
}
.ico--xxl.ico--success--5 {
  color: #2ba198;
}
.ico--xxl.ico--success--6 {
  color: #44bdb2;
}
.ico--xxl.ico--success--7 {
  color: #64d3c5;
}
.ico--xxl.ico--success--8 {
  color: #a6e9de;
}
.ico--xxl.ico--success--9 {
  color: #d2f5ee;
}
.ico--xxl.ico--success--10 {
  color: #f2fbf9;
}
.ico--xxl.ico--warning--1 {
  color: #7d3d11;
}
.ico--xxl.ico--warning--2 {
  color: #9b4911;
}
.ico--xxl.ico--warning--3 {
  color: #c45e0a;
}
.ico--xxl.ico--warning--4 {
  color: #f5810b;
}
.ico--xxl.ico--warning--5 {
  color: #fb9814;
}
.ico--xxl.ico--warning--6 {
  color: #fdb23a;
}
.ico--xxl.ico--warning--7 {
  color: #fed173;
}
.ico--xxl.ico--warning--8 {
  color: #ffe5a9;
}
.ico--xxl.ico--warning--9 {
  color: #fff4d5;
}
.ico--xxl.ico--warning--10 {
  color: #fffaed;
}
.ico--xxl.ico--danger--1 {
  color: #8f0c2d;
}
.ico--xxl.ico--danger--2 {
  color: #a8092d;
}
.ico--xxl.ico--danger--3 {
  color: #c8082d;
}
.ico--xxl.ico--danger--4 {
  color: #ed1136;
}
.ico--xxl.ico--danger--5 {
  color: #ff485e;
}
.ico--xxl.ico--danger--6 {
  color: #ff6977;
}
.ico--xxl.ico--danger--7 {
  color: #ff9fa6;
}
.ico--xxl.ico--danger--8 {
  color: #ffcace;
}
.ico--xxl.ico--danger--9 {
  color: #ffe2e4;
}
.ico--xxl.ico--danger--10 {
  color: #fff0f0;
}
.ico--xxl.ico--neutral--0 {
  color: #181a20;
}
.ico--xxl.ico--neutral--1 {
  color: #2b303b;
}
.ico--xxl.ico--neutral--2 {
  color: #3e4556;
}
.ico--xxl.ico--neutral--3 {
  color: #4e586f;
}
.ico--xxl.ico--neutral--4 {
  color: #596d97;
}
.ico--xxl.ico--neutral--5 {
  color: #8898b9;
}
.ico--xxl.ico--neutral--6 {
  color: #bcc5d7;
}
.ico--xxl.ico--neutral--7 {
  color: #dfe3ec;
}
.ico--xxl.ico--neutral--8 {
  color: #e9ecf2;
}
.ico--xxl.ico--neutral--9 {
  color: #f2f5f7;
}
.ico--xxl.ico--neutral--10 {
  color: #fcfcfd;
}
.ico--xxl.ico--social--facebook {
  color: #3b5998;
}
.ico--xxl.ico--social--twitter {
  color: #1da1f2;
}
.ico--xxl.ico--social--youtube {
  color: #cd201f;
}
.ico--xxl.ico--social--linkedin {
  color: #0077b5;
}
.ico--xxl.ico--social--frenchtex {
  color: #152f4a;
}
.ico--xxl.ico--mpleo--background {
  color: #f0f1f9;
}
.ico--menu-icon {
  font-size: 1.6rem;
}
.ico--menu-icon.ico--primary {
  color: #3456d0;
}
.ico--menu-icon.ico--secondary {
  color: #fa420f;
}
.ico--menu-icon.ico--success {
  color: #2ba198;
}
.ico--menu-icon.ico--warning {
  color: #fb9814;
}
.ico--menu-icon.ico--danger {
  color: #ff485e;
}
.ico--menu-icon.ico--neutral {
  color: #8898b9;
}
.ico--menu-icon.ico--primary--1 {
  color: #2e3862;
}
.ico--menu-icon.ico--primary--2 {
  color: #303d74;
}
.ico--menu-icon.ico--primary--3 {
  color: #304287;
}
.ico--menu-icon.ico--primary--4 {
  color: #324cab;
}
.ico--menu-icon.ico--primary--5 {
  color: #3456d0;
}
.ico--menu-icon.ico--primary--6 {
  color: #3d64ed;
}
.ico--menu-icon.ico--primary--7 {
  color: #7491f7;
}
.ico--menu-icon.ico--primary--8 {
  color: #96acf9;
}
.ico--menu-icon.ico--primary--9 {
  color: #b9c7fb;
}
.ico--menu-icon.ico--primary--10 {
  color: #dce4fd;
}
.ico--menu-icon.ico--primary--11 {
  color: #eef4ff;
}
.ico--menu-icon.ico--primary--12 {
  color: #fafcff;
}
.ico--menu-icon.ico--secondary--1 {
  color: #7e2210;
}
.ico--menu-icon.ico--secondary--2 {
  color: #9d2b0f;
}
.ico--menu-icon.ico--secondary--3 {
  color: #c62e08;
}
.ico--menu-icon.ico--secondary--4 {
  color: #dd370e;
}
.ico--menu-icon.ico--secondary--5 {
  color: #fa420f;
}
.ico--menu-icon.ico--secondary--6 {
  color: #ff6136;
}
.ico--menu-icon.ico--secondary--7 {
  color: #ff9271;
}
.ico--menu-icon.ico--secondary--8 {
  color: #ffbea8;
}
.ico--menu-icon.ico--secondary--9 {
  color: #ffdfd4;
}
.ico--menu-icon.ico--secondary--10 {
  color: #fff2ed;
}
.ico--menu-icon.ico--secondary--11 {
  color: #fffbfa;
}
.ico--menu-icon.ico--success--1 {
  color: #1b4644;
}
.ico--menu-icon.ico--success--2 {
  color: #1c5352;
}
.ico--menu-icon.ico--success--3 {
  color: #1d6864;
}
.ico--menu-icon.ico--success--4 {
  color: #20817c;
}
.ico--menu-icon.ico--success--5 {
  color: #2ba198;
}
.ico--menu-icon.ico--success--6 {
  color: #44bdb2;
}
.ico--menu-icon.ico--success--7 {
  color: #64d3c5;
}
.ico--menu-icon.ico--success--8 {
  color: #a6e9de;
}
.ico--menu-icon.ico--success--9 {
  color: #d2f5ee;
}
.ico--menu-icon.ico--success--10 {
  color: #f2fbf9;
}
.ico--menu-icon.ico--warning--1 {
  color: #7d3d11;
}
.ico--menu-icon.ico--warning--2 {
  color: #9b4911;
}
.ico--menu-icon.ico--warning--3 {
  color: #c45e0a;
}
.ico--menu-icon.ico--warning--4 {
  color: #f5810b;
}
.ico--menu-icon.ico--warning--5 {
  color: #fb9814;
}
.ico--menu-icon.ico--warning--6 {
  color: #fdb23a;
}
.ico--menu-icon.ico--warning--7 {
  color: #fed173;
}
.ico--menu-icon.ico--warning--8 {
  color: #ffe5a9;
}
.ico--menu-icon.ico--warning--9 {
  color: #fff4d5;
}
.ico--menu-icon.ico--warning--10 {
  color: #fffaed;
}
.ico--menu-icon.ico--danger--1 {
  color: #8f0c2d;
}
.ico--menu-icon.ico--danger--2 {
  color: #a8092d;
}
.ico--menu-icon.ico--danger--3 {
  color: #c8082d;
}
.ico--menu-icon.ico--danger--4 {
  color: #ed1136;
}
.ico--menu-icon.ico--danger--5 {
  color: #ff485e;
}
.ico--menu-icon.ico--danger--6 {
  color: #ff6977;
}
.ico--menu-icon.ico--danger--7 {
  color: #ff9fa6;
}
.ico--menu-icon.ico--danger--8 {
  color: #ffcace;
}
.ico--menu-icon.ico--danger--9 {
  color: #ffe2e4;
}
.ico--menu-icon.ico--danger--10 {
  color: #fff0f0;
}
.ico--menu-icon.ico--neutral--0 {
  color: #181a20;
}
.ico--menu-icon.ico--neutral--1 {
  color: #2b303b;
}
.ico--menu-icon.ico--neutral--2 {
  color: #3e4556;
}
.ico--menu-icon.ico--neutral--3 {
  color: #4e586f;
}
.ico--menu-icon.ico--neutral--4 {
  color: #596d97;
}
.ico--menu-icon.ico--neutral--5 {
  color: #8898b9;
}
.ico--menu-icon.ico--neutral--6 {
  color: #bcc5d7;
}
.ico--menu-icon.ico--neutral--7 {
  color: #dfe3ec;
}
.ico--menu-icon.ico--neutral--8 {
  color: #e9ecf2;
}
.ico--menu-icon.ico--neutral--9 {
  color: #f2f5f7;
}
.ico--menu-icon.ico--neutral--10 {
  color: #fcfcfd;
}
.ico--menu-icon.ico--social--facebook {
  color: #3b5998;
}
.ico--menu-icon.ico--social--twitter {
  color: #1da1f2;
}
.ico--menu-icon.ico--social--youtube {
  color: #cd201f;
}
.ico--menu-icon.ico--social--linkedin {
  color: #0077b5;
}
.ico--menu-icon.ico--social--frenchtex {
  color: #152f4a;
}
.ico--menu-icon.ico--mpleo--background {
  color: #f0f1f9;
}

.icon.spin::before,
.ico.spin::before {
  display: block;
  -webkit-animation: wr-spin 2s linear infinite;
  animation: wr-spin 2s linear infinite;
}
.icon.reverse::before,
.ico.reverse::before {
  display: block;
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

@-webkit-keyframes wr-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes wr-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.candidate-without-name {
  color: #fb9814 !important;
  background-color: rgba(255, 250, 237, 0.3) !important;
}

.transition--none {
  -webkit-transition: all 0ms ease-in-out;
  transition: all 0ms ease-in-out;
}
.transition--fast {
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}
.transition--default {
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
.transition--slow {
  -webkit-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.sh--sm {
  -webkit-box-shadow: 0px -1px 1px 0px rgba(43, 48, 59, 0.01), 0px 1px 1px 0px rgba(24, 26, 32, 0.03), 0px 2px 2px 0px rgba(24, 26, 32, 0.03), 0px 4px 4px 0px rgba(24, 26, 32, 0.02), 0px 15px 50px 0px rgba(24, 26, 32, 0.05);
  box-shadow: 0px -1px 1px 0px rgba(43, 48, 59, 0.01), 0px 1px 1px 0px rgba(24, 26, 32, 0.03), 0px 2px 2px 0px rgba(24, 26, 32, 0.03), 0px 4px 4px 0px rgba(24, 26, 32, 0.02), 0px 15px 50px 0px rgba(24, 26, 32, 0.05);
}
.sh--sm--primary {
  -webkit-box-shadow: 0px -1px 1px 0px rgba(52, 86, 208, 0.03), 0px -2px 2px 0px rgba(52, 86, 208, 0.03), 0px 1px 1px 0px rgba(52, 86, 208, 0.03), 0px 2px 2px 0px rgba(52, 86, 208, 0.03), 0px 4px 4px 0px rgba(52, 86, 208, 0.02), 0px 15px 250px 0px rgba(52, 86, 208, 0.05);
  box-shadow: 0px -1px 1px 0px rgba(52, 86, 208, 0.03), 0px -2px 2px 0px rgba(52, 86, 208, 0.03), 0px 1px 1px 0px rgba(52, 86, 208, 0.03), 0px 2px 2px 0px rgba(52, 86, 208, 0.03), 0px 4px 4px 0px rgba(52, 86, 208, 0.02), 0px 15px 250px 0px rgba(52, 86, 208, 0.05);
}
.sh--md {
  -webkit-box-shadow: 0px 1px 1px 0px rgba(24, 26, 32, 0.03), 0px 2px 2px 0px rgba(24, 26, 32, 0.03), 0px 4px 4px 0px rgba(24, 26, 32, 0.02), 0px 8px 8px 0px rgba(24, 26, 32, 0.01), 0px 16px 16px 0px rgba(24, 26, 32, 0.01), 0px 15px 50px 0px rgba(24, 26, 32, 0.05);
  box-shadow: 0px 1px 1px 0px rgba(24, 26, 32, 0.03), 0px 2px 2px 0px rgba(24, 26, 32, 0.03), 0px 4px 4px 0px rgba(24, 26, 32, 0.02), 0px 8px 8px 0px rgba(24, 26, 32, 0.01), 0px 16px 16px 0px rgba(24, 26, 32, 0.01), 0px 15px 50px 0px rgba(24, 26, 32, 0.05);
}
.sh--md--primary {
  -webkit-box-shadow: 0px -1px 1px 0px rgba(52, 86, 208, 0.03), 0px -2px 2px 0px rgba(52, 86, 208, 0.03), 0px 1px 1px 0px rgba(52, 86, 208, 0.03), 0px 2px 2px 0px rgba(52, 86, 208, 0.03), 0px 4px 4px 0px rgba(52, 86, 208, 0.02), 0px 8px 8px 0px rgba(52, 86, 208, 0.01), 0px 16px 16px 0px rgba(52, 86, 208, 0.01), 0px 15px 50px 0px rgba(52, 86, 208, 0.05);
  box-shadow: 0px -1px 1px 0px rgba(52, 86, 208, 0.03), 0px -2px 2px 0px rgba(52, 86, 208, 0.03), 0px 1px 1px 0px rgba(52, 86, 208, 0.03), 0px 2px 2px 0px rgba(52, 86, 208, 0.03), 0px 4px 4px 0px rgba(52, 86, 208, 0.02), 0px 8px 8px 0px rgba(52, 86, 208, 0.01), 0px 16px 16px 0px rgba(52, 86, 208, 0.01), 0px 15px 50px 0px rgba(52, 86, 208, 0.05);
}
.sh--lg {
  -webkit-box-shadow: 0px 2px 2px 0px rgba(24, 26, 32, 0.03), 0px 8px 8px 0px rgba(24, 26, 32, 0.01), 0px 16px 16px 0px rgba(24, 26, 32, 0.01), 0px 24px 24px 0px rgba(24, 26, 32, 0.01), 0px 32px 32px 0px rgba(24, 26, 32, 0.01), 0px 48px 48px 0px rgba(24, 26, 32, 0.01), 0px 64px 64px 0px rgba(24, 26, 32, 0.01), 0px 15px 50px 0px rgba(24, 26, 32, 0.05);
  box-shadow: 0px 2px 2px 0px rgba(24, 26, 32, 0.03), 0px 8px 8px 0px rgba(24, 26, 32, 0.01), 0px 16px 16px 0px rgba(24, 26, 32, 0.01), 0px 24px 24px 0px rgba(24, 26, 32, 0.01), 0px 32px 32px 0px rgba(24, 26, 32, 0.01), 0px 48px 48px 0px rgba(24, 26, 32, 0.01), 0px 64px 64px 0px rgba(24, 26, 32, 0.01), 0px 15px 50px 0px rgba(24, 26, 32, 0.05);
}
.sh--lg--primary {
  -webkit-box-shadow: 0px 4px 4px 0px rgba(52, 86, 208, 0.02), 0px 8px 8px 0px rgba(52, 86, 208, 0.01), 0px 16px 16px 0px rgba(52, 86, 208, 0.01), 0px 24px 24px 0px rgba(52, 86, 208, 0.01), 0px 32px 32px 0px rgba(52, 86, 208, 0.01), 0px 48px 48px 0px rgba(52, 86, 208, 0.01), 0px 64px 64px 0px rgba(52, 86, 208, 0.01), 0px 15px 50px 0px rgba(52, 86, 208, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(52, 86, 208, 0.02), 0px 8px 8px 0px rgba(52, 86, 208, 0.01), 0px 16px 16px 0px rgba(52, 86, 208, 0.01), 0px 24px 24px 0px rgba(52, 86, 208, 0.01), 0px 32px 32px 0px rgba(52, 86, 208, 0.01), 0px 48px 48px 0px rgba(52, 86, 208, 0.01), 0px 64px 64px 0px rgba(52, 86, 208, 0.01), 0px 15px 50px 0px rgba(52, 86, 208, 0.05);
}

.bor--primary--1 {
  border-color: #2e3862;
  border-width: 1px;
  border-style: solid;
}
.bor--primary--2 {
  border-color: #303d74;
  border-width: 1px;
  border-style: solid;
}
.bor--primary--3 {
  border-color: #304287;
  border-width: 1px;
  border-style: solid;
}
.bor--primary--4 {
  border-color: #324cab;
  border-width: 1px;
  border-style: solid;
}
.bor--primary--5 {
  border-color: #3456d0;
  border-width: 1px;
  border-style: solid;
}
.bor--primary--6 {
  border-color: #3d64ed;
  border-width: 1px;
  border-style: solid;
}
.bor--primary--7 {
  border-color: #7491f7;
  border-width: 1px;
  border-style: solid;
}
.bor--primary--8 {
  border-color: #96acf9;
  border-width: 1px;
  border-style: solid;
}
.bor--primary--9 {
  border-color: #b9c7fb;
  border-width: 1px;
  border-style: solid;
}
.bor--primary--10 {
  border-color: #dce4fd;
  border-width: 1px;
  border-style: solid;
}
.bor--primary--11 {
  border-color: #eef4ff;
  border-width: 1px;
  border-style: solid;
}
.bor--primary--12 {
  border-color: #fafcff;
  border-width: 1px;
  border-style: solid;
}
.bor--secondary--1 {
  border-color: #7e2210;
  border-width: 1px;
  border-style: solid;
}
.bor--secondary--2 {
  border-color: #9d2b0f;
  border-width: 1px;
  border-style: solid;
}
.bor--secondary--3 {
  border-color: #c62e08;
  border-width: 1px;
  border-style: solid;
}
.bor--secondary--4 {
  border-color: #dd370e;
  border-width: 1px;
  border-style: solid;
}
.bor--secondary--5 {
  border-color: #fa420f;
  border-width: 1px;
  border-style: solid;
}
.bor--secondary--6 {
  border-color: #ff6136;
  border-width: 1px;
  border-style: solid;
}
.bor--secondary--7 {
  border-color: #ff9271;
  border-width: 1px;
  border-style: solid;
}
.bor--secondary--8 {
  border-color: #ffbea8;
  border-width: 1px;
  border-style: solid;
}
.bor--secondary--9 {
  border-color: #ffdfd4;
  border-width: 1px;
  border-style: solid;
}
.bor--secondary--10 {
  border-color: #fff2ed;
  border-width: 1px;
  border-style: solid;
}
.bor--secondary--11 {
  border-color: #fffbfa;
  border-width: 1px;
  border-style: solid;
}
.bor--success--1 {
  border-color: #1b4644;
  border-width: 1px;
  border-style: solid;
}
.bor--success--2 {
  border-color: #1c5352;
  border-width: 1px;
  border-style: solid;
}
.bor--success--3 {
  border-color: #1d6864;
  border-width: 1px;
  border-style: solid;
}
.bor--success--4 {
  border-color: #20817c;
  border-width: 1px;
  border-style: solid;
}
.bor--success--5 {
  border-color: #2ba198;
  border-width: 1px;
  border-style: solid;
}
.bor--success--6 {
  border-color: #44bdb2;
  border-width: 1px;
  border-style: solid;
}
.bor--success--7 {
  border-color: #64d3c5;
  border-width: 1px;
  border-style: solid;
}
.bor--success--8 {
  border-color: #a6e9de;
  border-width: 1px;
  border-style: solid;
}
.bor--success--9 {
  border-color: #d2f5ee;
  border-width: 1px;
  border-style: solid;
}
.bor--success--10 {
  border-color: #f2fbf9;
  border-width: 1px;
  border-style: solid;
}
.bor--warning--1 {
  border-color: #7d3d11;
  border-width: 1px;
  border-style: solid;
}
.bor--warning--2 {
  border-color: #9b4911;
  border-width: 1px;
  border-style: solid;
}
.bor--warning--3 {
  border-color: #c45e0a;
  border-width: 1px;
  border-style: solid;
}
.bor--warning--4 {
  border-color: #f5810b;
  border-width: 1px;
  border-style: solid;
}
.bor--warning--5 {
  border-color: #fb9814;
  border-width: 1px;
  border-style: solid;
}
.bor--warning--6 {
  border-color: #fdb23a;
  border-width: 1px;
  border-style: solid;
}
.bor--warning--7 {
  border-color: #fed173;
  border-width: 1px;
  border-style: solid;
}
.bor--warning--8 {
  border-color: #ffe5a9;
  border-width: 1px;
  border-style: solid;
}
.bor--warning--9 {
  border-color: #fff4d5;
  border-width: 1px;
  border-style: solid;
}
.bor--warning--10 {
  border-color: #fffaed;
  border-width: 1px;
  border-style: solid;
}
.bor--danger--1 {
  border-color: #8f0c2d;
  border-width: 1px;
  border-style: solid;
}
.bor--danger--2 {
  border-color: #a8092d;
  border-width: 1px;
  border-style: solid;
}
.bor--danger--3 {
  border-color: #c8082d;
  border-width: 1px;
  border-style: solid;
}
.bor--danger--4 {
  border-color: #ed1136;
  border-width: 1px;
  border-style: solid;
}
.bor--danger--5 {
  border-color: #ff485e;
  border-width: 1px;
  border-style: solid;
}
.bor--danger--6 {
  border-color: #ff6977;
  border-width: 1px;
  border-style: solid;
}
.bor--danger--7 {
  border-color: #ff9fa6;
  border-width: 1px;
  border-style: solid;
}
.bor--danger--8 {
  border-color: #ffcace;
  border-width: 1px;
  border-style: solid;
}
.bor--danger--9 {
  border-color: #ffe2e4;
  border-width: 1px;
  border-style: solid;
}
.bor--danger--10 {
  border-color: #fff0f0;
  border-width: 1px;
  border-style: solid;
}
.bor--neutral--0 {
  border-color: #181a20;
  border-width: 1px;
  border-style: solid;
}
.bor--neutral--1 {
  border-color: #2b303b;
  border-width: 1px;
  border-style: solid;
}
.bor--neutral--2 {
  border-color: #3e4556;
  border-width: 1px;
  border-style: solid;
}
.bor--neutral--3 {
  border-color: #4e586f;
  border-width: 1px;
  border-style: solid;
}
.bor--neutral--4 {
  border-color: #596d97;
  border-width: 1px;
  border-style: solid;
}
.bor--neutral--5 {
  border-color: #8898b9;
  border-width: 1px;
  border-style: solid;
}
.bor--neutral--6 {
  border-color: #bcc5d7;
  border-width: 1px;
  border-style: solid;
}
.bor--neutral--7 {
  border-color: #dfe3ec;
  border-width: 1px;
  border-style: solid;
}
.bor--neutral--8 {
  border-color: #e9ecf2;
  border-width: 1px;
  border-style: solid;
}
.bor--neutral--9 {
  border-color: #f2f5f7;
  border-width: 1px;
  border-style: solid;
}
.bor--neutral--10 {
  border-color: #fcfcfd;
  border-width: 1px;
  border-style: solid;
}
.bor--social--facebook {
  border-color: #3b5998;
  border-width: 1px;
  border-style: solid;
}
.bor--social--twitter {
  border-color: #1da1f2;
  border-width: 1px;
  border-style: solid;
}
.bor--social--youtube {
  border-color: #cd201f;
  border-width: 1px;
  border-style: solid;
}
.bor--social--linkedin {
  border-color: #0077b5;
  border-width: 1px;
  border-style: solid;
}
.bor--social--frenchtex {
  border-color: #152f4a;
  border-width: 1px;
  border-style: solid;
}
.bor--mpleo--background {
  border-color: #f0f1f9;
  border-width: 1px;
  border-style: solid;
}
.bor-rad--0 {
  border-radius: 0;
}
.bor-rad--1 {
  border-radius: 0.4rem;
}
.bor-rad--2 {
  border-radius: 0.8rem;
}
.bor-rad--3 {
  border-radius: 1.2rem;
}
.bor-rad--4 {
  border-radius: 1.6rem;
}
.bor-rad--5 {
  border-radius: 2rem;
}
.bor-rad--6 {
  border-radius: 2.4rem;
}
.bor-rad--top-right--0 {
  border-top-right-radius: 0;
}
.bor-rad--top-right--1 {
  border-top-right-radius: 0.4rem;
}
.bor-rad--top-right--2 {
  border-top-right-radius: 0.8rem;
}
.bor-rad--top-right--3 {
  border-top-right-radius: 1.2rem;
}
.bor-rad--top-right--4 {
  border-top-right-radius: 1.6rem;
}
.bor-rad--top-right--5 {
  border-top-right-radius: 2rem;
}
.bor-rad--top-right--6 {
  border-top-right-radius: 2.4rem;
}
.bor-rad--top-left--0 {
  border-top-left-radius: 0;
}
.bor-rad--top-left--1 {
  border-top-left-radius: 0.4rem;
}
.bor-rad--top-left--2 {
  border-top-left-radius: 0.8rem;
}
.bor-rad--top-left--3 {
  border-top-left-radius: 1.2rem;
}
.bor-rad--top-left--4 {
  border-top-left-radius: 1.6rem;
}
.bor-rad--top-left--5 {
  border-top-left-radius: 2rem;
}
.bor-rad--top-left--6 {
  border-top-left-radius: 2.4rem;
}
.bor-rad--bottom-right--0 {
  border-bottom-right-radius: 0;
}
.bor-rad--bottom-right--1 {
  border-bottom-right-radius: 0.4rem;
}
.bor-rad--bottom-right--2 {
  border-bottom-right-radius: 0.8rem;
}
.bor-rad--bottom-right--3 {
  border-bottom-right-radius: 1.2rem;
}
.bor-rad--bottom-right--4 {
  border-bottom-right-radius: 1.6rem;
}
.bor-rad--bottom-right--5 {
  border-bottom-right-radius: 2rem;
}
.bor-rad--bottom-right--6 {
  border-bottom-right-radius: 2.4rem;
}
.bor-rad--bottom-left--0 {
  border-bottom-left-radius: 0;
}
.bor-rad--bottom-left--1 {
  border-bottom-left-radius: 0.4rem;
}
.bor-rad--bottom-left--2 {
  border-bottom-left-radius: 0.8rem;
}
.bor-rad--bottom-left--3 {
  border-bottom-left-radius: 1.2rem;
}
.bor-rad--bottom-left--4 {
  border-bottom-left-radius: 1.6rem;
}
.bor-rad--bottom-left--5 {
  border-bottom-left-radius: 2rem;
}
.bor-rad--bottom-left--6 {
  border-bottom-left-radius: 2.4rem;
}
.bor--default {
  border: 0.1rem solid #dfe3ec;
}
.bor--focus {
  border: 0.1rem solid #3d64ed;
  -webkit-box-shadow: 0 0 0 1px #3d64ed, 0 0 0 4px #b9c7fb;
  box-shadow: 0 0 0 1px #3d64ed, 0 0 0 4px #b9c7fb;
}
.bor--t--primary--1 {
  border-top-color: #2e3862;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--primary--2 {
  border-top-color: #303d74;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--primary--3 {
  border-top-color: #304287;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--primary--4 {
  border-top-color: #324cab;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--primary--5 {
  border-top-color: #3456d0;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--primary--6 {
  border-top-color: #3d64ed;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--primary--7 {
  border-top-color: #7491f7;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--primary--8 {
  border-top-color: #96acf9;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--primary--9 {
  border-top-color: #b9c7fb;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--primary--10 {
  border-top-color: #dce4fd;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--primary--11 {
  border-top-color: #eef4ff;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--primary--12 {
  border-top-color: #fafcff;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--secondary--1 {
  border-top-color: #7e2210;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--secondary--2 {
  border-top-color: #9d2b0f;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--secondary--3 {
  border-top-color: #c62e08;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--secondary--4 {
  border-top-color: #dd370e;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--secondary--5 {
  border-top-color: #fa420f;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--secondary--6 {
  border-top-color: #ff6136;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--secondary--7 {
  border-top-color: #ff9271;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--secondary--8 {
  border-top-color: #ffbea8;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--secondary--9 {
  border-top-color: #ffdfd4;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--secondary--10 {
  border-top-color: #fff2ed;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--secondary--11 {
  border-top-color: #fffbfa;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--success--1 {
  border-top-color: #1b4644;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--success--2 {
  border-top-color: #1c5352;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--success--3 {
  border-top-color: #1d6864;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--success--4 {
  border-top-color: #20817c;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--success--5 {
  border-top-color: #2ba198;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--success--6 {
  border-top-color: #44bdb2;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--success--7 {
  border-top-color: #64d3c5;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--success--8 {
  border-top-color: #a6e9de;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--success--9 {
  border-top-color: #d2f5ee;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--success--10 {
  border-top-color: #f2fbf9;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--warning--1 {
  border-top-color: #7d3d11;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--warning--2 {
  border-top-color: #9b4911;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--warning--3 {
  border-top-color: #c45e0a;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--warning--4 {
  border-top-color: #f5810b;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--warning--5 {
  border-top-color: #fb9814;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--warning--6 {
  border-top-color: #fdb23a;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--warning--7 {
  border-top-color: #fed173;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--warning--8 {
  border-top-color: #ffe5a9;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--warning--9 {
  border-top-color: #fff4d5;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--warning--10 {
  border-top-color: #fffaed;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--danger--1 {
  border-top-color: #8f0c2d;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--danger--2 {
  border-top-color: #a8092d;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--danger--3 {
  border-top-color: #c8082d;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--danger--4 {
  border-top-color: #ed1136;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--danger--5 {
  border-top-color: #ff485e;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--danger--6 {
  border-top-color: #ff6977;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--danger--7 {
  border-top-color: #ff9fa6;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--danger--8 {
  border-top-color: #ffcace;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--danger--9 {
  border-top-color: #ffe2e4;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--danger--10 {
  border-top-color: #fff0f0;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--neutral--0 {
  border-top-color: #181a20;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--neutral--1 {
  border-top-color: #2b303b;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--neutral--2 {
  border-top-color: #3e4556;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--neutral--3 {
  border-top-color: #4e586f;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--neutral--4 {
  border-top-color: #596d97;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--neutral--5 {
  border-top-color: #8898b9;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--neutral--6 {
  border-top-color: #bcc5d7;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--neutral--7 {
  border-top-color: #dfe3ec;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--neutral--8 {
  border-top-color: #e9ecf2;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--neutral--9 {
  border-top-color: #f2f5f7;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--neutral--10 {
  border-top-color: #fcfcfd;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--social--facebook {
  border-top-color: #3b5998;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--social--twitter {
  border-top-color: #1da1f2;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--social--youtube {
  border-top-color: #cd201f;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--social--linkedin {
  border-top-color: #0077b5;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--social--frenchtex {
  border-top-color: #152f4a;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--mpleo--background {
  border-top-color: #f0f1f9;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--t--default {
  border-top: 0.1rem solid #dfe3ec;
}
.bor--r--primary--1 {
  border-right-color: #2e3862;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--primary--2 {
  border-right-color: #303d74;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--primary--3 {
  border-right-color: #304287;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--primary--4 {
  border-right-color: #324cab;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--primary--5 {
  border-right-color: #3456d0;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--primary--6 {
  border-right-color: #3d64ed;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--primary--7 {
  border-right-color: #7491f7;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--primary--8 {
  border-right-color: #96acf9;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--primary--9 {
  border-right-color: #b9c7fb;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--primary--10 {
  border-right-color: #dce4fd;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--primary--11 {
  border-right-color: #eef4ff;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--primary--12 {
  border-right-color: #fafcff;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--secondary--1 {
  border-right-color: #7e2210;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--secondary--2 {
  border-right-color: #9d2b0f;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--secondary--3 {
  border-right-color: #c62e08;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--secondary--4 {
  border-right-color: #dd370e;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--secondary--5 {
  border-right-color: #fa420f;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--secondary--6 {
  border-right-color: #ff6136;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--secondary--7 {
  border-right-color: #ff9271;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--secondary--8 {
  border-right-color: #ffbea8;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--secondary--9 {
  border-right-color: #ffdfd4;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--secondary--10 {
  border-right-color: #fff2ed;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--secondary--11 {
  border-right-color: #fffbfa;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--success--1 {
  border-right-color: #1b4644;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--success--2 {
  border-right-color: #1c5352;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--success--3 {
  border-right-color: #1d6864;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--success--4 {
  border-right-color: #20817c;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--success--5 {
  border-right-color: #2ba198;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--success--6 {
  border-right-color: #44bdb2;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--success--7 {
  border-right-color: #64d3c5;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--success--8 {
  border-right-color: #a6e9de;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--success--9 {
  border-right-color: #d2f5ee;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--success--10 {
  border-right-color: #f2fbf9;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--warning--1 {
  border-right-color: #7d3d11;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--warning--2 {
  border-right-color: #9b4911;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--warning--3 {
  border-right-color: #c45e0a;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--warning--4 {
  border-right-color: #f5810b;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--warning--5 {
  border-right-color: #fb9814;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--warning--6 {
  border-right-color: #fdb23a;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--warning--7 {
  border-right-color: #fed173;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--warning--8 {
  border-right-color: #ffe5a9;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--warning--9 {
  border-right-color: #fff4d5;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--warning--10 {
  border-right-color: #fffaed;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--danger--1 {
  border-right-color: #8f0c2d;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--danger--2 {
  border-right-color: #a8092d;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--danger--3 {
  border-right-color: #c8082d;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--danger--4 {
  border-right-color: #ed1136;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--danger--5 {
  border-right-color: #ff485e;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--danger--6 {
  border-right-color: #ff6977;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--danger--7 {
  border-right-color: #ff9fa6;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--danger--8 {
  border-right-color: #ffcace;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--danger--9 {
  border-right-color: #ffe2e4;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--danger--10 {
  border-right-color: #fff0f0;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--neutral--0 {
  border-right-color: #181a20;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--neutral--1 {
  border-right-color: #2b303b;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--neutral--2 {
  border-right-color: #3e4556;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--neutral--3 {
  border-right-color: #4e586f;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--neutral--4 {
  border-right-color: #596d97;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--neutral--5 {
  border-right-color: #8898b9;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--neutral--6 {
  border-right-color: #bcc5d7;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--neutral--7 {
  border-right-color: #dfe3ec;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--neutral--8 {
  border-right-color: #e9ecf2;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--neutral--9 {
  border-right-color: #f2f5f7;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--neutral--10 {
  border-right-color: #fcfcfd;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--social--facebook {
  border-right-color: #3b5998;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--social--twitter {
  border-right-color: #1da1f2;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--social--youtube {
  border-right-color: #cd201f;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--social--linkedin {
  border-right-color: #0077b5;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--social--frenchtex {
  border-right-color: #152f4a;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--mpleo--background {
  border-right-color: #f0f1f9;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--r--default {
  border-right: 0.1rem solid #dfe3ec;
}
.bor--b--primary--1 {
  border-bottom-color: #2e3862;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--primary--2 {
  border-bottom-color: #303d74;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--primary--3 {
  border-bottom-color: #304287;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--primary--4 {
  border-bottom-color: #324cab;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--primary--5 {
  border-bottom-color: #3456d0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--primary--6 {
  border-bottom-color: #3d64ed;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--primary--7 {
  border-bottom-color: #7491f7;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--primary--8 {
  border-bottom-color: #96acf9;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--primary--9 {
  border-bottom-color: #b9c7fb;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--primary--10 {
  border-bottom-color: #dce4fd;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--primary--11 {
  border-bottom-color: #eef4ff;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--primary--12 {
  border-bottom-color: #fafcff;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--secondary--1 {
  border-bottom-color: #7e2210;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--secondary--2 {
  border-bottom-color: #9d2b0f;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--secondary--3 {
  border-bottom-color: #c62e08;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--secondary--4 {
  border-bottom-color: #dd370e;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--secondary--5 {
  border-bottom-color: #fa420f;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--secondary--6 {
  border-bottom-color: #ff6136;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--secondary--7 {
  border-bottom-color: #ff9271;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--secondary--8 {
  border-bottom-color: #ffbea8;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--secondary--9 {
  border-bottom-color: #ffdfd4;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--secondary--10 {
  border-bottom-color: #fff2ed;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--secondary--11 {
  border-bottom-color: #fffbfa;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--success--1 {
  border-bottom-color: #1b4644;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--success--2 {
  border-bottom-color: #1c5352;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--success--3 {
  border-bottom-color: #1d6864;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--success--4 {
  border-bottom-color: #20817c;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--success--5 {
  border-bottom-color: #2ba198;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--success--6 {
  border-bottom-color: #44bdb2;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--success--7 {
  border-bottom-color: #64d3c5;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--success--8 {
  border-bottom-color: #a6e9de;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--success--9 {
  border-bottom-color: #d2f5ee;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--success--10 {
  border-bottom-color: #f2fbf9;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--warning--1 {
  border-bottom-color: #7d3d11;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--warning--2 {
  border-bottom-color: #9b4911;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--warning--3 {
  border-bottom-color: #c45e0a;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--warning--4 {
  border-bottom-color: #f5810b;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--warning--5 {
  border-bottom-color: #fb9814;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--warning--6 {
  border-bottom-color: #fdb23a;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--warning--7 {
  border-bottom-color: #fed173;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--warning--8 {
  border-bottom-color: #ffe5a9;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--warning--9 {
  border-bottom-color: #fff4d5;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--warning--10 {
  border-bottom-color: #fffaed;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--danger--1 {
  border-bottom-color: #8f0c2d;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--danger--2 {
  border-bottom-color: #a8092d;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--danger--3 {
  border-bottom-color: #c8082d;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--danger--4 {
  border-bottom-color: #ed1136;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--danger--5 {
  border-bottom-color: #ff485e;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--danger--6 {
  border-bottom-color: #ff6977;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--danger--7 {
  border-bottom-color: #ff9fa6;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--danger--8 {
  border-bottom-color: #ffcace;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--danger--9 {
  border-bottom-color: #ffe2e4;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--danger--10 {
  border-bottom-color: #fff0f0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--neutral--0 {
  border-bottom-color: #181a20;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--neutral--1 {
  border-bottom-color: #2b303b;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--neutral--2 {
  border-bottom-color: #3e4556;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--neutral--3 {
  border-bottom-color: #4e586f;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--neutral--4 {
  border-bottom-color: #596d97;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--neutral--5 {
  border-bottom-color: #8898b9;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--neutral--6 {
  border-bottom-color: #bcc5d7;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--neutral--7 {
  border-bottom-color: #dfe3ec;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--neutral--8 {
  border-bottom-color: #e9ecf2;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--neutral--9 {
  border-bottom-color: #f2f5f7;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--neutral--10 {
  border-bottom-color: #fcfcfd;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--social--facebook {
  border-bottom-color: #3b5998;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--social--twitter {
  border-bottom-color: #1da1f2;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--social--youtube {
  border-bottom-color: #cd201f;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--social--linkedin {
  border-bottom-color: #0077b5;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--social--frenchtex {
  border-bottom-color: #152f4a;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--mpleo--background {
  border-bottom-color: #f0f1f9;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--b--default {
  border-bottom: 0.1rem solid #dfe3ec;
}
.bor--l--primary--1 {
  border-left-color: #2e3862;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--primary--2 {
  border-left-color: #303d74;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--primary--3 {
  border-left-color: #304287;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--primary--4 {
  border-left-color: #324cab;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--primary--5 {
  border-left-color: #3456d0;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--primary--6 {
  border-left-color: #3d64ed;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--primary--7 {
  border-left-color: #7491f7;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--primary--8 {
  border-left-color: #96acf9;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--primary--9 {
  border-left-color: #b9c7fb;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--primary--10 {
  border-left-color: #dce4fd;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--primary--11 {
  border-left-color: #eef4ff;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--primary--12 {
  border-left-color: #fafcff;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--secondary--1 {
  border-left-color: #7e2210;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--secondary--2 {
  border-left-color: #9d2b0f;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--secondary--3 {
  border-left-color: #c62e08;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--secondary--4 {
  border-left-color: #dd370e;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--secondary--5 {
  border-left-color: #fa420f;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--secondary--6 {
  border-left-color: #ff6136;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--secondary--7 {
  border-left-color: #ff9271;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--secondary--8 {
  border-left-color: #ffbea8;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--secondary--9 {
  border-left-color: #ffdfd4;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--secondary--10 {
  border-left-color: #fff2ed;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--secondary--11 {
  border-left-color: #fffbfa;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--success--1 {
  border-left-color: #1b4644;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--success--2 {
  border-left-color: #1c5352;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--success--3 {
  border-left-color: #1d6864;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--success--4 {
  border-left-color: #20817c;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--success--5 {
  border-left-color: #2ba198;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--success--6 {
  border-left-color: #44bdb2;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--success--7 {
  border-left-color: #64d3c5;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--success--8 {
  border-left-color: #a6e9de;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--success--9 {
  border-left-color: #d2f5ee;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--success--10 {
  border-left-color: #f2fbf9;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--warning--1 {
  border-left-color: #7d3d11;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--warning--2 {
  border-left-color: #9b4911;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--warning--3 {
  border-left-color: #c45e0a;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--warning--4 {
  border-left-color: #f5810b;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--warning--5 {
  border-left-color: #fb9814;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--warning--6 {
  border-left-color: #fdb23a;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--warning--7 {
  border-left-color: #fed173;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--warning--8 {
  border-left-color: #ffe5a9;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--warning--9 {
  border-left-color: #fff4d5;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--warning--10 {
  border-left-color: #fffaed;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--danger--1 {
  border-left-color: #8f0c2d;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--danger--2 {
  border-left-color: #a8092d;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--danger--3 {
  border-left-color: #c8082d;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--danger--4 {
  border-left-color: #ed1136;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--danger--5 {
  border-left-color: #ff485e;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--danger--6 {
  border-left-color: #ff6977;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--danger--7 {
  border-left-color: #ff9fa6;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--danger--8 {
  border-left-color: #ffcace;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--danger--9 {
  border-left-color: #ffe2e4;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--danger--10 {
  border-left-color: #fff0f0;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--neutral--0 {
  border-left-color: #181a20;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--neutral--1 {
  border-left-color: #2b303b;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--neutral--2 {
  border-left-color: #3e4556;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--neutral--3 {
  border-left-color: #4e586f;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--neutral--4 {
  border-left-color: #596d97;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--neutral--5 {
  border-left-color: #8898b9;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--neutral--6 {
  border-left-color: #bcc5d7;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--neutral--7 {
  border-left-color: #dfe3ec;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--neutral--8 {
  border-left-color: #e9ecf2;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--neutral--9 {
  border-left-color: #f2f5f7;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--neutral--10 {
  border-left-color: #fcfcfd;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--social--facebook {
  border-left-color: #3b5998;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--social--twitter {
  border-left-color: #1da1f2;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--social--youtube {
  border-left-color: #cd201f;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--social--linkedin {
  border-left-color: #0077b5;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--social--frenchtex {
  border-left-color: #152f4a;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--mpleo--background {
  border-left-color: #f0f1f9;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--l--default {
  border-left: 0.1rem solid #dfe3ec;
}
.bor--y--primary--1 {
  border-top-color: #2e3862;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--primary--2 {
  border-top-color: #303d74;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--primary--3 {
  border-top-color: #304287;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--primary--4 {
  border-top-color: #324cab;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--primary--5 {
  border-top-color: #3456d0;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--primary--6 {
  border-top-color: #3d64ed;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--primary--7 {
  border-top-color: #7491f7;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--primary--8 {
  border-top-color: #96acf9;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--primary--9 {
  border-top-color: #b9c7fb;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--primary--10 {
  border-top-color: #dce4fd;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--primary--11 {
  border-top-color: #eef4ff;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--primary--12 {
  border-top-color: #fafcff;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--secondary--1 {
  border-top-color: #7e2210;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--secondary--2 {
  border-top-color: #9d2b0f;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--secondary--3 {
  border-top-color: #c62e08;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--secondary--4 {
  border-top-color: #dd370e;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--secondary--5 {
  border-top-color: #fa420f;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--secondary--6 {
  border-top-color: #ff6136;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--secondary--7 {
  border-top-color: #ff9271;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--secondary--8 {
  border-top-color: #ffbea8;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--secondary--9 {
  border-top-color: #ffdfd4;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--secondary--10 {
  border-top-color: #fff2ed;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--secondary--11 {
  border-top-color: #fffbfa;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--success--1 {
  border-top-color: #1b4644;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--success--2 {
  border-top-color: #1c5352;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--success--3 {
  border-top-color: #1d6864;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--success--4 {
  border-top-color: #20817c;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--success--5 {
  border-top-color: #2ba198;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--success--6 {
  border-top-color: #44bdb2;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--success--7 {
  border-top-color: #64d3c5;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--success--8 {
  border-top-color: #a6e9de;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--success--9 {
  border-top-color: #d2f5ee;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--success--10 {
  border-top-color: #f2fbf9;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--warning--1 {
  border-top-color: #7d3d11;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--warning--2 {
  border-top-color: #9b4911;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--warning--3 {
  border-top-color: #c45e0a;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--warning--4 {
  border-top-color: #f5810b;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--warning--5 {
  border-top-color: #fb9814;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--warning--6 {
  border-top-color: #fdb23a;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--warning--7 {
  border-top-color: #fed173;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--warning--8 {
  border-top-color: #ffe5a9;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--warning--9 {
  border-top-color: #fff4d5;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--warning--10 {
  border-top-color: #fffaed;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--danger--1 {
  border-top-color: #8f0c2d;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--danger--2 {
  border-top-color: #a8092d;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--danger--3 {
  border-top-color: #c8082d;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--danger--4 {
  border-top-color: #ed1136;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--danger--5 {
  border-top-color: #ff485e;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--danger--6 {
  border-top-color: #ff6977;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--danger--7 {
  border-top-color: #ff9fa6;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--danger--8 {
  border-top-color: #ffcace;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--danger--9 {
  border-top-color: #ffe2e4;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--danger--10 {
  border-top-color: #fff0f0;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--neutral--0 {
  border-top-color: #181a20;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--neutral--1 {
  border-top-color: #2b303b;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--neutral--2 {
  border-top-color: #3e4556;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--neutral--3 {
  border-top-color: #4e586f;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--neutral--4 {
  border-top-color: #596d97;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--neutral--5 {
  border-top-color: #8898b9;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--neutral--6 {
  border-top-color: #bcc5d7;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--neutral--7 {
  border-top-color: #dfe3ec;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--neutral--8 {
  border-top-color: #e9ecf2;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--neutral--9 {
  border-top-color: #f2f5f7;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--neutral--10 {
  border-top-color: #fcfcfd;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--social--facebook {
  border-top-color: #3b5998;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--social--twitter {
  border-top-color: #1da1f2;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--social--youtube {
  border-top-color: #cd201f;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--social--linkedin {
  border-top-color: #0077b5;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--social--frenchtex {
  border-top-color: #152f4a;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--mpleo--background {
  border-top-color: #f0f1f9;
  border-top-width: 1px;
  border-top-style: solid;
}
.bor--y--primary--1 {
  border-bottom-color: #2e3862;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--primary--2 {
  border-bottom-color: #303d74;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--primary--3 {
  border-bottom-color: #304287;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--primary--4 {
  border-bottom-color: #324cab;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--primary--5 {
  border-bottom-color: #3456d0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--primary--6 {
  border-bottom-color: #3d64ed;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--primary--7 {
  border-bottom-color: #7491f7;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--primary--8 {
  border-bottom-color: #96acf9;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--primary--9 {
  border-bottom-color: #b9c7fb;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--primary--10 {
  border-bottom-color: #dce4fd;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--primary--11 {
  border-bottom-color: #eef4ff;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--primary--12 {
  border-bottom-color: #fafcff;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--secondary--1 {
  border-bottom-color: #7e2210;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--secondary--2 {
  border-bottom-color: #9d2b0f;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--secondary--3 {
  border-bottom-color: #c62e08;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--secondary--4 {
  border-bottom-color: #dd370e;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--secondary--5 {
  border-bottom-color: #fa420f;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--secondary--6 {
  border-bottom-color: #ff6136;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--secondary--7 {
  border-bottom-color: #ff9271;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--secondary--8 {
  border-bottom-color: #ffbea8;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--secondary--9 {
  border-bottom-color: #ffdfd4;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--secondary--10 {
  border-bottom-color: #fff2ed;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--secondary--11 {
  border-bottom-color: #fffbfa;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--success--1 {
  border-bottom-color: #1b4644;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--success--2 {
  border-bottom-color: #1c5352;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--success--3 {
  border-bottom-color: #1d6864;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--success--4 {
  border-bottom-color: #20817c;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--success--5 {
  border-bottom-color: #2ba198;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--success--6 {
  border-bottom-color: #44bdb2;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--success--7 {
  border-bottom-color: #64d3c5;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--success--8 {
  border-bottom-color: #a6e9de;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--success--9 {
  border-bottom-color: #d2f5ee;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--success--10 {
  border-bottom-color: #f2fbf9;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--warning--1 {
  border-bottom-color: #7d3d11;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--warning--2 {
  border-bottom-color: #9b4911;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--warning--3 {
  border-bottom-color: #c45e0a;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--warning--4 {
  border-bottom-color: #f5810b;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--warning--5 {
  border-bottom-color: #fb9814;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--warning--6 {
  border-bottom-color: #fdb23a;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--warning--7 {
  border-bottom-color: #fed173;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--warning--8 {
  border-bottom-color: #ffe5a9;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--warning--9 {
  border-bottom-color: #fff4d5;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--warning--10 {
  border-bottom-color: #fffaed;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--danger--1 {
  border-bottom-color: #8f0c2d;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--danger--2 {
  border-bottom-color: #a8092d;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--danger--3 {
  border-bottom-color: #c8082d;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--danger--4 {
  border-bottom-color: #ed1136;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--danger--5 {
  border-bottom-color: #ff485e;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--danger--6 {
  border-bottom-color: #ff6977;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--danger--7 {
  border-bottom-color: #ff9fa6;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--danger--8 {
  border-bottom-color: #ffcace;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--danger--9 {
  border-bottom-color: #ffe2e4;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--danger--10 {
  border-bottom-color: #fff0f0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--neutral--0 {
  border-bottom-color: #181a20;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--neutral--1 {
  border-bottom-color: #2b303b;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--neutral--2 {
  border-bottom-color: #3e4556;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--neutral--3 {
  border-bottom-color: #4e586f;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--neutral--4 {
  border-bottom-color: #596d97;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--neutral--5 {
  border-bottom-color: #8898b9;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--neutral--6 {
  border-bottom-color: #bcc5d7;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--neutral--7 {
  border-bottom-color: #dfe3ec;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--neutral--8 {
  border-bottom-color: #e9ecf2;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--neutral--9 {
  border-bottom-color: #f2f5f7;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--neutral--10 {
  border-bottom-color: #fcfcfd;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--social--facebook {
  border-bottom-color: #3b5998;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--social--twitter {
  border-bottom-color: #1da1f2;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--social--youtube {
  border-bottom-color: #cd201f;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--social--linkedin {
  border-bottom-color: #0077b5;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--social--frenchtex {
  border-bottom-color: #152f4a;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--mpleo--background {
  border-bottom-color: #f0f1f9;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bor--y--default {
  border-top: 0.1rem solid #dfe3ec;
  border-bottom: 0.1rem solid #dfe3ec;
}
.bor--x--primary--1 {
  border-right-color: #2e3862;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--primary--2 {
  border-right-color: #303d74;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--primary--3 {
  border-right-color: #304287;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--primary--4 {
  border-right-color: #324cab;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--primary--5 {
  border-right-color: #3456d0;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--primary--6 {
  border-right-color: #3d64ed;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--primary--7 {
  border-right-color: #7491f7;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--primary--8 {
  border-right-color: #96acf9;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--primary--9 {
  border-right-color: #b9c7fb;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--primary--10 {
  border-right-color: #dce4fd;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--primary--11 {
  border-right-color: #eef4ff;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--primary--12 {
  border-right-color: #fafcff;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--secondary--1 {
  border-right-color: #7e2210;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--secondary--2 {
  border-right-color: #9d2b0f;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--secondary--3 {
  border-right-color: #c62e08;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--secondary--4 {
  border-right-color: #dd370e;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--secondary--5 {
  border-right-color: #fa420f;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--secondary--6 {
  border-right-color: #ff6136;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--secondary--7 {
  border-right-color: #ff9271;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--secondary--8 {
  border-right-color: #ffbea8;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--secondary--9 {
  border-right-color: #ffdfd4;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--secondary--10 {
  border-right-color: #fff2ed;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--secondary--11 {
  border-right-color: #fffbfa;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--success--1 {
  border-right-color: #1b4644;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--success--2 {
  border-right-color: #1c5352;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--success--3 {
  border-right-color: #1d6864;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--success--4 {
  border-right-color: #20817c;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--success--5 {
  border-right-color: #2ba198;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--success--6 {
  border-right-color: #44bdb2;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--success--7 {
  border-right-color: #64d3c5;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--success--8 {
  border-right-color: #a6e9de;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--success--9 {
  border-right-color: #d2f5ee;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--success--10 {
  border-right-color: #f2fbf9;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--warning--1 {
  border-right-color: #7d3d11;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--warning--2 {
  border-right-color: #9b4911;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--warning--3 {
  border-right-color: #c45e0a;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--warning--4 {
  border-right-color: #f5810b;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--warning--5 {
  border-right-color: #fb9814;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--warning--6 {
  border-right-color: #fdb23a;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--warning--7 {
  border-right-color: #fed173;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--warning--8 {
  border-right-color: #ffe5a9;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--warning--9 {
  border-right-color: #fff4d5;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--warning--10 {
  border-right-color: #fffaed;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--danger--1 {
  border-right-color: #8f0c2d;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--danger--2 {
  border-right-color: #a8092d;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--danger--3 {
  border-right-color: #c8082d;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--danger--4 {
  border-right-color: #ed1136;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--danger--5 {
  border-right-color: #ff485e;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--danger--6 {
  border-right-color: #ff6977;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--danger--7 {
  border-right-color: #ff9fa6;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--danger--8 {
  border-right-color: #ffcace;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--danger--9 {
  border-right-color: #ffe2e4;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--danger--10 {
  border-right-color: #fff0f0;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--neutral--0 {
  border-right-color: #181a20;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--neutral--1 {
  border-right-color: #2b303b;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--neutral--2 {
  border-right-color: #3e4556;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--neutral--3 {
  border-right-color: #4e586f;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--neutral--4 {
  border-right-color: #596d97;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--neutral--5 {
  border-right-color: #8898b9;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--neutral--6 {
  border-right-color: #bcc5d7;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--neutral--7 {
  border-right-color: #dfe3ec;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--neutral--8 {
  border-right-color: #e9ecf2;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--neutral--9 {
  border-right-color: #f2f5f7;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--neutral--10 {
  border-right-color: #fcfcfd;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--social--facebook {
  border-right-color: #3b5998;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--social--twitter {
  border-right-color: #1da1f2;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--social--youtube {
  border-right-color: #cd201f;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--social--linkedin {
  border-right-color: #0077b5;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--social--frenchtex {
  border-right-color: #152f4a;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--mpleo--background {
  border-right-color: #f0f1f9;
  border-right-width: 1px;
  border-right-style: solid;
}
.bor--x--primary--1 {
  border-left-color: #2e3862;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--primary--2 {
  border-left-color: #303d74;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--primary--3 {
  border-left-color: #304287;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--primary--4 {
  border-left-color: #324cab;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--primary--5 {
  border-left-color: #3456d0;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--primary--6 {
  border-left-color: #3d64ed;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--primary--7 {
  border-left-color: #7491f7;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--primary--8 {
  border-left-color: #96acf9;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--primary--9 {
  border-left-color: #b9c7fb;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--primary--10 {
  border-left-color: #dce4fd;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--primary--11 {
  border-left-color: #eef4ff;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--primary--12 {
  border-left-color: #fafcff;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--secondary--1 {
  border-left-color: #7e2210;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--secondary--2 {
  border-left-color: #9d2b0f;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--secondary--3 {
  border-left-color: #c62e08;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--secondary--4 {
  border-left-color: #dd370e;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--secondary--5 {
  border-left-color: #fa420f;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--secondary--6 {
  border-left-color: #ff6136;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--secondary--7 {
  border-left-color: #ff9271;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--secondary--8 {
  border-left-color: #ffbea8;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--secondary--9 {
  border-left-color: #ffdfd4;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--secondary--10 {
  border-left-color: #fff2ed;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--secondary--11 {
  border-left-color: #fffbfa;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--success--1 {
  border-left-color: #1b4644;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--success--2 {
  border-left-color: #1c5352;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--success--3 {
  border-left-color: #1d6864;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--success--4 {
  border-left-color: #20817c;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--success--5 {
  border-left-color: #2ba198;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--success--6 {
  border-left-color: #44bdb2;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--success--7 {
  border-left-color: #64d3c5;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--success--8 {
  border-left-color: #a6e9de;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--success--9 {
  border-left-color: #d2f5ee;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--success--10 {
  border-left-color: #f2fbf9;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--warning--1 {
  border-left-color: #7d3d11;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--warning--2 {
  border-left-color: #9b4911;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--warning--3 {
  border-left-color: #c45e0a;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--warning--4 {
  border-left-color: #f5810b;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--warning--5 {
  border-left-color: #fb9814;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--warning--6 {
  border-left-color: #fdb23a;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--warning--7 {
  border-left-color: #fed173;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--warning--8 {
  border-left-color: #ffe5a9;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--warning--9 {
  border-left-color: #fff4d5;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--warning--10 {
  border-left-color: #fffaed;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--danger--1 {
  border-left-color: #8f0c2d;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--danger--2 {
  border-left-color: #a8092d;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--danger--3 {
  border-left-color: #c8082d;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--danger--4 {
  border-left-color: #ed1136;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--danger--5 {
  border-left-color: #ff485e;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--danger--6 {
  border-left-color: #ff6977;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--danger--7 {
  border-left-color: #ff9fa6;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--danger--8 {
  border-left-color: #ffcace;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--danger--9 {
  border-left-color: #ffe2e4;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--danger--10 {
  border-left-color: #fff0f0;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--neutral--0 {
  border-left-color: #181a20;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--neutral--1 {
  border-left-color: #2b303b;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--neutral--2 {
  border-left-color: #3e4556;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--neutral--3 {
  border-left-color: #4e586f;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--neutral--4 {
  border-left-color: #596d97;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--neutral--5 {
  border-left-color: #8898b9;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--neutral--6 {
  border-left-color: #bcc5d7;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--neutral--7 {
  border-left-color: #dfe3ec;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--neutral--8 {
  border-left-color: #e9ecf2;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--neutral--9 {
  border-left-color: #f2f5f7;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--neutral--10 {
  border-left-color: #fcfcfd;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--social--facebook {
  border-left-color: #3b5998;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--social--twitter {
  border-left-color: #1da1f2;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--social--youtube {
  border-left-color: #cd201f;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--social--linkedin {
  border-left-color: #0077b5;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--social--frenchtex {
  border-left-color: #152f4a;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--mpleo--background {
  border-left-color: #f0f1f9;
  border-left-width: 1px;
  border-left-style: solid;
}
.bor--x--default {
  border-right: 0.1rem solid #dfe3ec;
  border-left: 0.1rem solid #dfe3ec;
}

.m--0 {
  margin: 0;
}
.m--1 {
  margin: 0.2rem;
}
.m--2 {
  margin: 0.4rem;
}
.m--3 {
  margin: 0.8rem;
}
.m--4 {
  margin: 1.2rem;
}
.m--5 {
  margin: 1.6rem;
}
.m--6 {
  margin: 2.4rem;
}
.m--7 {
  margin: 3.2rem;
}
.m--8 {
  margin: 4rem;
}
.m--9 {
  margin: 5.6rem;
}
.m--10 {
  margin: 11.2rem;
}
.m--11 {
  margin: 22.4rem;
}
.m--t--0 {
  margin-top: 0;
}
.m--t--1 {
  margin-top: 0.2rem;
}
.m--t--2 {
  margin-top: 0.4rem;
}
.m--t--3 {
  margin-top: 0.8rem;
}
.m--t--4 {
  margin-top: 1.2rem;
}
.m--t--5 {
  margin-top: 1.6rem;
}
.m--t--6 {
  margin-top: 2.4rem;
}
.m--t--7 {
  margin-top: 3.2rem;
}
.m--t--8 {
  margin-top: 4rem;
}
.m--t--9 {
  margin-top: 5.6rem;
}
.m--t--10 {
  margin-top: 11.2rem;
}
.m--t--11 {
  margin-top: 22.4rem;
}
.m--r--0 {
  margin-right: 0;
}
.m--r--1 {
  margin-right: 0.2rem;
}
.m--r--2 {
  margin-right: 0.4rem;
}
.m--r--3 {
  margin-right: 0.8rem;
}
.m--r--4 {
  margin-right: 1.2rem;
}
.m--r--5 {
  margin-right: 1.6rem;
}
.m--r--6 {
  margin-right: 2.4rem;
}
.m--r--7 {
  margin-right: 3.2rem;
}
.m--r--8 {
  margin-right: 4rem;
}
.m--r--9 {
  margin-right: 5.6rem;
}
.m--r--10 {
  margin-right: 11.2rem;
}
.m--r--11 {
  margin-right: 22.4rem;
}
.m--b--0 {
  margin-bottom: 0;
}
.m--b--1 {
  margin-bottom: 0.2rem;
}
.m--b--2 {
  margin-bottom: 0.4rem;
}
.m--b--3 {
  margin-bottom: 0.8rem;
}
.m--b--4 {
  margin-bottom: 1.2rem;
}
.m--b--5 {
  margin-bottom: 1.6rem;
}
.m--b--6 {
  margin-bottom: 2.4rem;
}
.m--b--7 {
  margin-bottom: 3.2rem;
}
.m--b--8 {
  margin-bottom: 4rem;
}
.m--b--9 {
  margin-bottom: 5.6rem;
}
.m--b--10 {
  margin-bottom: 11.2rem;
}
.m--b--11 {
  margin-bottom: 22.4rem;
}
.m--l--0 {
  margin-left: 0;
}
.m--l--1 {
  margin-left: 0.2rem;
}
.m--l--2 {
  margin-left: 0.4rem;
}
.m--l--3 {
  margin-left: 0.8rem;
}
.m--l--4 {
  margin-left: 1.2rem;
}
.m--l--5 {
  margin-left: 1.6rem;
}
.m--l--6 {
  margin-left: 2.4rem;
}
.m--l--7 {
  margin-left: 3.2rem;
}
.m--l--8 {
  margin-left: 4rem;
}
.m--l--9 {
  margin-left: 5.6rem;
}
.m--l--10 {
  margin-left: 11.2rem;
}
.m--l--11 {
  margin-left: 22.4rem;
}
.m--y--0 {
  margin-top: 0;
}
.m--y--1 {
  margin-top: 0.2rem;
}
.m--y--2 {
  margin-top: 0.4rem;
}
.m--y--3 {
  margin-top: 0.8rem;
}
.m--y--4 {
  margin-top: 1.2rem;
}
.m--y--5 {
  margin-top: 1.6rem;
}
.m--y--6 {
  margin-top: 2.4rem;
}
.m--y--7 {
  margin-top: 3.2rem;
}
.m--y--8 {
  margin-top: 4rem;
}
.m--y--9 {
  margin-top: 5.6rem;
}
.m--y--10 {
  margin-top: 11.2rem;
}
.m--y--11 {
  margin-top: 22.4rem;
}
.m--y--0 {
  margin-bottom: 0;
}
.m--y--1 {
  margin-bottom: 0.2rem;
}
.m--y--2 {
  margin-bottom: 0.4rem;
}
.m--y--3 {
  margin-bottom: 0.8rem;
}
.m--y--4 {
  margin-bottom: 1.2rem;
}
.m--y--5 {
  margin-bottom: 1.6rem;
}
.m--y--6 {
  margin-bottom: 2.4rem;
}
.m--y--7 {
  margin-bottom: 3.2rem;
}
.m--y--8 {
  margin-bottom: 4rem;
}
.m--y--9 {
  margin-bottom: 5.6rem;
}
.m--y--10 {
  margin-bottom: 11.2rem;
}
.m--y--11 {
  margin-bottom: 22.4rem;
}
.m--x--0 {
  margin-right: 0;
}
.m--x--1 {
  margin-right: 0.2rem;
}
.m--x--2 {
  margin-right: 0.4rem;
}
.m--x--3 {
  margin-right: 0.8rem;
}
.m--x--4 {
  margin-right: 1.2rem;
}
.m--x--5 {
  margin-right: 1.6rem;
}
.m--x--6 {
  margin-right: 2.4rem;
}
.m--x--7 {
  margin-right: 3.2rem;
}
.m--x--8 {
  margin-right: 4rem;
}
.m--x--9 {
  margin-right: 5.6rem;
}
.m--x--10 {
  margin-right: 11.2rem;
}
.m--x--11 {
  margin-right: 22.4rem;
}
.m--x--0 {
  margin-left: 0;
}
.m--x--1 {
  margin-left: 0.2rem;
}
.m--x--2 {
  margin-left: 0.4rem;
}
.m--x--3 {
  margin-left: 0.8rem;
}
.m--x--4 {
  margin-left: 1.2rem;
}
.m--x--5 {
  margin-left: 1.6rem;
}
.m--x--6 {
  margin-left: 2.4rem;
}
.m--x--7 {
  margin-left: 3.2rem;
}
.m--x--8 {
  margin-left: 4rem;
}
.m--x--9 {
  margin-left: 5.6rem;
}
.m--x--10 {
  margin-left: 11.2rem;
}
.m--x--11 {
  margin-left: 22.4rem;
}
@media (min-width: 576px) {
  .m--sm--0 {
    margin: 0;
  }
  .m--sm--1 {
    margin: 0.2rem;
  }
  .m--sm--2 {
    margin: 0.4rem;
  }
  .m--sm--3 {
    margin: 0.8rem;
  }
  .m--sm--4 {
    margin: 1.2rem;
  }
  .m--sm--5 {
    margin: 1.6rem;
  }
  .m--sm--6 {
    margin: 2.4rem;
  }
  .m--sm--7 {
    margin: 3.2rem;
  }
  .m--sm--8 {
    margin: 4rem;
  }
  .m--sm--9 {
    margin: 5.6rem;
  }
  .m--sm--10 {
    margin: 11.2rem;
  }
  .m--sm--11 {
    margin: 22.4rem;
  }
  .m--t--sm--0 {
    margin-top: 0;
  }
  .m--t--sm--1 {
    margin-top: 0.2rem;
  }
  .m--t--sm--2 {
    margin-top: 0.4rem;
  }
  .m--t--sm--3 {
    margin-top: 0.8rem;
  }
  .m--t--sm--4 {
    margin-top: 1.2rem;
  }
  .m--t--sm--5 {
    margin-top: 1.6rem;
  }
  .m--t--sm--6 {
    margin-top: 2.4rem;
  }
  .m--t--sm--7 {
    margin-top: 3.2rem;
  }
  .m--t--sm--8 {
    margin-top: 4rem;
  }
  .m--t--sm--9 {
    margin-top: 5.6rem;
  }
  .m--t--sm--10 {
    margin-top: 11.2rem;
  }
  .m--t--sm--11 {
    margin-top: 22.4rem;
  }
  .m--r--sm--0 {
    margin-right: 0;
  }
  .m--r--sm--1 {
    margin-right: 0.2rem;
  }
  .m--r--sm--2 {
    margin-right: 0.4rem;
  }
  .m--r--sm--3 {
    margin-right: 0.8rem;
  }
  .m--r--sm--4 {
    margin-right: 1.2rem;
  }
  .m--r--sm--5 {
    margin-right: 1.6rem;
  }
  .m--r--sm--6 {
    margin-right: 2.4rem;
  }
  .m--r--sm--7 {
    margin-right: 3.2rem;
  }
  .m--r--sm--8 {
    margin-right: 4rem;
  }
  .m--r--sm--9 {
    margin-right: 5.6rem;
  }
  .m--r--sm--10 {
    margin-right: 11.2rem;
  }
  .m--r--sm--11 {
    margin-right: 22.4rem;
  }
  .m--b--sm--0 {
    margin-bottom: 0;
  }
  .m--b--sm--1 {
    margin-bottom: 0.2rem;
  }
  .m--b--sm--2 {
    margin-bottom: 0.4rem;
  }
  .m--b--sm--3 {
    margin-bottom: 0.8rem;
  }
  .m--b--sm--4 {
    margin-bottom: 1.2rem;
  }
  .m--b--sm--5 {
    margin-bottom: 1.6rem;
  }
  .m--b--sm--6 {
    margin-bottom: 2.4rem;
  }
  .m--b--sm--7 {
    margin-bottom: 3.2rem;
  }
  .m--b--sm--8 {
    margin-bottom: 4rem;
  }
  .m--b--sm--9 {
    margin-bottom: 5.6rem;
  }
  .m--b--sm--10 {
    margin-bottom: 11.2rem;
  }
  .m--b--sm--11 {
    margin-bottom: 22.4rem;
  }
  .m--l--sm--0 {
    margin-left: 0;
  }
  .m--l--sm--1 {
    margin-left: 0.2rem;
  }
  .m--l--sm--2 {
    margin-left: 0.4rem;
  }
  .m--l--sm--3 {
    margin-left: 0.8rem;
  }
  .m--l--sm--4 {
    margin-left: 1.2rem;
  }
  .m--l--sm--5 {
    margin-left: 1.6rem;
  }
  .m--l--sm--6 {
    margin-left: 2.4rem;
  }
  .m--l--sm--7 {
    margin-left: 3.2rem;
  }
  .m--l--sm--8 {
    margin-left: 4rem;
  }
  .m--l--sm--9 {
    margin-left: 5.6rem;
  }
  .m--l--sm--10 {
    margin-left: 11.2rem;
  }
  .m--l--sm--11 {
    margin-left: 22.4rem;
  }
  .m--y--sm--0 {
    margin-top: 0;
  }
  .m--y--sm--1 {
    margin-top: 0.2rem;
  }
  .m--y--sm--2 {
    margin-top: 0.4rem;
  }
  .m--y--sm--3 {
    margin-top: 0.8rem;
  }
  .m--y--sm--4 {
    margin-top: 1.2rem;
  }
  .m--y--sm--5 {
    margin-top: 1.6rem;
  }
  .m--y--sm--6 {
    margin-top: 2.4rem;
  }
  .m--y--sm--7 {
    margin-top: 3.2rem;
  }
  .m--y--sm--8 {
    margin-top: 4rem;
  }
  .m--y--sm--9 {
    margin-top: 5.6rem;
  }
  .m--y--sm--10 {
    margin-top: 11.2rem;
  }
  .m--y--sm--11 {
    margin-top: 22.4rem;
  }
  .m--y--sm--0 {
    margin-bottom: 0;
  }
  .m--y--sm--1 {
    margin-bottom: 0.2rem;
  }
  .m--y--sm--2 {
    margin-bottom: 0.4rem;
  }
  .m--y--sm--3 {
    margin-bottom: 0.8rem;
  }
  .m--y--sm--4 {
    margin-bottom: 1.2rem;
  }
  .m--y--sm--5 {
    margin-bottom: 1.6rem;
  }
  .m--y--sm--6 {
    margin-bottom: 2.4rem;
  }
  .m--y--sm--7 {
    margin-bottom: 3.2rem;
  }
  .m--y--sm--8 {
    margin-bottom: 4rem;
  }
  .m--y--sm--9 {
    margin-bottom: 5.6rem;
  }
  .m--y--sm--10 {
    margin-bottom: 11.2rem;
  }
  .m--y--sm--11 {
    margin-bottom: 22.4rem;
  }
  .m--x--sm--0 {
    margin-right: 0;
  }
  .m--x--sm--1 {
    margin-right: 0.2rem;
  }
  .m--x--sm--2 {
    margin-right: 0.4rem;
  }
  .m--x--sm--3 {
    margin-right: 0.8rem;
  }
  .m--x--sm--4 {
    margin-right: 1.2rem;
  }
  .m--x--sm--5 {
    margin-right: 1.6rem;
  }
  .m--x--sm--6 {
    margin-right: 2.4rem;
  }
  .m--x--sm--7 {
    margin-right: 3.2rem;
  }
  .m--x--sm--8 {
    margin-right: 4rem;
  }
  .m--x--sm--9 {
    margin-right: 5.6rem;
  }
  .m--x--sm--10 {
    margin-right: 11.2rem;
  }
  .m--x--sm--11 {
    margin-right: 22.4rem;
  }
  .m--x--sm--0 {
    margin-left: 0;
  }
  .m--x--sm--1 {
    margin-left: 0.2rem;
  }
  .m--x--sm--2 {
    margin-left: 0.4rem;
  }
  .m--x--sm--3 {
    margin-left: 0.8rem;
  }
  .m--x--sm--4 {
    margin-left: 1.2rem;
  }
  .m--x--sm--5 {
    margin-left: 1.6rem;
  }
  .m--x--sm--6 {
    margin-left: 2.4rem;
  }
  .m--x--sm--7 {
    margin-left: 3.2rem;
  }
  .m--x--sm--8 {
    margin-left: 4rem;
  }
  .m--x--sm--9 {
    margin-left: 5.6rem;
  }
  .m--x--sm--10 {
    margin-left: 11.2rem;
  }
  .m--x--sm--11 {
    margin-left: 22.4rem;
  }
}
@media (min-width: 768px) {
  .m--md--0 {
    margin: 0;
  }
  .m--md--1 {
    margin: 0.2rem;
  }
  .m--md--2 {
    margin: 0.4rem;
  }
  .m--md--3 {
    margin: 0.8rem;
  }
  .m--md--4 {
    margin: 1.2rem;
  }
  .m--md--5 {
    margin: 1.6rem;
  }
  .m--md--6 {
    margin: 2.4rem;
  }
  .m--md--7 {
    margin: 3.2rem;
  }
  .m--md--8 {
    margin: 4rem;
  }
  .m--md--9 {
    margin: 5.6rem;
  }
  .m--md--10 {
    margin: 11.2rem;
  }
  .m--md--11 {
    margin: 22.4rem;
  }
  .m--t--md--0 {
    margin-top: 0;
  }
  .m--t--md--1 {
    margin-top: 0.2rem;
  }
  .m--t--md--2 {
    margin-top: 0.4rem;
  }
  .m--t--md--3 {
    margin-top: 0.8rem;
  }
  .m--t--md--4 {
    margin-top: 1.2rem;
  }
  .m--t--md--5 {
    margin-top: 1.6rem;
  }
  .m--t--md--6 {
    margin-top: 2.4rem;
  }
  .m--t--md--7 {
    margin-top: 3.2rem;
  }
  .m--t--md--8 {
    margin-top: 4rem;
  }
  .m--t--md--9 {
    margin-top: 5.6rem;
  }
  .m--t--md--10 {
    margin-top: 11.2rem;
  }
  .m--t--md--11 {
    margin-top: 22.4rem;
  }
  .m--r--md--0 {
    margin-right: 0;
  }
  .m--r--md--1 {
    margin-right: 0.2rem;
  }
  .m--r--md--2 {
    margin-right: 0.4rem;
  }
  .m--r--md--3 {
    margin-right: 0.8rem;
  }
  .m--r--md--4 {
    margin-right: 1.2rem;
  }
  .m--r--md--5 {
    margin-right: 1.6rem;
  }
  .m--r--md--6 {
    margin-right: 2.4rem;
  }
  .m--r--md--7 {
    margin-right: 3.2rem;
  }
  .m--r--md--8 {
    margin-right: 4rem;
  }
  .m--r--md--9 {
    margin-right: 5.6rem;
  }
  .m--r--md--10 {
    margin-right: 11.2rem;
  }
  .m--r--md--11 {
    margin-right: 22.4rem;
  }
  .m--b--md--0 {
    margin-bottom: 0;
  }
  .m--b--md--1 {
    margin-bottom: 0.2rem;
  }
  .m--b--md--2 {
    margin-bottom: 0.4rem;
  }
  .m--b--md--3 {
    margin-bottom: 0.8rem;
  }
  .m--b--md--4 {
    margin-bottom: 1.2rem;
  }
  .m--b--md--5 {
    margin-bottom: 1.6rem;
  }
  .m--b--md--6 {
    margin-bottom: 2.4rem;
  }
  .m--b--md--7 {
    margin-bottom: 3.2rem;
  }
  .m--b--md--8 {
    margin-bottom: 4rem;
  }
  .m--b--md--9 {
    margin-bottom: 5.6rem;
  }
  .m--b--md--10 {
    margin-bottom: 11.2rem;
  }
  .m--b--md--11 {
    margin-bottom: 22.4rem;
  }
  .m--l--md--0 {
    margin-left: 0;
  }
  .m--l--md--1 {
    margin-left: 0.2rem;
  }
  .m--l--md--2 {
    margin-left: 0.4rem;
  }
  .m--l--md--3 {
    margin-left: 0.8rem;
  }
  .m--l--md--4 {
    margin-left: 1.2rem;
  }
  .m--l--md--5 {
    margin-left: 1.6rem;
  }
  .m--l--md--6 {
    margin-left: 2.4rem;
  }
  .m--l--md--7 {
    margin-left: 3.2rem;
  }
  .m--l--md--8 {
    margin-left: 4rem;
  }
  .m--l--md--9 {
    margin-left: 5.6rem;
  }
  .m--l--md--10 {
    margin-left: 11.2rem;
  }
  .m--l--md--11 {
    margin-left: 22.4rem;
  }
  .m--y--md--0 {
    margin-top: 0;
  }
  .m--y--md--1 {
    margin-top: 0.2rem;
  }
  .m--y--md--2 {
    margin-top: 0.4rem;
  }
  .m--y--md--3 {
    margin-top: 0.8rem;
  }
  .m--y--md--4 {
    margin-top: 1.2rem;
  }
  .m--y--md--5 {
    margin-top: 1.6rem;
  }
  .m--y--md--6 {
    margin-top: 2.4rem;
  }
  .m--y--md--7 {
    margin-top: 3.2rem;
  }
  .m--y--md--8 {
    margin-top: 4rem;
  }
  .m--y--md--9 {
    margin-top: 5.6rem;
  }
  .m--y--md--10 {
    margin-top: 11.2rem;
  }
  .m--y--md--11 {
    margin-top: 22.4rem;
  }
  .m--y--md--0 {
    margin-bottom: 0;
  }
  .m--y--md--1 {
    margin-bottom: 0.2rem;
  }
  .m--y--md--2 {
    margin-bottom: 0.4rem;
  }
  .m--y--md--3 {
    margin-bottom: 0.8rem;
  }
  .m--y--md--4 {
    margin-bottom: 1.2rem;
  }
  .m--y--md--5 {
    margin-bottom: 1.6rem;
  }
  .m--y--md--6 {
    margin-bottom: 2.4rem;
  }
  .m--y--md--7 {
    margin-bottom: 3.2rem;
  }
  .m--y--md--8 {
    margin-bottom: 4rem;
  }
  .m--y--md--9 {
    margin-bottom: 5.6rem;
  }
  .m--y--md--10 {
    margin-bottom: 11.2rem;
  }
  .m--y--md--11 {
    margin-bottom: 22.4rem;
  }
  .m--x--md--0 {
    margin-right: 0;
  }
  .m--x--md--1 {
    margin-right: 0.2rem;
  }
  .m--x--md--2 {
    margin-right: 0.4rem;
  }
  .m--x--md--3 {
    margin-right: 0.8rem;
  }
  .m--x--md--4 {
    margin-right: 1.2rem;
  }
  .m--x--md--5 {
    margin-right: 1.6rem;
  }
  .m--x--md--6 {
    margin-right: 2.4rem;
  }
  .m--x--md--7 {
    margin-right: 3.2rem;
  }
  .m--x--md--8 {
    margin-right: 4rem;
  }
  .m--x--md--9 {
    margin-right: 5.6rem;
  }
  .m--x--md--10 {
    margin-right: 11.2rem;
  }
  .m--x--md--11 {
    margin-right: 22.4rem;
  }
  .m--x--md--0 {
    margin-left: 0;
  }
  .m--x--md--1 {
    margin-left: 0.2rem;
  }
  .m--x--md--2 {
    margin-left: 0.4rem;
  }
  .m--x--md--3 {
    margin-left: 0.8rem;
  }
  .m--x--md--4 {
    margin-left: 1.2rem;
  }
  .m--x--md--5 {
    margin-left: 1.6rem;
  }
  .m--x--md--6 {
    margin-left: 2.4rem;
  }
  .m--x--md--7 {
    margin-left: 3.2rem;
  }
  .m--x--md--8 {
    margin-left: 4rem;
  }
  .m--x--md--9 {
    margin-left: 5.6rem;
  }
  .m--x--md--10 {
    margin-left: 11.2rem;
  }
  .m--x--md--11 {
    margin-left: 22.4rem;
  }
}
@media (min-width: 992px) {
  .m--lg--0 {
    margin: 0;
  }
  .m--lg--1 {
    margin: 0.2rem;
  }
  .m--lg--2 {
    margin: 0.4rem;
  }
  .m--lg--3 {
    margin: 0.8rem;
  }
  .m--lg--4 {
    margin: 1.2rem;
  }
  .m--lg--5 {
    margin: 1.6rem;
  }
  .m--lg--6 {
    margin: 2.4rem;
  }
  .m--lg--7 {
    margin: 3.2rem;
  }
  .m--lg--8 {
    margin: 4rem;
  }
  .m--lg--9 {
    margin: 5.6rem;
  }
  .m--lg--10 {
    margin: 11.2rem;
  }
  .m--lg--11 {
    margin: 22.4rem;
  }
  .m--t--lg--0 {
    margin-top: 0;
  }
  .m--t--lg--1 {
    margin-top: 0.2rem;
  }
  .m--t--lg--2 {
    margin-top: 0.4rem;
  }
  .m--t--lg--3 {
    margin-top: 0.8rem;
  }
  .m--t--lg--4 {
    margin-top: 1.2rem;
  }
  .m--t--lg--5 {
    margin-top: 1.6rem;
  }
  .m--t--lg--6 {
    margin-top: 2.4rem;
  }
  .m--t--lg--7 {
    margin-top: 3.2rem;
  }
  .m--t--lg--8 {
    margin-top: 4rem;
  }
  .m--t--lg--9 {
    margin-top: 5.6rem;
  }
  .m--t--lg--10 {
    margin-top: 11.2rem;
  }
  .m--t--lg--11 {
    margin-top: 22.4rem;
  }
  .m--r--lg--0 {
    margin-right: 0;
  }
  .m--r--lg--1 {
    margin-right: 0.2rem;
  }
  .m--r--lg--2 {
    margin-right: 0.4rem;
  }
  .m--r--lg--3 {
    margin-right: 0.8rem;
  }
  .m--r--lg--4 {
    margin-right: 1.2rem;
  }
  .m--r--lg--5 {
    margin-right: 1.6rem;
  }
  .m--r--lg--6 {
    margin-right: 2.4rem;
  }
  .m--r--lg--7 {
    margin-right: 3.2rem;
  }
  .m--r--lg--8 {
    margin-right: 4rem;
  }
  .m--r--lg--9 {
    margin-right: 5.6rem;
  }
  .m--r--lg--10 {
    margin-right: 11.2rem;
  }
  .m--r--lg--11 {
    margin-right: 22.4rem;
  }
  .m--b--lg--0 {
    margin-bottom: 0;
  }
  .m--b--lg--1 {
    margin-bottom: 0.2rem;
  }
  .m--b--lg--2 {
    margin-bottom: 0.4rem;
  }
  .m--b--lg--3 {
    margin-bottom: 0.8rem;
  }
  .m--b--lg--4 {
    margin-bottom: 1.2rem;
  }
  .m--b--lg--5 {
    margin-bottom: 1.6rem;
  }
  .m--b--lg--6 {
    margin-bottom: 2.4rem;
  }
  .m--b--lg--7 {
    margin-bottom: 3.2rem;
  }
  .m--b--lg--8 {
    margin-bottom: 4rem;
  }
  .m--b--lg--9 {
    margin-bottom: 5.6rem;
  }
  .m--b--lg--10 {
    margin-bottom: 11.2rem;
  }
  .m--b--lg--11 {
    margin-bottom: 22.4rem;
  }
  .m--l--lg--0 {
    margin-left: 0;
  }
  .m--l--lg--1 {
    margin-left: 0.2rem;
  }
  .m--l--lg--2 {
    margin-left: 0.4rem;
  }
  .m--l--lg--3 {
    margin-left: 0.8rem;
  }
  .m--l--lg--4 {
    margin-left: 1.2rem;
  }
  .m--l--lg--5 {
    margin-left: 1.6rem;
  }
  .m--l--lg--6 {
    margin-left: 2.4rem;
  }
  .m--l--lg--7 {
    margin-left: 3.2rem;
  }
  .m--l--lg--8 {
    margin-left: 4rem;
  }
  .m--l--lg--9 {
    margin-left: 5.6rem;
  }
  .m--l--lg--10 {
    margin-left: 11.2rem;
  }
  .m--l--lg--11 {
    margin-left: 22.4rem;
  }
  .m--y--lg--0 {
    margin-top: 0;
  }
  .m--y--lg--1 {
    margin-top: 0.2rem;
  }
  .m--y--lg--2 {
    margin-top: 0.4rem;
  }
  .m--y--lg--3 {
    margin-top: 0.8rem;
  }
  .m--y--lg--4 {
    margin-top: 1.2rem;
  }
  .m--y--lg--5 {
    margin-top: 1.6rem;
  }
  .m--y--lg--6 {
    margin-top: 2.4rem;
  }
  .m--y--lg--7 {
    margin-top: 3.2rem;
  }
  .m--y--lg--8 {
    margin-top: 4rem;
  }
  .m--y--lg--9 {
    margin-top: 5.6rem;
  }
  .m--y--lg--10 {
    margin-top: 11.2rem;
  }
  .m--y--lg--11 {
    margin-top: 22.4rem;
  }
  .m--y--lg--0 {
    margin-bottom: 0;
  }
  .m--y--lg--1 {
    margin-bottom: 0.2rem;
  }
  .m--y--lg--2 {
    margin-bottom: 0.4rem;
  }
  .m--y--lg--3 {
    margin-bottom: 0.8rem;
  }
  .m--y--lg--4 {
    margin-bottom: 1.2rem;
  }
  .m--y--lg--5 {
    margin-bottom: 1.6rem;
  }
  .m--y--lg--6 {
    margin-bottom: 2.4rem;
  }
  .m--y--lg--7 {
    margin-bottom: 3.2rem;
  }
  .m--y--lg--8 {
    margin-bottom: 4rem;
  }
  .m--y--lg--9 {
    margin-bottom: 5.6rem;
  }
  .m--y--lg--10 {
    margin-bottom: 11.2rem;
  }
  .m--y--lg--11 {
    margin-bottom: 22.4rem;
  }
  .m--x--lg--0 {
    margin-right: 0;
  }
  .m--x--lg--1 {
    margin-right: 0.2rem;
  }
  .m--x--lg--2 {
    margin-right: 0.4rem;
  }
  .m--x--lg--3 {
    margin-right: 0.8rem;
  }
  .m--x--lg--4 {
    margin-right: 1.2rem;
  }
  .m--x--lg--5 {
    margin-right: 1.6rem;
  }
  .m--x--lg--6 {
    margin-right: 2.4rem;
  }
  .m--x--lg--7 {
    margin-right: 3.2rem;
  }
  .m--x--lg--8 {
    margin-right: 4rem;
  }
  .m--x--lg--9 {
    margin-right: 5.6rem;
  }
  .m--x--lg--10 {
    margin-right: 11.2rem;
  }
  .m--x--lg--11 {
    margin-right: 22.4rem;
  }
  .m--x--lg--0 {
    margin-left: 0;
  }
  .m--x--lg--1 {
    margin-left: 0.2rem;
  }
  .m--x--lg--2 {
    margin-left: 0.4rem;
  }
  .m--x--lg--3 {
    margin-left: 0.8rem;
  }
  .m--x--lg--4 {
    margin-left: 1.2rem;
  }
  .m--x--lg--5 {
    margin-left: 1.6rem;
  }
  .m--x--lg--6 {
    margin-left: 2.4rem;
  }
  .m--x--lg--7 {
    margin-left: 3.2rem;
  }
  .m--x--lg--8 {
    margin-left: 4rem;
  }
  .m--x--lg--9 {
    margin-left: 5.6rem;
  }
  .m--x--lg--10 {
    margin-left: 11.2rem;
  }
  .m--x--lg--11 {
    margin-left: 22.4rem;
  }
}
@media (min-width: 1200px) {
  .m--xl--0 {
    margin: 0;
  }
  .m--xl--1 {
    margin: 0.2rem;
  }
  .m--xl--2 {
    margin: 0.4rem;
  }
  .m--xl--3 {
    margin: 0.8rem;
  }
  .m--xl--4 {
    margin: 1.2rem;
  }
  .m--xl--5 {
    margin: 1.6rem;
  }
  .m--xl--6 {
    margin: 2.4rem;
  }
  .m--xl--7 {
    margin: 3.2rem;
  }
  .m--xl--8 {
    margin: 4rem;
  }
  .m--xl--9 {
    margin: 5.6rem;
  }
  .m--xl--10 {
    margin: 11.2rem;
  }
  .m--xl--11 {
    margin: 22.4rem;
  }
  .m--t--xl--0 {
    margin-top: 0;
  }
  .m--t--xl--1 {
    margin-top: 0.2rem;
  }
  .m--t--xl--2 {
    margin-top: 0.4rem;
  }
  .m--t--xl--3 {
    margin-top: 0.8rem;
  }
  .m--t--xl--4 {
    margin-top: 1.2rem;
  }
  .m--t--xl--5 {
    margin-top: 1.6rem;
  }
  .m--t--xl--6 {
    margin-top: 2.4rem;
  }
  .m--t--xl--7 {
    margin-top: 3.2rem;
  }
  .m--t--xl--8 {
    margin-top: 4rem;
  }
  .m--t--xl--9 {
    margin-top: 5.6rem;
  }
  .m--t--xl--10 {
    margin-top: 11.2rem;
  }
  .m--t--xl--11 {
    margin-top: 22.4rem;
  }
  .m--r--xl--0 {
    margin-right: 0;
  }
  .m--r--xl--1 {
    margin-right: 0.2rem;
  }
  .m--r--xl--2 {
    margin-right: 0.4rem;
  }
  .m--r--xl--3 {
    margin-right: 0.8rem;
  }
  .m--r--xl--4 {
    margin-right: 1.2rem;
  }
  .m--r--xl--5 {
    margin-right: 1.6rem;
  }
  .m--r--xl--6 {
    margin-right: 2.4rem;
  }
  .m--r--xl--7 {
    margin-right: 3.2rem;
  }
  .m--r--xl--8 {
    margin-right: 4rem;
  }
  .m--r--xl--9 {
    margin-right: 5.6rem;
  }
  .m--r--xl--10 {
    margin-right: 11.2rem;
  }
  .m--r--xl--11 {
    margin-right: 22.4rem;
  }
  .m--b--xl--0 {
    margin-bottom: 0;
  }
  .m--b--xl--1 {
    margin-bottom: 0.2rem;
  }
  .m--b--xl--2 {
    margin-bottom: 0.4rem;
  }
  .m--b--xl--3 {
    margin-bottom: 0.8rem;
  }
  .m--b--xl--4 {
    margin-bottom: 1.2rem;
  }
  .m--b--xl--5 {
    margin-bottom: 1.6rem;
  }
  .m--b--xl--6 {
    margin-bottom: 2.4rem;
  }
  .m--b--xl--7 {
    margin-bottom: 3.2rem;
  }
  .m--b--xl--8 {
    margin-bottom: 4rem;
  }
  .m--b--xl--9 {
    margin-bottom: 5.6rem;
  }
  .m--b--xl--10 {
    margin-bottom: 11.2rem;
  }
  .m--b--xl--11 {
    margin-bottom: 22.4rem;
  }
  .m--l--xl--0 {
    margin-left: 0;
  }
  .m--l--xl--1 {
    margin-left: 0.2rem;
  }
  .m--l--xl--2 {
    margin-left: 0.4rem;
  }
  .m--l--xl--3 {
    margin-left: 0.8rem;
  }
  .m--l--xl--4 {
    margin-left: 1.2rem;
  }
  .m--l--xl--5 {
    margin-left: 1.6rem;
  }
  .m--l--xl--6 {
    margin-left: 2.4rem;
  }
  .m--l--xl--7 {
    margin-left: 3.2rem;
  }
  .m--l--xl--8 {
    margin-left: 4rem;
  }
  .m--l--xl--9 {
    margin-left: 5.6rem;
  }
  .m--l--xl--10 {
    margin-left: 11.2rem;
  }
  .m--l--xl--11 {
    margin-left: 22.4rem;
  }
  .m--y--xl--0 {
    margin-top: 0;
  }
  .m--y--xl--1 {
    margin-top: 0.2rem;
  }
  .m--y--xl--2 {
    margin-top: 0.4rem;
  }
  .m--y--xl--3 {
    margin-top: 0.8rem;
  }
  .m--y--xl--4 {
    margin-top: 1.2rem;
  }
  .m--y--xl--5 {
    margin-top: 1.6rem;
  }
  .m--y--xl--6 {
    margin-top: 2.4rem;
  }
  .m--y--xl--7 {
    margin-top: 3.2rem;
  }
  .m--y--xl--8 {
    margin-top: 4rem;
  }
  .m--y--xl--9 {
    margin-top: 5.6rem;
  }
  .m--y--xl--10 {
    margin-top: 11.2rem;
  }
  .m--y--xl--11 {
    margin-top: 22.4rem;
  }
  .m--y--xl--0 {
    margin-bottom: 0;
  }
  .m--y--xl--1 {
    margin-bottom: 0.2rem;
  }
  .m--y--xl--2 {
    margin-bottom: 0.4rem;
  }
  .m--y--xl--3 {
    margin-bottom: 0.8rem;
  }
  .m--y--xl--4 {
    margin-bottom: 1.2rem;
  }
  .m--y--xl--5 {
    margin-bottom: 1.6rem;
  }
  .m--y--xl--6 {
    margin-bottom: 2.4rem;
  }
  .m--y--xl--7 {
    margin-bottom: 3.2rem;
  }
  .m--y--xl--8 {
    margin-bottom: 4rem;
  }
  .m--y--xl--9 {
    margin-bottom: 5.6rem;
  }
  .m--y--xl--10 {
    margin-bottom: 11.2rem;
  }
  .m--y--xl--11 {
    margin-bottom: 22.4rem;
  }
  .m--x--xl--0 {
    margin-right: 0;
  }
  .m--x--xl--1 {
    margin-right: 0.2rem;
  }
  .m--x--xl--2 {
    margin-right: 0.4rem;
  }
  .m--x--xl--3 {
    margin-right: 0.8rem;
  }
  .m--x--xl--4 {
    margin-right: 1.2rem;
  }
  .m--x--xl--5 {
    margin-right: 1.6rem;
  }
  .m--x--xl--6 {
    margin-right: 2.4rem;
  }
  .m--x--xl--7 {
    margin-right: 3.2rem;
  }
  .m--x--xl--8 {
    margin-right: 4rem;
  }
  .m--x--xl--9 {
    margin-right: 5.6rem;
  }
  .m--x--xl--10 {
    margin-right: 11.2rem;
  }
  .m--x--xl--11 {
    margin-right: 22.4rem;
  }
  .m--x--xl--0 {
    margin-left: 0;
  }
  .m--x--xl--1 {
    margin-left: 0.2rem;
  }
  .m--x--xl--2 {
    margin-left: 0.4rem;
  }
  .m--x--xl--3 {
    margin-left: 0.8rem;
  }
  .m--x--xl--4 {
    margin-left: 1.2rem;
  }
  .m--x--xl--5 {
    margin-left: 1.6rem;
  }
  .m--x--xl--6 {
    margin-left: 2.4rem;
  }
  .m--x--xl--7 {
    margin-left: 3.2rem;
  }
  .m--x--xl--8 {
    margin-left: 4rem;
  }
  .m--x--xl--9 {
    margin-left: 5.6rem;
  }
  .m--x--xl--10 {
    margin-left: 11.2rem;
  }
  .m--x--xl--11 {
    margin-left: 22.4rem;
  }
}
@media (min-width: 1400px) {
  .m--xxl--0 {
    margin: 0;
  }
  .m--xxl--1 {
    margin: 0.2rem;
  }
  .m--xxl--2 {
    margin: 0.4rem;
  }
  .m--xxl--3 {
    margin: 0.8rem;
  }
  .m--xxl--4 {
    margin: 1.2rem;
  }
  .m--xxl--5 {
    margin: 1.6rem;
  }
  .m--xxl--6 {
    margin: 2.4rem;
  }
  .m--xxl--7 {
    margin: 3.2rem;
  }
  .m--xxl--8 {
    margin: 4rem;
  }
  .m--xxl--9 {
    margin: 5.6rem;
  }
  .m--xxl--10 {
    margin: 11.2rem;
  }
  .m--xxl--11 {
    margin: 22.4rem;
  }
  .m--t--xxl--0 {
    margin-top: 0;
  }
  .m--t--xxl--1 {
    margin-top: 0.2rem;
  }
  .m--t--xxl--2 {
    margin-top: 0.4rem;
  }
  .m--t--xxl--3 {
    margin-top: 0.8rem;
  }
  .m--t--xxl--4 {
    margin-top: 1.2rem;
  }
  .m--t--xxl--5 {
    margin-top: 1.6rem;
  }
  .m--t--xxl--6 {
    margin-top: 2.4rem;
  }
  .m--t--xxl--7 {
    margin-top: 3.2rem;
  }
  .m--t--xxl--8 {
    margin-top: 4rem;
  }
  .m--t--xxl--9 {
    margin-top: 5.6rem;
  }
  .m--t--xxl--10 {
    margin-top: 11.2rem;
  }
  .m--t--xxl--11 {
    margin-top: 22.4rem;
  }
  .m--r--xxl--0 {
    margin-right: 0;
  }
  .m--r--xxl--1 {
    margin-right: 0.2rem;
  }
  .m--r--xxl--2 {
    margin-right: 0.4rem;
  }
  .m--r--xxl--3 {
    margin-right: 0.8rem;
  }
  .m--r--xxl--4 {
    margin-right: 1.2rem;
  }
  .m--r--xxl--5 {
    margin-right: 1.6rem;
  }
  .m--r--xxl--6 {
    margin-right: 2.4rem;
  }
  .m--r--xxl--7 {
    margin-right: 3.2rem;
  }
  .m--r--xxl--8 {
    margin-right: 4rem;
  }
  .m--r--xxl--9 {
    margin-right: 5.6rem;
  }
  .m--r--xxl--10 {
    margin-right: 11.2rem;
  }
  .m--r--xxl--11 {
    margin-right: 22.4rem;
  }
  .m--b--xxl--0 {
    margin-bottom: 0;
  }
  .m--b--xxl--1 {
    margin-bottom: 0.2rem;
  }
  .m--b--xxl--2 {
    margin-bottom: 0.4rem;
  }
  .m--b--xxl--3 {
    margin-bottom: 0.8rem;
  }
  .m--b--xxl--4 {
    margin-bottom: 1.2rem;
  }
  .m--b--xxl--5 {
    margin-bottom: 1.6rem;
  }
  .m--b--xxl--6 {
    margin-bottom: 2.4rem;
  }
  .m--b--xxl--7 {
    margin-bottom: 3.2rem;
  }
  .m--b--xxl--8 {
    margin-bottom: 4rem;
  }
  .m--b--xxl--9 {
    margin-bottom: 5.6rem;
  }
  .m--b--xxl--10 {
    margin-bottom: 11.2rem;
  }
  .m--b--xxl--11 {
    margin-bottom: 22.4rem;
  }
  .m--l--xxl--0 {
    margin-left: 0;
  }
  .m--l--xxl--1 {
    margin-left: 0.2rem;
  }
  .m--l--xxl--2 {
    margin-left: 0.4rem;
  }
  .m--l--xxl--3 {
    margin-left: 0.8rem;
  }
  .m--l--xxl--4 {
    margin-left: 1.2rem;
  }
  .m--l--xxl--5 {
    margin-left: 1.6rem;
  }
  .m--l--xxl--6 {
    margin-left: 2.4rem;
  }
  .m--l--xxl--7 {
    margin-left: 3.2rem;
  }
  .m--l--xxl--8 {
    margin-left: 4rem;
  }
  .m--l--xxl--9 {
    margin-left: 5.6rem;
  }
  .m--l--xxl--10 {
    margin-left: 11.2rem;
  }
  .m--l--xxl--11 {
    margin-left: 22.4rem;
  }
  .m--y--xxl--0 {
    margin-top: 0;
  }
  .m--y--xxl--1 {
    margin-top: 0.2rem;
  }
  .m--y--xxl--2 {
    margin-top: 0.4rem;
  }
  .m--y--xxl--3 {
    margin-top: 0.8rem;
  }
  .m--y--xxl--4 {
    margin-top: 1.2rem;
  }
  .m--y--xxl--5 {
    margin-top: 1.6rem;
  }
  .m--y--xxl--6 {
    margin-top: 2.4rem;
  }
  .m--y--xxl--7 {
    margin-top: 3.2rem;
  }
  .m--y--xxl--8 {
    margin-top: 4rem;
  }
  .m--y--xxl--9 {
    margin-top: 5.6rem;
  }
  .m--y--xxl--10 {
    margin-top: 11.2rem;
  }
  .m--y--xxl--11 {
    margin-top: 22.4rem;
  }
  .m--y--xxl--0 {
    margin-bottom: 0;
  }
  .m--y--xxl--1 {
    margin-bottom: 0.2rem;
  }
  .m--y--xxl--2 {
    margin-bottom: 0.4rem;
  }
  .m--y--xxl--3 {
    margin-bottom: 0.8rem;
  }
  .m--y--xxl--4 {
    margin-bottom: 1.2rem;
  }
  .m--y--xxl--5 {
    margin-bottom: 1.6rem;
  }
  .m--y--xxl--6 {
    margin-bottom: 2.4rem;
  }
  .m--y--xxl--7 {
    margin-bottom: 3.2rem;
  }
  .m--y--xxl--8 {
    margin-bottom: 4rem;
  }
  .m--y--xxl--9 {
    margin-bottom: 5.6rem;
  }
  .m--y--xxl--10 {
    margin-bottom: 11.2rem;
  }
  .m--y--xxl--11 {
    margin-bottom: 22.4rem;
  }
  .m--x--xxl--0 {
    margin-right: 0;
  }
  .m--x--xxl--1 {
    margin-right: 0.2rem;
  }
  .m--x--xxl--2 {
    margin-right: 0.4rem;
  }
  .m--x--xxl--3 {
    margin-right: 0.8rem;
  }
  .m--x--xxl--4 {
    margin-right: 1.2rem;
  }
  .m--x--xxl--5 {
    margin-right: 1.6rem;
  }
  .m--x--xxl--6 {
    margin-right: 2.4rem;
  }
  .m--x--xxl--7 {
    margin-right: 3.2rem;
  }
  .m--x--xxl--8 {
    margin-right: 4rem;
  }
  .m--x--xxl--9 {
    margin-right: 5.6rem;
  }
  .m--x--xxl--10 {
    margin-right: 11.2rem;
  }
  .m--x--xxl--11 {
    margin-right: 22.4rem;
  }
  .m--x--xxl--0 {
    margin-left: 0;
  }
  .m--x--xxl--1 {
    margin-left: 0.2rem;
  }
  .m--x--xxl--2 {
    margin-left: 0.4rem;
  }
  .m--x--xxl--3 {
    margin-left: 0.8rem;
  }
  .m--x--xxl--4 {
    margin-left: 1.2rem;
  }
  .m--x--xxl--5 {
    margin-left: 1.6rem;
  }
  .m--x--xxl--6 {
    margin-left: 2.4rem;
  }
  .m--x--xxl--7 {
    margin-left: 3.2rem;
  }
  .m--x--xxl--8 {
    margin-left: 4rem;
  }
  .m--x--xxl--9 {
    margin-left: 5.6rem;
  }
  .m--x--xxl--10 {
    margin-left: 11.2rem;
  }
  .m--x--xxl--11 {
    margin-left: 22.4rem;
  }
}

.p--0 {
  padding: 0;
}
.p--1 {
  padding: 0.2rem;
}
.p--2 {
  padding: 0.4rem;
}
.p--3 {
  padding: 0.8rem;
}
.p--4 {
  padding: 1.2rem;
}
.p--5 {
  padding: 1.6rem;
}
.p--6 {
  padding: 2.4rem;
}
.p--7 {
  padding: 3.2rem;
}
.p--8 {
  padding: 4rem;
}
.p--9 {
  padding: 5.6rem;
}
.p--10 {
  padding: 11.2rem;
}
.p--11 {
  padding: 22.4rem;
}
.p--t--0 {
  padding-top: 0;
}
.p--t--1 {
  padding-top: 0.2rem;
}
.p--t--2 {
  padding-top: 0.4rem;
}
.p--t--3 {
  padding-top: 0.8rem;
}
.p--t--4 {
  padding-top: 1.2rem;
}
.p--t--5 {
  padding-top: 1.6rem;
}
.p--t--6 {
  padding-top: 2.4rem;
}
.p--t--7 {
  padding-top: 3.2rem;
}
.p--t--8 {
  padding-top: 4rem;
}
.p--t--9 {
  padding-top: 5.6rem;
}
.p--t--10 {
  padding-top: 11.2rem;
}
.p--t--11 {
  padding-top: 22.4rem;
}
.p--t--0--reverse {
  margin-top: -0;
}
.p--t--1--reverse {
  margin-top: -0.2rem;
}
.p--t--2--reverse {
  margin-top: -0.4rem;
}
.p--t--3--reverse {
  margin-top: -0.8rem;
}
.p--t--4--reverse {
  margin-top: -1.2rem;
}
.p--t--5--reverse {
  margin-top: -1.6rem;
}
.p--t--6--reverse {
  margin-top: -2.4rem;
}
.p--t--7--reverse {
  margin-top: -3.2rem;
}
.p--t--8--reverse {
  margin-top: -4rem;
}
.p--t--9--reverse {
  margin-top: -5.6rem;
}
.p--t--10--reverse {
  margin-top: -11.2rem;
}
.p--t--11--reverse {
  margin-top: -22.4rem;
}
.p--r--0 {
  padding-right: 0;
}
.p--r--1 {
  padding-right: 0.2rem;
}
.p--r--2 {
  padding-right: 0.4rem;
}
.p--r--3 {
  padding-right: 0.8rem;
}
.p--r--4 {
  padding-right: 1.2rem;
}
.p--r--5 {
  padding-right: 1.6rem;
}
.p--r--6 {
  padding-right: 2.4rem;
}
.p--r--7 {
  padding-right: 3.2rem;
}
.p--r--8 {
  padding-right: 4rem;
}
.p--r--9 {
  padding-right: 5.6rem;
}
.p--r--10 {
  padding-right: 11.2rem;
}
.p--r--11 {
  padding-right: 22.4rem;
}
.p--r--0--reverse {
  margin-right: -0;
}
.p--r--1--reverse {
  margin-right: -0.2rem;
}
.p--r--2--reverse {
  margin-right: -0.4rem;
}
.p--r--3--reverse {
  margin-right: -0.8rem;
}
.p--r--4--reverse {
  margin-right: -1.2rem;
}
.p--r--5--reverse {
  margin-right: -1.6rem;
}
.p--r--6--reverse {
  margin-right: -2.4rem;
}
.p--r--7--reverse {
  margin-right: -3.2rem;
}
.p--r--8--reverse {
  margin-right: -4rem;
}
.p--r--9--reverse {
  margin-right: -5.6rem;
}
.p--r--10--reverse {
  margin-right: -11.2rem;
}
.p--r--11--reverse {
  margin-right: -22.4rem;
}
.p--b--0 {
  padding-bottom: 0;
}
.p--b--1 {
  padding-bottom: 0.2rem;
}
.p--b--2 {
  padding-bottom: 0.4rem;
}
.p--b--3 {
  padding-bottom: 0.8rem;
}
.p--b--4 {
  padding-bottom: 1.2rem;
}
.p--b--5 {
  padding-bottom: 1.6rem;
}
.p--b--6 {
  padding-bottom: 2.4rem;
}
.p--b--7 {
  padding-bottom: 3.2rem;
}
.p--b--8 {
  padding-bottom: 4rem;
}
.p--b--9 {
  padding-bottom: 5.6rem;
}
.p--b--10 {
  padding-bottom: 11.2rem;
}
.p--b--11 {
  padding-bottom: 22.4rem;
}
.p--b--0--reverse {
  margin-bottom: -0;
}
.p--b--1--reverse {
  margin-bottom: -0.2rem;
}
.p--b--2--reverse {
  margin-bottom: -0.4rem;
}
.p--b--3--reverse {
  margin-bottom: -0.8rem;
}
.p--b--4--reverse {
  margin-bottom: -1.2rem;
}
.p--b--5--reverse {
  margin-bottom: -1.6rem;
}
.p--b--6--reverse {
  margin-bottom: -2.4rem;
}
.p--b--7--reverse {
  margin-bottom: -3.2rem;
}
.p--b--8--reverse {
  margin-bottom: -4rem;
}
.p--b--9--reverse {
  margin-bottom: -5.6rem;
}
.p--b--10--reverse {
  margin-bottom: -11.2rem;
}
.p--b--11--reverse {
  margin-bottom: -22.4rem;
}
.p--l--0 {
  padding-left: 0;
}
.p--l--1 {
  padding-left: 0.2rem;
}
.p--l--2 {
  padding-left: 0.4rem;
}
.p--l--3 {
  padding-left: 0.8rem;
}
.p--l--4 {
  padding-left: 1.2rem;
}
.p--l--5 {
  padding-left: 1.6rem;
}
.p--l--6 {
  padding-left: 2.4rem;
}
.p--l--7 {
  padding-left: 3.2rem;
}
.p--l--8 {
  padding-left: 4rem;
}
.p--l--9 {
  padding-left: 5.6rem;
}
.p--l--10 {
  padding-left: 11.2rem;
}
.p--l--11 {
  padding-left: 22.4rem;
}
.p--l--0--reverse {
  margin-left: -0;
}
.p--l--1--reverse {
  margin-left: -0.2rem;
}
.p--l--2--reverse {
  margin-left: -0.4rem;
}
.p--l--3--reverse {
  margin-left: -0.8rem;
}
.p--l--4--reverse {
  margin-left: -1.2rem;
}
.p--l--5--reverse {
  margin-left: -1.6rem;
}
.p--l--6--reverse {
  margin-left: -2.4rem;
}
.p--l--7--reverse {
  margin-left: -3.2rem;
}
.p--l--8--reverse {
  margin-left: -4rem;
}
.p--l--9--reverse {
  margin-left: -5.6rem;
}
.p--l--10--reverse {
  margin-left: -11.2rem;
}
.p--l--11--reverse {
  margin-left: -22.4rem;
}
.p--y--0 {
  padding-top: 0;
}
.p--y--1 {
  padding-top: 0.2rem;
}
.p--y--2 {
  padding-top: 0.4rem;
}
.p--y--3 {
  padding-top: 0.8rem;
}
.p--y--4 {
  padding-top: 1.2rem;
}
.p--y--5 {
  padding-top: 1.6rem;
}
.p--y--6 {
  padding-top: 2.4rem;
}
.p--y--7 {
  padding-top: 3.2rem;
}
.p--y--8 {
  padding-top: 4rem;
}
.p--y--9 {
  padding-top: 5.6rem;
}
.p--y--10 {
  padding-top: 11.2rem;
}
.p--y--11 {
  padding-top: 22.4rem;
}
.p--y--0 {
  padding-bottom: 0;
}
.p--y--1 {
  padding-bottom: 0.2rem;
}
.p--y--2 {
  padding-bottom: 0.4rem;
}
.p--y--3 {
  padding-bottom: 0.8rem;
}
.p--y--4 {
  padding-bottom: 1.2rem;
}
.p--y--5 {
  padding-bottom: 1.6rem;
}
.p--y--6 {
  padding-bottom: 2.4rem;
}
.p--y--7 {
  padding-bottom: 3.2rem;
}
.p--y--8 {
  padding-bottom: 4rem;
}
.p--y--9 {
  padding-bottom: 5.6rem;
}
.p--y--10 {
  padding-bottom: 11.2rem;
}
.p--y--11 {
  padding-bottom: 22.4rem;
}
.p--x--0 {
  padding-right: 0;
}
.p--x--1 {
  padding-right: 0.2rem;
}
.p--x--2 {
  padding-right: 0.4rem;
}
.p--x--3 {
  padding-right: 0.8rem;
}
.p--x--4 {
  padding-right: 1.2rem;
}
.p--x--5 {
  padding-right: 1.6rem;
}
.p--x--6 {
  padding-right: 2.4rem;
}
.p--x--7 {
  padding-right: 3.2rem;
}
.p--x--8 {
  padding-right: 4rem;
}
.p--x--9 {
  padding-right: 5.6rem;
}
.p--x--10 {
  padding-right: 11.2rem;
}
.p--x--11 {
  padding-right: 22.4rem;
}
.p--x--0 {
  padding-left: 0;
}
.p--x--1 {
  padding-left: 0.2rem;
}
.p--x--2 {
  padding-left: 0.4rem;
}
.p--x--3 {
  padding-left: 0.8rem;
}
.p--x--4 {
  padding-left: 1.2rem;
}
.p--x--5 {
  padding-left: 1.6rem;
}
.p--x--6 {
  padding-left: 2.4rem;
}
.p--x--7 {
  padding-left: 3.2rem;
}
.p--x--8 {
  padding-left: 4rem;
}
.p--x--9 {
  padding-left: 5.6rem;
}
.p--x--10 {
  padding-left: 11.2rem;
}
.p--x--11 {
  padding-left: 22.4rem;
}
@media (min-width: 576px) {
  .p--sm--0 {
    padding: 0;
  }
  .p--sm--1 {
    padding: 0.2rem;
  }
  .p--sm--2 {
    padding: 0.4rem;
  }
  .p--sm--3 {
    padding: 0.8rem;
  }
  .p--sm--4 {
    padding: 1.2rem;
  }
  .p--sm--5 {
    padding: 1.6rem;
  }
  .p--sm--6 {
    padding: 2.4rem;
  }
  .p--sm--7 {
    padding: 3.2rem;
  }
  .p--sm--8 {
    padding: 4rem;
  }
  .p--sm--9 {
    padding: 5.6rem;
  }
  .p--sm--10 {
    padding: 11.2rem;
  }
  .p--sm--11 {
    padding: 22.4rem;
  }
  .p--t--sm--0 {
    padding-top: 0;
  }
  .p--t--sm--1 {
    padding-top: 0.2rem;
  }
  .p--t--sm--2 {
    padding-top: 0.4rem;
  }
  .p--t--sm--3 {
    padding-top: 0.8rem;
  }
  .p--t--sm--4 {
    padding-top: 1.2rem;
  }
  .p--t--sm--5 {
    padding-top: 1.6rem;
  }
  .p--t--sm--6 {
    padding-top: 2.4rem;
  }
  .p--t--sm--7 {
    padding-top: 3.2rem;
  }
  .p--t--sm--8 {
    padding-top: 4rem;
  }
  .p--t--sm--9 {
    padding-top: 5.6rem;
  }
  .p--t--sm--10 {
    padding-top: 11.2rem;
  }
  .p--t--sm--11 {
    padding-top: 22.4rem;
  }
  .p--r--sm--0 {
    padding-right: 0;
  }
  .p--r--sm--1 {
    padding-right: 0.2rem;
  }
  .p--r--sm--2 {
    padding-right: 0.4rem;
  }
  .p--r--sm--3 {
    padding-right: 0.8rem;
  }
  .p--r--sm--4 {
    padding-right: 1.2rem;
  }
  .p--r--sm--5 {
    padding-right: 1.6rem;
  }
  .p--r--sm--6 {
    padding-right: 2.4rem;
  }
  .p--r--sm--7 {
    padding-right: 3.2rem;
  }
  .p--r--sm--8 {
    padding-right: 4rem;
  }
  .p--r--sm--9 {
    padding-right: 5.6rem;
  }
  .p--r--sm--10 {
    padding-right: 11.2rem;
  }
  .p--r--sm--11 {
    padding-right: 22.4rem;
  }
  .p--b--sm--0 {
    padding-bottom: 0;
  }
  .p--b--sm--1 {
    padding-bottom: 0.2rem;
  }
  .p--b--sm--2 {
    padding-bottom: 0.4rem;
  }
  .p--b--sm--3 {
    padding-bottom: 0.8rem;
  }
  .p--b--sm--4 {
    padding-bottom: 1.2rem;
  }
  .p--b--sm--5 {
    padding-bottom: 1.6rem;
  }
  .p--b--sm--6 {
    padding-bottom: 2.4rem;
  }
  .p--b--sm--7 {
    padding-bottom: 3.2rem;
  }
  .p--b--sm--8 {
    padding-bottom: 4rem;
  }
  .p--b--sm--9 {
    padding-bottom: 5.6rem;
  }
  .p--b--sm--10 {
    padding-bottom: 11.2rem;
  }
  .p--b--sm--11 {
    padding-bottom: 22.4rem;
  }
  .p--l--sm--0 {
    padding-left: 0;
  }
  .p--l--sm--1 {
    padding-left: 0.2rem;
  }
  .p--l--sm--2 {
    padding-left: 0.4rem;
  }
  .p--l--sm--3 {
    padding-left: 0.8rem;
  }
  .p--l--sm--4 {
    padding-left: 1.2rem;
  }
  .p--l--sm--5 {
    padding-left: 1.6rem;
  }
  .p--l--sm--6 {
    padding-left: 2.4rem;
  }
  .p--l--sm--7 {
    padding-left: 3.2rem;
  }
  .p--l--sm--8 {
    padding-left: 4rem;
  }
  .p--l--sm--9 {
    padding-left: 5.6rem;
  }
  .p--l--sm--10 {
    padding-left: 11.2rem;
  }
  .p--l--sm--11 {
    padding-left: 22.4rem;
  }
  .p--y--sm--0 {
    padding-top: 0;
  }
  .p--y--sm--1 {
    padding-top: 0.2rem;
  }
  .p--y--sm--2 {
    padding-top: 0.4rem;
  }
  .p--y--sm--3 {
    padding-top: 0.8rem;
  }
  .p--y--sm--4 {
    padding-top: 1.2rem;
  }
  .p--y--sm--5 {
    padding-top: 1.6rem;
  }
  .p--y--sm--6 {
    padding-top: 2.4rem;
  }
  .p--y--sm--7 {
    padding-top: 3.2rem;
  }
  .p--y--sm--8 {
    padding-top: 4rem;
  }
  .p--y--sm--9 {
    padding-top: 5.6rem;
  }
  .p--y--sm--10 {
    padding-top: 11.2rem;
  }
  .p--y--sm--11 {
    padding-top: 22.4rem;
  }
  .p--y--sm--0 {
    padding-bottom: 0;
  }
  .p--y--sm--1 {
    padding-bottom: 0.2rem;
  }
  .p--y--sm--2 {
    padding-bottom: 0.4rem;
  }
  .p--y--sm--3 {
    padding-bottom: 0.8rem;
  }
  .p--y--sm--4 {
    padding-bottom: 1.2rem;
  }
  .p--y--sm--5 {
    padding-bottom: 1.6rem;
  }
  .p--y--sm--6 {
    padding-bottom: 2.4rem;
  }
  .p--y--sm--7 {
    padding-bottom: 3.2rem;
  }
  .p--y--sm--8 {
    padding-bottom: 4rem;
  }
  .p--y--sm--9 {
    padding-bottom: 5.6rem;
  }
  .p--y--sm--10 {
    padding-bottom: 11.2rem;
  }
  .p--y--sm--11 {
    padding-bottom: 22.4rem;
  }
  .p--x--sm--0 {
    padding-right: 0;
  }
  .p--x--sm--1 {
    padding-right: 0.2rem;
  }
  .p--x--sm--2 {
    padding-right: 0.4rem;
  }
  .p--x--sm--3 {
    padding-right: 0.8rem;
  }
  .p--x--sm--4 {
    padding-right: 1.2rem;
  }
  .p--x--sm--5 {
    padding-right: 1.6rem;
  }
  .p--x--sm--6 {
    padding-right: 2.4rem;
  }
  .p--x--sm--7 {
    padding-right: 3.2rem;
  }
  .p--x--sm--8 {
    padding-right: 4rem;
  }
  .p--x--sm--9 {
    padding-right: 5.6rem;
  }
  .p--x--sm--10 {
    padding-right: 11.2rem;
  }
  .p--x--sm--11 {
    padding-right: 22.4rem;
  }
  .p--x--sm--0 {
    padding-left: 0;
  }
  .p--x--sm--1 {
    padding-left: 0.2rem;
  }
  .p--x--sm--2 {
    padding-left: 0.4rem;
  }
  .p--x--sm--3 {
    padding-left: 0.8rem;
  }
  .p--x--sm--4 {
    padding-left: 1.2rem;
  }
  .p--x--sm--5 {
    padding-left: 1.6rem;
  }
  .p--x--sm--6 {
    padding-left: 2.4rem;
  }
  .p--x--sm--7 {
    padding-left: 3.2rem;
  }
  .p--x--sm--8 {
    padding-left: 4rem;
  }
  .p--x--sm--9 {
    padding-left: 5.6rem;
  }
  .p--x--sm--10 {
    padding-left: 11.2rem;
  }
  .p--x--sm--11 {
    padding-left: 22.4rem;
  }
}
@media (min-width: 768px) {
  .p--md--0 {
    padding: 0;
  }
  .p--md--1 {
    padding: 0.2rem;
  }
  .p--md--2 {
    padding: 0.4rem;
  }
  .p--md--3 {
    padding: 0.8rem;
  }
  .p--md--4 {
    padding: 1.2rem;
  }
  .p--md--5 {
    padding: 1.6rem;
  }
  .p--md--6 {
    padding: 2.4rem;
  }
  .p--md--7 {
    padding: 3.2rem;
  }
  .p--md--8 {
    padding: 4rem;
  }
  .p--md--9 {
    padding: 5.6rem;
  }
  .p--md--10 {
    padding: 11.2rem;
  }
  .p--md--11 {
    padding: 22.4rem;
  }
  .p--t--md--0 {
    padding-top: 0;
  }
  .p--t--md--1 {
    padding-top: 0.2rem;
  }
  .p--t--md--2 {
    padding-top: 0.4rem;
  }
  .p--t--md--3 {
    padding-top: 0.8rem;
  }
  .p--t--md--4 {
    padding-top: 1.2rem;
  }
  .p--t--md--5 {
    padding-top: 1.6rem;
  }
  .p--t--md--6 {
    padding-top: 2.4rem;
  }
  .p--t--md--7 {
    padding-top: 3.2rem;
  }
  .p--t--md--8 {
    padding-top: 4rem;
  }
  .p--t--md--9 {
    padding-top: 5.6rem;
  }
  .p--t--md--10 {
    padding-top: 11.2rem;
  }
  .p--t--md--11 {
    padding-top: 22.4rem;
  }
  .p--r--md--0 {
    padding-right: 0;
  }
  .p--r--md--1 {
    padding-right: 0.2rem;
  }
  .p--r--md--2 {
    padding-right: 0.4rem;
  }
  .p--r--md--3 {
    padding-right: 0.8rem;
  }
  .p--r--md--4 {
    padding-right: 1.2rem;
  }
  .p--r--md--5 {
    padding-right: 1.6rem;
  }
  .p--r--md--6 {
    padding-right: 2.4rem;
  }
  .p--r--md--7 {
    padding-right: 3.2rem;
  }
  .p--r--md--8 {
    padding-right: 4rem;
  }
  .p--r--md--9 {
    padding-right: 5.6rem;
  }
  .p--r--md--10 {
    padding-right: 11.2rem;
  }
  .p--r--md--11 {
    padding-right: 22.4rem;
  }
  .p--b--md--0 {
    padding-bottom: 0;
  }
  .p--b--md--1 {
    padding-bottom: 0.2rem;
  }
  .p--b--md--2 {
    padding-bottom: 0.4rem;
  }
  .p--b--md--3 {
    padding-bottom: 0.8rem;
  }
  .p--b--md--4 {
    padding-bottom: 1.2rem;
  }
  .p--b--md--5 {
    padding-bottom: 1.6rem;
  }
  .p--b--md--6 {
    padding-bottom: 2.4rem;
  }
  .p--b--md--7 {
    padding-bottom: 3.2rem;
  }
  .p--b--md--8 {
    padding-bottom: 4rem;
  }
  .p--b--md--9 {
    padding-bottom: 5.6rem;
  }
  .p--b--md--10 {
    padding-bottom: 11.2rem;
  }
  .p--b--md--11 {
    padding-bottom: 22.4rem;
  }
  .p--l--md--0 {
    padding-left: 0;
  }
  .p--l--md--1 {
    padding-left: 0.2rem;
  }
  .p--l--md--2 {
    padding-left: 0.4rem;
  }
  .p--l--md--3 {
    padding-left: 0.8rem;
  }
  .p--l--md--4 {
    padding-left: 1.2rem;
  }
  .p--l--md--5 {
    padding-left: 1.6rem;
  }
  .p--l--md--6 {
    padding-left: 2.4rem;
  }
  .p--l--md--7 {
    padding-left: 3.2rem;
  }
  .p--l--md--8 {
    padding-left: 4rem;
  }
  .p--l--md--9 {
    padding-left: 5.6rem;
  }
  .p--l--md--10 {
    padding-left: 11.2rem;
  }
  .p--l--md--11 {
    padding-left: 22.4rem;
  }
  .p--y--md--0 {
    padding-top: 0;
  }
  .p--y--md--1 {
    padding-top: 0.2rem;
  }
  .p--y--md--2 {
    padding-top: 0.4rem;
  }
  .p--y--md--3 {
    padding-top: 0.8rem;
  }
  .p--y--md--4 {
    padding-top: 1.2rem;
  }
  .p--y--md--5 {
    padding-top: 1.6rem;
  }
  .p--y--md--6 {
    padding-top: 2.4rem;
  }
  .p--y--md--7 {
    padding-top: 3.2rem;
  }
  .p--y--md--8 {
    padding-top: 4rem;
  }
  .p--y--md--9 {
    padding-top: 5.6rem;
  }
  .p--y--md--10 {
    padding-top: 11.2rem;
  }
  .p--y--md--11 {
    padding-top: 22.4rem;
  }
  .p--y--md--0 {
    padding-bottom: 0;
  }
  .p--y--md--1 {
    padding-bottom: 0.2rem;
  }
  .p--y--md--2 {
    padding-bottom: 0.4rem;
  }
  .p--y--md--3 {
    padding-bottom: 0.8rem;
  }
  .p--y--md--4 {
    padding-bottom: 1.2rem;
  }
  .p--y--md--5 {
    padding-bottom: 1.6rem;
  }
  .p--y--md--6 {
    padding-bottom: 2.4rem;
  }
  .p--y--md--7 {
    padding-bottom: 3.2rem;
  }
  .p--y--md--8 {
    padding-bottom: 4rem;
  }
  .p--y--md--9 {
    padding-bottom: 5.6rem;
  }
  .p--y--md--10 {
    padding-bottom: 11.2rem;
  }
  .p--y--md--11 {
    padding-bottom: 22.4rem;
  }
  .p--x--md--0 {
    padding-right: 0;
  }
  .p--x--md--1 {
    padding-right: 0.2rem;
  }
  .p--x--md--2 {
    padding-right: 0.4rem;
  }
  .p--x--md--3 {
    padding-right: 0.8rem;
  }
  .p--x--md--4 {
    padding-right: 1.2rem;
  }
  .p--x--md--5 {
    padding-right: 1.6rem;
  }
  .p--x--md--6 {
    padding-right: 2.4rem;
  }
  .p--x--md--7 {
    padding-right: 3.2rem;
  }
  .p--x--md--8 {
    padding-right: 4rem;
  }
  .p--x--md--9 {
    padding-right: 5.6rem;
  }
  .p--x--md--10 {
    padding-right: 11.2rem;
  }
  .p--x--md--11 {
    padding-right: 22.4rem;
  }
  .p--x--md--0 {
    padding-left: 0;
  }
  .p--x--md--1 {
    padding-left: 0.2rem;
  }
  .p--x--md--2 {
    padding-left: 0.4rem;
  }
  .p--x--md--3 {
    padding-left: 0.8rem;
  }
  .p--x--md--4 {
    padding-left: 1.2rem;
  }
  .p--x--md--5 {
    padding-left: 1.6rem;
  }
  .p--x--md--6 {
    padding-left: 2.4rem;
  }
  .p--x--md--7 {
    padding-left: 3.2rem;
  }
  .p--x--md--8 {
    padding-left: 4rem;
  }
  .p--x--md--9 {
    padding-left: 5.6rem;
  }
  .p--x--md--10 {
    padding-left: 11.2rem;
  }
  .p--x--md--11 {
    padding-left: 22.4rem;
  }
}
@media (min-width: 992px) {
  .p--lg--0 {
    padding: 0;
  }
  .p--lg--1 {
    padding: 0.2rem;
  }
  .p--lg--2 {
    padding: 0.4rem;
  }
  .p--lg--3 {
    padding: 0.8rem;
  }
  .p--lg--4 {
    padding: 1.2rem;
  }
  .p--lg--5 {
    padding: 1.6rem;
  }
  .p--lg--6 {
    padding: 2.4rem;
  }
  .p--lg--7 {
    padding: 3.2rem;
  }
  .p--lg--8 {
    padding: 4rem;
  }
  .p--lg--9 {
    padding: 5.6rem;
  }
  .p--lg--10 {
    padding: 11.2rem;
  }
  .p--lg--11 {
    padding: 22.4rem;
  }
  .p--t--lg--0 {
    padding-top: 0;
  }
  .p--t--lg--1 {
    padding-top: 0.2rem;
  }
  .p--t--lg--2 {
    padding-top: 0.4rem;
  }
  .p--t--lg--3 {
    padding-top: 0.8rem;
  }
  .p--t--lg--4 {
    padding-top: 1.2rem;
  }
  .p--t--lg--5 {
    padding-top: 1.6rem;
  }
  .p--t--lg--6 {
    padding-top: 2.4rem;
  }
  .p--t--lg--7 {
    padding-top: 3.2rem;
  }
  .p--t--lg--8 {
    padding-top: 4rem;
  }
  .p--t--lg--9 {
    padding-top: 5.6rem;
  }
  .p--t--lg--10 {
    padding-top: 11.2rem;
  }
  .p--t--lg--11 {
    padding-top: 22.4rem;
  }
  .p--r--lg--0 {
    padding-right: 0;
  }
  .p--r--lg--1 {
    padding-right: 0.2rem;
  }
  .p--r--lg--2 {
    padding-right: 0.4rem;
  }
  .p--r--lg--3 {
    padding-right: 0.8rem;
  }
  .p--r--lg--4 {
    padding-right: 1.2rem;
  }
  .p--r--lg--5 {
    padding-right: 1.6rem;
  }
  .p--r--lg--6 {
    padding-right: 2.4rem;
  }
  .p--r--lg--7 {
    padding-right: 3.2rem;
  }
  .p--r--lg--8 {
    padding-right: 4rem;
  }
  .p--r--lg--9 {
    padding-right: 5.6rem;
  }
  .p--r--lg--10 {
    padding-right: 11.2rem;
  }
  .p--r--lg--11 {
    padding-right: 22.4rem;
  }
  .p--b--lg--0 {
    padding-bottom: 0;
  }
  .p--b--lg--1 {
    padding-bottom: 0.2rem;
  }
  .p--b--lg--2 {
    padding-bottom: 0.4rem;
  }
  .p--b--lg--3 {
    padding-bottom: 0.8rem;
  }
  .p--b--lg--4 {
    padding-bottom: 1.2rem;
  }
  .p--b--lg--5 {
    padding-bottom: 1.6rem;
  }
  .p--b--lg--6 {
    padding-bottom: 2.4rem;
  }
  .p--b--lg--7 {
    padding-bottom: 3.2rem;
  }
  .p--b--lg--8 {
    padding-bottom: 4rem;
  }
  .p--b--lg--9 {
    padding-bottom: 5.6rem;
  }
  .p--b--lg--10 {
    padding-bottom: 11.2rem;
  }
  .p--b--lg--11 {
    padding-bottom: 22.4rem;
  }
  .p--l--lg--0 {
    padding-left: 0;
  }
  .p--l--lg--1 {
    padding-left: 0.2rem;
  }
  .p--l--lg--2 {
    padding-left: 0.4rem;
  }
  .p--l--lg--3 {
    padding-left: 0.8rem;
  }
  .p--l--lg--4 {
    padding-left: 1.2rem;
  }
  .p--l--lg--5 {
    padding-left: 1.6rem;
  }
  .p--l--lg--6 {
    padding-left: 2.4rem;
  }
  .p--l--lg--7 {
    padding-left: 3.2rem;
  }
  .p--l--lg--8 {
    padding-left: 4rem;
  }
  .p--l--lg--9 {
    padding-left: 5.6rem;
  }
  .p--l--lg--10 {
    padding-left: 11.2rem;
  }
  .p--l--lg--11 {
    padding-left: 22.4rem;
  }
  .p--y--lg--0 {
    padding-top: 0;
  }
  .p--y--lg--1 {
    padding-top: 0.2rem;
  }
  .p--y--lg--2 {
    padding-top: 0.4rem;
  }
  .p--y--lg--3 {
    padding-top: 0.8rem;
  }
  .p--y--lg--4 {
    padding-top: 1.2rem;
  }
  .p--y--lg--5 {
    padding-top: 1.6rem;
  }
  .p--y--lg--6 {
    padding-top: 2.4rem;
  }
  .p--y--lg--7 {
    padding-top: 3.2rem;
  }
  .p--y--lg--8 {
    padding-top: 4rem;
  }
  .p--y--lg--9 {
    padding-top: 5.6rem;
  }
  .p--y--lg--10 {
    padding-top: 11.2rem;
  }
  .p--y--lg--11 {
    padding-top: 22.4rem;
  }
  .p--y--lg--0 {
    padding-bottom: 0;
  }
  .p--y--lg--1 {
    padding-bottom: 0.2rem;
  }
  .p--y--lg--2 {
    padding-bottom: 0.4rem;
  }
  .p--y--lg--3 {
    padding-bottom: 0.8rem;
  }
  .p--y--lg--4 {
    padding-bottom: 1.2rem;
  }
  .p--y--lg--5 {
    padding-bottom: 1.6rem;
  }
  .p--y--lg--6 {
    padding-bottom: 2.4rem;
  }
  .p--y--lg--7 {
    padding-bottom: 3.2rem;
  }
  .p--y--lg--8 {
    padding-bottom: 4rem;
  }
  .p--y--lg--9 {
    padding-bottom: 5.6rem;
  }
  .p--y--lg--10 {
    padding-bottom: 11.2rem;
  }
  .p--y--lg--11 {
    padding-bottom: 22.4rem;
  }
  .p--x--lg--0 {
    padding-right: 0;
  }
  .p--x--lg--1 {
    padding-right: 0.2rem;
  }
  .p--x--lg--2 {
    padding-right: 0.4rem;
  }
  .p--x--lg--3 {
    padding-right: 0.8rem;
  }
  .p--x--lg--4 {
    padding-right: 1.2rem;
  }
  .p--x--lg--5 {
    padding-right: 1.6rem;
  }
  .p--x--lg--6 {
    padding-right: 2.4rem;
  }
  .p--x--lg--7 {
    padding-right: 3.2rem;
  }
  .p--x--lg--8 {
    padding-right: 4rem;
  }
  .p--x--lg--9 {
    padding-right: 5.6rem;
  }
  .p--x--lg--10 {
    padding-right: 11.2rem;
  }
  .p--x--lg--11 {
    padding-right: 22.4rem;
  }
  .p--x--lg--0 {
    padding-left: 0;
  }
  .p--x--lg--1 {
    padding-left: 0.2rem;
  }
  .p--x--lg--2 {
    padding-left: 0.4rem;
  }
  .p--x--lg--3 {
    padding-left: 0.8rem;
  }
  .p--x--lg--4 {
    padding-left: 1.2rem;
  }
  .p--x--lg--5 {
    padding-left: 1.6rem;
  }
  .p--x--lg--6 {
    padding-left: 2.4rem;
  }
  .p--x--lg--7 {
    padding-left: 3.2rem;
  }
  .p--x--lg--8 {
    padding-left: 4rem;
  }
  .p--x--lg--9 {
    padding-left: 5.6rem;
  }
  .p--x--lg--10 {
    padding-left: 11.2rem;
  }
  .p--x--lg--11 {
    padding-left: 22.4rem;
  }
}
@media (min-width: 1200px) {
  .p--xl--0 {
    padding: 0;
  }
  .p--xl--1 {
    padding: 0.2rem;
  }
  .p--xl--2 {
    padding: 0.4rem;
  }
  .p--xl--3 {
    padding: 0.8rem;
  }
  .p--xl--4 {
    padding: 1.2rem;
  }
  .p--xl--5 {
    padding: 1.6rem;
  }
  .p--xl--6 {
    padding: 2.4rem;
  }
  .p--xl--7 {
    padding: 3.2rem;
  }
  .p--xl--8 {
    padding: 4rem;
  }
  .p--xl--9 {
    padding: 5.6rem;
  }
  .p--xl--10 {
    padding: 11.2rem;
  }
  .p--xl--11 {
    padding: 22.4rem;
  }
  .p--t--xl--0 {
    padding-top: 0;
  }
  .p--t--xl--1 {
    padding-top: 0.2rem;
  }
  .p--t--xl--2 {
    padding-top: 0.4rem;
  }
  .p--t--xl--3 {
    padding-top: 0.8rem;
  }
  .p--t--xl--4 {
    padding-top: 1.2rem;
  }
  .p--t--xl--5 {
    padding-top: 1.6rem;
  }
  .p--t--xl--6 {
    padding-top: 2.4rem;
  }
  .p--t--xl--7 {
    padding-top: 3.2rem;
  }
  .p--t--xl--8 {
    padding-top: 4rem;
  }
  .p--t--xl--9 {
    padding-top: 5.6rem;
  }
  .p--t--xl--10 {
    padding-top: 11.2rem;
  }
  .p--t--xl--11 {
    padding-top: 22.4rem;
  }
  .p--r--xl--0 {
    padding-right: 0;
  }
  .p--r--xl--1 {
    padding-right: 0.2rem;
  }
  .p--r--xl--2 {
    padding-right: 0.4rem;
  }
  .p--r--xl--3 {
    padding-right: 0.8rem;
  }
  .p--r--xl--4 {
    padding-right: 1.2rem;
  }
  .p--r--xl--5 {
    padding-right: 1.6rem;
  }
  .p--r--xl--6 {
    padding-right: 2.4rem;
  }
  .p--r--xl--7 {
    padding-right: 3.2rem;
  }
  .p--r--xl--8 {
    padding-right: 4rem;
  }
  .p--r--xl--9 {
    padding-right: 5.6rem;
  }
  .p--r--xl--10 {
    padding-right: 11.2rem;
  }
  .p--r--xl--11 {
    padding-right: 22.4rem;
  }
  .p--b--xl--0 {
    padding-bottom: 0;
  }
  .p--b--xl--1 {
    padding-bottom: 0.2rem;
  }
  .p--b--xl--2 {
    padding-bottom: 0.4rem;
  }
  .p--b--xl--3 {
    padding-bottom: 0.8rem;
  }
  .p--b--xl--4 {
    padding-bottom: 1.2rem;
  }
  .p--b--xl--5 {
    padding-bottom: 1.6rem;
  }
  .p--b--xl--6 {
    padding-bottom: 2.4rem;
  }
  .p--b--xl--7 {
    padding-bottom: 3.2rem;
  }
  .p--b--xl--8 {
    padding-bottom: 4rem;
  }
  .p--b--xl--9 {
    padding-bottom: 5.6rem;
  }
  .p--b--xl--10 {
    padding-bottom: 11.2rem;
  }
  .p--b--xl--11 {
    padding-bottom: 22.4rem;
  }
  .p--l--xl--0 {
    padding-left: 0;
  }
  .p--l--xl--1 {
    padding-left: 0.2rem;
  }
  .p--l--xl--2 {
    padding-left: 0.4rem;
  }
  .p--l--xl--3 {
    padding-left: 0.8rem;
  }
  .p--l--xl--4 {
    padding-left: 1.2rem;
  }
  .p--l--xl--5 {
    padding-left: 1.6rem;
  }
  .p--l--xl--6 {
    padding-left: 2.4rem;
  }
  .p--l--xl--7 {
    padding-left: 3.2rem;
  }
  .p--l--xl--8 {
    padding-left: 4rem;
  }
  .p--l--xl--9 {
    padding-left: 5.6rem;
  }
  .p--l--xl--10 {
    padding-left: 11.2rem;
  }
  .p--l--xl--11 {
    padding-left: 22.4rem;
  }
  .p--y--xl--0 {
    padding-top: 0;
  }
  .p--y--xl--1 {
    padding-top: 0.2rem;
  }
  .p--y--xl--2 {
    padding-top: 0.4rem;
  }
  .p--y--xl--3 {
    padding-top: 0.8rem;
  }
  .p--y--xl--4 {
    padding-top: 1.2rem;
  }
  .p--y--xl--5 {
    padding-top: 1.6rem;
  }
  .p--y--xl--6 {
    padding-top: 2.4rem;
  }
  .p--y--xl--7 {
    padding-top: 3.2rem;
  }
  .p--y--xl--8 {
    padding-top: 4rem;
  }
  .p--y--xl--9 {
    padding-top: 5.6rem;
  }
  .p--y--xl--10 {
    padding-top: 11.2rem;
  }
  .p--y--xl--11 {
    padding-top: 22.4rem;
  }
  .p--y--xl--0 {
    padding-bottom: 0;
  }
  .p--y--xl--1 {
    padding-bottom: 0.2rem;
  }
  .p--y--xl--2 {
    padding-bottom: 0.4rem;
  }
  .p--y--xl--3 {
    padding-bottom: 0.8rem;
  }
  .p--y--xl--4 {
    padding-bottom: 1.2rem;
  }
  .p--y--xl--5 {
    padding-bottom: 1.6rem;
  }
  .p--y--xl--6 {
    padding-bottom: 2.4rem;
  }
  .p--y--xl--7 {
    padding-bottom: 3.2rem;
  }
  .p--y--xl--8 {
    padding-bottom: 4rem;
  }
  .p--y--xl--9 {
    padding-bottom: 5.6rem;
  }
  .p--y--xl--10 {
    padding-bottom: 11.2rem;
  }
  .p--y--xl--11 {
    padding-bottom: 22.4rem;
  }
  .p--x--xl--0 {
    padding-right: 0;
  }
  .p--x--xl--1 {
    padding-right: 0.2rem;
  }
  .p--x--xl--2 {
    padding-right: 0.4rem;
  }
  .p--x--xl--3 {
    padding-right: 0.8rem;
  }
  .p--x--xl--4 {
    padding-right: 1.2rem;
  }
  .p--x--xl--5 {
    padding-right: 1.6rem;
  }
  .p--x--xl--6 {
    padding-right: 2.4rem;
  }
  .p--x--xl--7 {
    padding-right: 3.2rem;
  }
  .p--x--xl--8 {
    padding-right: 4rem;
  }
  .p--x--xl--9 {
    padding-right: 5.6rem;
  }
  .p--x--xl--10 {
    padding-right: 11.2rem;
  }
  .p--x--xl--11 {
    padding-right: 22.4rem;
  }
  .p--x--xl--0 {
    padding-left: 0;
  }
  .p--x--xl--1 {
    padding-left: 0.2rem;
  }
  .p--x--xl--2 {
    padding-left: 0.4rem;
  }
  .p--x--xl--3 {
    padding-left: 0.8rem;
  }
  .p--x--xl--4 {
    padding-left: 1.2rem;
  }
  .p--x--xl--5 {
    padding-left: 1.6rem;
  }
  .p--x--xl--6 {
    padding-left: 2.4rem;
  }
  .p--x--xl--7 {
    padding-left: 3.2rem;
  }
  .p--x--xl--8 {
    padding-left: 4rem;
  }
  .p--x--xl--9 {
    padding-left: 5.6rem;
  }
  .p--x--xl--10 {
    padding-left: 11.2rem;
  }
  .p--x--xl--11 {
    padding-left: 22.4rem;
  }
}
@media (min-width: 1400px) {
  .p--xxl--0 {
    padding: 0;
  }
  .p--xxl--1 {
    padding: 0.2rem;
  }
  .p--xxl--2 {
    padding: 0.4rem;
  }
  .p--xxl--3 {
    padding: 0.8rem;
  }
  .p--xxl--4 {
    padding: 1.2rem;
  }
  .p--xxl--5 {
    padding: 1.6rem;
  }
  .p--xxl--6 {
    padding: 2.4rem;
  }
  .p--xxl--7 {
    padding: 3.2rem;
  }
  .p--xxl--8 {
    padding: 4rem;
  }
  .p--xxl--9 {
    padding: 5.6rem;
  }
  .p--xxl--10 {
    padding: 11.2rem;
  }
  .p--xxl--11 {
    padding: 22.4rem;
  }
  .p--t--xxl--0 {
    padding-top: 0;
  }
  .p--t--xxl--1 {
    padding-top: 0.2rem;
  }
  .p--t--xxl--2 {
    padding-top: 0.4rem;
  }
  .p--t--xxl--3 {
    padding-top: 0.8rem;
  }
  .p--t--xxl--4 {
    padding-top: 1.2rem;
  }
  .p--t--xxl--5 {
    padding-top: 1.6rem;
  }
  .p--t--xxl--6 {
    padding-top: 2.4rem;
  }
  .p--t--xxl--7 {
    padding-top: 3.2rem;
  }
  .p--t--xxl--8 {
    padding-top: 4rem;
  }
  .p--t--xxl--9 {
    padding-top: 5.6rem;
  }
  .p--t--xxl--10 {
    padding-top: 11.2rem;
  }
  .p--t--xxl--11 {
    padding-top: 22.4rem;
  }
  .p--r--xxl--0 {
    padding-right: 0;
  }
  .p--r--xxl--1 {
    padding-right: 0.2rem;
  }
  .p--r--xxl--2 {
    padding-right: 0.4rem;
  }
  .p--r--xxl--3 {
    padding-right: 0.8rem;
  }
  .p--r--xxl--4 {
    padding-right: 1.2rem;
  }
  .p--r--xxl--5 {
    padding-right: 1.6rem;
  }
  .p--r--xxl--6 {
    padding-right: 2.4rem;
  }
  .p--r--xxl--7 {
    padding-right: 3.2rem;
  }
  .p--r--xxl--8 {
    padding-right: 4rem;
  }
  .p--r--xxl--9 {
    padding-right: 5.6rem;
  }
  .p--r--xxl--10 {
    padding-right: 11.2rem;
  }
  .p--r--xxl--11 {
    padding-right: 22.4rem;
  }
  .p--b--xxl--0 {
    padding-bottom: 0;
  }
  .p--b--xxl--1 {
    padding-bottom: 0.2rem;
  }
  .p--b--xxl--2 {
    padding-bottom: 0.4rem;
  }
  .p--b--xxl--3 {
    padding-bottom: 0.8rem;
  }
  .p--b--xxl--4 {
    padding-bottom: 1.2rem;
  }
  .p--b--xxl--5 {
    padding-bottom: 1.6rem;
  }
  .p--b--xxl--6 {
    padding-bottom: 2.4rem;
  }
  .p--b--xxl--7 {
    padding-bottom: 3.2rem;
  }
  .p--b--xxl--8 {
    padding-bottom: 4rem;
  }
  .p--b--xxl--9 {
    padding-bottom: 5.6rem;
  }
  .p--b--xxl--10 {
    padding-bottom: 11.2rem;
  }
  .p--b--xxl--11 {
    padding-bottom: 22.4rem;
  }
  .p--l--xxl--0 {
    padding-left: 0;
  }
  .p--l--xxl--1 {
    padding-left: 0.2rem;
  }
  .p--l--xxl--2 {
    padding-left: 0.4rem;
  }
  .p--l--xxl--3 {
    padding-left: 0.8rem;
  }
  .p--l--xxl--4 {
    padding-left: 1.2rem;
  }
  .p--l--xxl--5 {
    padding-left: 1.6rem;
  }
  .p--l--xxl--6 {
    padding-left: 2.4rem;
  }
  .p--l--xxl--7 {
    padding-left: 3.2rem;
  }
  .p--l--xxl--8 {
    padding-left: 4rem;
  }
  .p--l--xxl--9 {
    padding-left: 5.6rem;
  }
  .p--l--xxl--10 {
    padding-left: 11.2rem;
  }
  .p--l--xxl--11 {
    padding-left: 22.4rem;
  }
  .p--y--xxl--0 {
    padding-top: 0;
  }
  .p--y--xxl--1 {
    padding-top: 0.2rem;
  }
  .p--y--xxl--2 {
    padding-top: 0.4rem;
  }
  .p--y--xxl--3 {
    padding-top: 0.8rem;
  }
  .p--y--xxl--4 {
    padding-top: 1.2rem;
  }
  .p--y--xxl--5 {
    padding-top: 1.6rem;
  }
  .p--y--xxl--6 {
    padding-top: 2.4rem;
  }
  .p--y--xxl--7 {
    padding-top: 3.2rem;
  }
  .p--y--xxl--8 {
    padding-top: 4rem;
  }
  .p--y--xxl--9 {
    padding-top: 5.6rem;
  }
  .p--y--xxl--10 {
    padding-top: 11.2rem;
  }
  .p--y--xxl--11 {
    padding-top: 22.4rem;
  }
  .p--y--xxl--0 {
    padding-bottom: 0;
  }
  .p--y--xxl--1 {
    padding-bottom: 0.2rem;
  }
  .p--y--xxl--2 {
    padding-bottom: 0.4rem;
  }
  .p--y--xxl--3 {
    padding-bottom: 0.8rem;
  }
  .p--y--xxl--4 {
    padding-bottom: 1.2rem;
  }
  .p--y--xxl--5 {
    padding-bottom: 1.6rem;
  }
  .p--y--xxl--6 {
    padding-bottom: 2.4rem;
  }
  .p--y--xxl--7 {
    padding-bottom: 3.2rem;
  }
  .p--y--xxl--8 {
    padding-bottom: 4rem;
  }
  .p--y--xxl--9 {
    padding-bottom: 5.6rem;
  }
  .p--y--xxl--10 {
    padding-bottom: 11.2rem;
  }
  .p--y--xxl--11 {
    padding-bottom: 22.4rem;
  }
  .p--x--xxl--0 {
    padding-right: 0;
  }
  .p--x--xxl--1 {
    padding-right: 0.2rem;
  }
  .p--x--xxl--2 {
    padding-right: 0.4rem;
  }
  .p--x--xxl--3 {
    padding-right: 0.8rem;
  }
  .p--x--xxl--4 {
    padding-right: 1.2rem;
  }
  .p--x--xxl--5 {
    padding-right: 1.6rem;
  }
  .p--x--xxl--6 {
    padding-right: 2.4rem;
  }
  .p--x--xxl--7 {
    padding-right: 3.2rem;
  }
  .p--x--xxl--8 {
    padding-right: 4rem;
  }
  .p--x--xxl--9 {
    padding-right: 5.6rem;
  }
  .p--x--xxl--10 {
    padding-right: 11.2rem;
  }
  .p--x--xxl--11 {
    padding-right: 22.4rem;
  }
  .p--x--xxl--0 {
    padding-left: 0;
  }
  .p--x--xxl--1 {
    padding-left: 0.2rem;
  }
  .p--x--xxl--2 {
    padding-left: 0.4rem;
  }
  .p--x--xxl--3 {
    padding-left: 0.8rem;
  }
  .p--x--xxl--4 {
    padding-left: 1.2rem;
  }
  .p--x--xxl--5 {
    padding-left: 1.6rem;
  }
  .p--x--xxl--6 {
    padding-left: 2.4rem;
  }
  .p--x--xxl--7 {
    padding-left: 3.2rem;
  }
  .p--x--xxl--8 {
    padding-left: 4rem;
  }
  .p--x--xxl--9 {
    padding-left: 5.6rem;
  }
  .p--x--xxl--10 {
    padding-left: 11.2rem;
  }
  .p--x--xxl--11 {
    padding-left: 22.4rem;
  }
}

.height--caption--sm {
  height: 1.5rem;
}
.height--caption, .height--content, .height--content--bold {
  height: 1.6rem;
}
.height--content--lg {
  height: 1.9rem;
}
.height--display {
  height: 4rem;
}
.height--0 {
  height: 0;
}
.height--1 {
  height: 0.2rem;
}
.height--2 {
  height: 0.4rem;
}
.height--3 {
  height: 0.8rem;
}
.height--4 {
  height: 1.2rem;
}
.height--5 {
  height: 1.6rem;
}
.height--6 {
  height: 2.4rem;
}
.height--7 {
  height: 3.2rem;
}
.height--8 {
  height: 4rem;
}
.height--9 {
  height: 5.6rem;
}
.height--10 {
  height: 11.2rem;
}
.height--11 {
  height: 22.4rem;
}

.cdk-drag {
  position: relative;
}
.cdk-drag-preview {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 1px 1px 0px rgba(24, 26, 32, 0.03), 0px 2px 2px 0px rgba(24, 26, 32, 0.03), 0px 4px 4px 0px rgba(24, 26, 32, 0.02), 0px 8px 8px 0px rgba(24, 26, 32, 0.01), 0px 16px 16px 0px rgba(24, 26, 32, 0.01), 0px 15px 50px 0px rgba(24, 26, 32, 0.05);
  box-shadow: 0px 1px 1px 0px rgba(24, 26, 32, 0.03), 0px 2px 2px 0px rgba(24, 26, 32, 0.03), 0px 4px 4px 0px rgba(24, 26, 32, 0.02), 0px 8px 8px 0px rgba(24, 26, 32, 0.01), 0px 16px 16px 0px rgba(24, 26, 32, 0.01), 0px 15px 50px 0px rgba(24, 26, 32, 0.05);
  border-radius: 1.2rem;
  background-color: #fcfcfd;
  opacity: 0.85;
  border: 2px solid #96acf9;
  overflow: hidden;
}
.cdk-drag-placeholder {
  position: relative;
}
.cdk-drag-placeholder::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 1.2rem;
  border: 1px dashed #b9c7fb;
  background-color: #dce4fd;
  z-index: 1;
  -webkit-box-shadow: inset 0px 1px 1px #181a2008, inset 0px 2px 2px #181a2008, inset 0px 4px 4px #181a2005;
  box-shadow: inset 0px 1px 1px #181a2008, inset 0px 2px 2px #181a2008, inset 0px 4px 4px #181a2005;
}
.cdk-drag-placeholder > * {
  opacity: 0;
}
.cdk-drag-animating {
  -webkit-transition: transform 100ms ease-in-out;
  transition: transform 100ms ease-in-out;
}
.cdk-drop-list-dragging .cdk-drag {
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1);
  transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1);
  -o-transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

tr.cdk-drag-placeholder {
  display: block;
}

.mention-link {
  color: #3456d0 !important;
}

.inline-msg--primary .inline-msg__content a {
  text-decoration: underline;
  color: #303d74;
}
.inline-msg--primary .inline-msg__content a:hover {
  color: #2e3862;
}
.inline-msg--primary--ghost .inline-msg__content a {
  color: #304287;
}
.inline-msg--primary--ghost .inline-msg__content a:hover {
  color: #303d74;
}
.inline-msg--secondary .inline-msg__content a {
  text-decoration: underline;
  color: #9d2b0f;
}
.inline-msg--secondary .inline-msg__content a:hover {
  color: #7e2210;
}
.inline-msg--secondary--ghost .inline-msg__content a {
  color: #c62e08;
}
.inline-msg--secondary--ghost .inline-msg__content a:hover {
  color: #9d2b0f;
}
.inline-msg--success .inline-msg__content a {
  text-decoration: underline;
  color: #1c5352;
}
.inline-msg--success .inline-msg__content a:hover {
  color: #1b4644;
}
.inline-msg--success--ghost .inline-msg__content a {
  color: #1d6864;
}
.inline-msg--success--ghost .inline-msg__content a:hover {
  color: #1c5352;
}
.inline-msg--warning .inline-msg__content a {
  text-decoration: underline;
  color: #9b4911;
}
.inline-msg--warning .inline-msg__content a:hover {
  color: #7d3d11;
}
.inline-msg--warning--ghost .inline-msg__content a {
  color: #c45e0a;
}
.inline-msg--warning--ghost .inline-msg__content a:hover {
  color: #9b4911;
}
.inline-msg--danger .inline-msg__content a {
  text-decoration: underline;
  color: #a8092d;
}
.inline-msg--danger .inline-msg__content a:hover {
  color: #8f0c2d;
}
.inline-msg--danger--ghost .inline-msg__content a {
  color: #c8082d;
}
.inline-msg--danger--ghost .inline-msg__content a:hover {
  color: #a8092d;
}
.inline-msg--neutral .inline-msg__content a {
  text-decoration: underline;
  color: #3e4556;
}
.inline-msg--neutral .inline-msg__content a:hover {
  color: #2b303b;
}
.inline-msg--neutral--ghost .inline-msg__content a {
  color: #4e586f;
}
.inline-msg--neutral--ghost .inline-msg__content a:hover {
  color: #3e4556;
}
.inline-msg--social .inline-msg__content a {
  text-decoration: underline;
}
.inline-msg--mpleo .inline-msg__content a {
  text-decoration: underline;
}
.inline-msg__content a {
  text-decoration: underline !important;
}

.inline {
  display: inline;
}

body mention-list,
body .dropdown-menu.mention-menu {
  z-index: 804;
}

body .dropdown-menu.mention-menu {
  display: block !important;
  position: absolute;
  background-color: #fcfcfd;
  border-radius: 0.8rem;
  border: 0.1rem solid #dfe3ec;
  padding: 1.6rem;
  margin: 0;
  min-width: calc(0.4rem * 10);
  max-width: calc(3.2rem * 10);
  max-height: calc(22.4rem * 2);
  overflow: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;
  letter-spacing: 0.14px;
  -webkit-transition: opacity 100ms ease-in-out, margin-top 200ms ease-in-out;
  transition: opacity 100ms ease-in-out, margin-top 200ms ease-in-out;
  -webkit-transition-delay: 0.5ms;
  -o-transition-delay: 0.5ms;
  transition-delay: 0.5ms;
  height: auto;
  opacity: 1;
  visibility: visible;
}
body .dropdown-menu.mention-menu[hidden] {
  margin-top: -0.4rem;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}
body .dropdown-menu.mention-menu .mention-active .mention-item {
  color: #303d74;
  background-color: #dce4fd;
}
body .dropdown-menu.mention-menu .mention-item {
  color: #4e586f;
  border-radius: 0.8rem;
  -webkit-transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;
  letter-spacing: 0.14px;
  padding: 0.8rem;
  border: none;
  max-width: 100%;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
body .dropdown-menu.mention-menu .mention-item:hover {
  color: #303d74;
  background-color: #dce4fd;
}

.innerHTML-mail {
  word-break: break-word;
}
.innerHTML-mail figure,
.innerHTML-mail img {
  max-width: 100%;
  height: auto;
}
.innerHTML-mail table {
  max-width: 100%;
  width: auto !important;
}
.innerHTML-mail div blockquote {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  padding-left: 1.6rem;
  border-left: 0.1rem solid #dfe3ec;
}
.innerHTML-mail.one-line, .innerHTML-mail.one-line * {
  font-size: 1.3rem !important;
  font-weight: normal !important;
  text-align: left;
  padding: 0;
}
.innerHTML-mail.one-line figure,
.innerHTML-mail.one-line img {
  display: none;
}

.coopmi-desc__content p,
.coopmi-desc__content dl,
.coopmi-desc__content blockquote,
.coopmi-desc__content hr,
.coopmi-desc__content pre,
.coopmi-desc__content table,
.coopmi-desc__content figure,
.coopmi-desc__content address {
  margin-bottom: 0;
  line-height: 1.5;
  min-height: 2.4rem;
  word-break: break-word;
}
.coopmi-desc__content h1 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 3.2rem;
  line-height: 120%;
  font-weight: 600;
  letter-spacing: -0.64px;
}
.coopmi-desc__content h4 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 500;
}
.coopmi-desc__content h5 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 400;
}
.coopmi-desc__content h6 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;
  letter-spacing: 0.14px;
}
.coopmi-desc__content h1,
.coopmi-desc__content h2,
.coopmi-desc__content h3,
.coopmi-desc__content h4,
.coopmi-desc__content h5,
.coopmi-desc__content h6 {
  margin-bottom: 1.6rem;
  padding: 0;
  line-height: 1.2;
  min-height: 0;
}
.coopmi-desc__content h1 {
  padding-bottom: 0.4rem;
}
.coopmi-desc__content h2 {
  margin-top: 0.8rem;
}
.coopmi-desc__content blockquote {
  font-style: italic;
  opacity: 0.9;
}
.coopmi-desc__content a {
  -webkit-transition: color 100ms ease-in-out;
  transition: color 100ms ease-in-out;
  color: #3e4556;
  text-decoration: underline;
}
.coopmi-desc__content a:hover {
  color: #304287;
}
.coopmi-desc__content pre {
  border: 0.1rem solid #dfe3ec;
  font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
  background-color: #f2f5f7;
  border-radius: 0.4rem;
  padding: 0;
  top: 0;
  display: block;
  color: #3e4556;
  overflow: none;
  white-space: pre-wrap;
  padding: 0.8rem;
}
.coopmi-desc__content ul,
.coopmi-desc__content ol {
  padding: 0;
  padding-left: 0.4rem;
  margin: 0 0 0.8rem 1.6rem;
}
.coopmi-desc__content ul ul,
.coopmi-desc__content ul ol,
.coopmi-desc__content ol ol,
.coopmi-desc__content ol ul {
  margin-bottom: 0;
}
.coopmi-desc__content ul li,
.coopmi-desc__content ol li {
  margin: 0;
  list-style-position: initial;
  line-height: 2rem;
}
.coopmi-desc__content h2,
.coopmi-desc__content h3 {
  margin-bottom: 1.6rem;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 500;
  position: relative;
  display: inline-block;
  padding-left: 2.4rem;
  color: #2b303b;
}
.coopmi-desc__content h2::before,
.coopmi-desc__content h3::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.95rem;
  height: 0.4rem;
  width: 1.6rem;
  border-radius: 0.8rem;
  background-color: #3d64ed;
}

wr-holding-switch-dialog cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
  padding-left: 1.6rem;
  width: 100%;
}

wr-candidate-list cdk-virtual-scroll-viewport .cdk-virtual-scroll-content-wrapper {
  right: 0;
}

#intercom-frame,
.intercom-lightweight-app {
  display: none !important;
  visibility: hidden !important;
}

.txt-infos:not(:empty) {
  display: block;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
  padding-bottom: 0.4rem;
  color: #8898b9;
}

.dialog__modal__footer, .dialog__modal__footer > div[footer] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-flow: column-reverse wrap;
  flex-flow: column-reverse wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
.dialog__modal__footer wr-btn:not(:last-of-type), .dialog__modal__footer > div[footer] wr-btn:not(:last-of-type) {
  margin-top: 0.8rem;
}

@media (min-width: 576px) {
  .dialog__modal__footer, .dialog__modal__footer > div[footer] {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .dialog__modal__footer wr-btn, .dialog__modal__footer > div[footer] wr-btn {
    margin-bottom: 0.8rem;
  }
  .dialog__modal__footer wr-btn:not(:last-of-type), .dialog__modal__footer > div[footer] wr-btn:not(:last-of-type) {
    margin-top: 0;
  }
  .dialog__modal__footer wr-btn:not(:first-of-type), .dialog__modal__footer > div[footer] wr-btn:not(:first-of-type) {
    margin-left: 0.8rem;
  }
}
.stats-tooltip span {
  padding-left: 0.8rem;
}

@media (min-width: 576px) {
  .tooltip-inner, .tooltip > .arrow {
    display: block;
  }
}
@media (min-width: 768px) {
  #intercom-frame,
  .intercom-lightweight-app {
    display: block !important;
    visibility: visible !important;
  }
}
.extension .tooltip-inner, .extension .tooltip > .arrow {
  display: block;
}

wr-angular-table .table__Tmpl__Applications,
wr-table .table__Tmpl__Applications {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  max-width: calc(10 * (5.6rem));
}
wr-angular-table .table__Tmpl__ApplicationStates,
wr-table .table__Tmpl__ApplicationStates {
  line-height: 1.8rem;
}

wr-angular-table .mat-sort-header-container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

wr-angular-table .mat-sort-header-container {
  justify-content: flex-start;
}
wr-angular-table .mat-sort-header-arrow {
  padding-left: 0.8rem;
  -webkit-transform: translateY(0px) !important;
  -ms-transform: translateY(0px) !important;
  transform: translateY(0px) !important;
}
wr-angular-table .mat-sort-header-stem, wr-angular-table .mat-sort-header-pointer-left, wr-angular-table .mat-sort-header-pointer-right, wr-angular-table .mat-sort-header-pointer-middle {
  display: none;
}
wr-angular-table .mat-sort-header:hover .mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
  opacity: 1;
}
wr-angular-table .mat-sort-header:hover .mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow .mat-sort-header-indicator::before {
  font-family: "Font Awesome 6 Free" !important;
  /* FontAwesome fa... styling ======= */
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f0dc";
  font-weight: 900;
  top: 1px;
  position: absolute;
  font-size: 1.2rem;
  color: #8898b9;
}
wr-angular-table .mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow .mat-sort-header-indicator::before {
  color: #4e586f;
}
wr-angular-table .mat-sort-header[aria-sort=ascending] .mat-sort-header-arrow .mat-sort-header-indicator::before {
  font-family: "Font Awesome 6 Free" !important;
  /* FontAwesome fa... styling ======= */
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f0de";
  font-weight: 900;
  position: absolute;
  font-size: 1.2rem;
  color: #4e586f;
  top: 3px;
}
wr-angular-table .mat-sort-header[aria-sort=descending] .mat-sort-header-arrow .mat-sort-header-indicator::before {
  font-family: "Font Awesome 6 Free" !important;
  /* FontAwesome fa... styling ======= */
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f0dd";
  font-weight: 900;
  position: absolute;
  font-size: 1.2rem;
  color: #4e586f;
  top: -12px;
}
wr-angular-table .mat-header-cell.cdk-drag {
  color: #2b303b;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 600;
  letter-spacing: -0.14px;
  border-top: 0.1rem solid #b9c7fb;
  border-bottom: 0.1rem solid #dce4fd;
  padding: 1.6rem !important;
  color: #596d97;
  background-color: #eef4ff;
  white-space: nowrap;
  cursor: pointer;
}
wr-angular-table .mat-header-cell.cdk-drag:first-child {
  border-left: none;
}
wr-angular-table .mat-header-cell.total p, wr-angular-table .mat-header-header-cell.total p {
  font-weight: 500;
}

/*
text-decoration: underline cannot be removed from a child.
So to be able to prevent a child from being styled with it,
we have to apply the text decoration directly to the children.
*/
wr-angular-table .cell-link:hover {
  text-decoration: none;
}
wr-angular-table .cell-link:hover p,
wr-angular-table .cell-link:hover span {
  text-decoration: underline;
}
wr-angular-table .cell-link:hover .no-link-style p,
wr-angular-table .cell-link:hover .no-link-style span {
  text-decoration: none;
}

*:focus {
  outline: 0;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: not-allowed;
  background-color: #eeeeee;
  opacity: 1;
}

.redactor-box {
  border-style: none !important;
}

.collapse__panel__group__first wr-collapse-panel {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-style: none;
}

.collapse__panel__group__item wr-collapse-panel {
  border-radius: 0;
  border-bottom-style: none;
}

.collapse__panel__group__last {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.integration-tooltip {
  width: 300px;
  white-space: pre-line;
}

.loading-msg {
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #2e3862;
  font-weight: 500;
  font-size: 1.4rem;
}

wr-select wr-input .input:focus, wr-select wr-input .input.focused, wr-select wr-input .input:active,
wr-multi-select wr-input .input:focus,
wr-multi-select wr-input .input.focused,
wr-multi-select wr-input .input:active {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #dfe3ec !important;
}

.btext--success {
  background-color: #c7f0d2;
}
.btext--success p,
.btext--success span {
  background-color: #c7f0d2;
}

.btext--danger {
  background-color: #fac5cd;
}
.btext--danger p,
.btext--danger span {
  background-color: #fac5cd;
}