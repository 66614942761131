@import '@angular/cdk/a11y-prebuilt.css';

// shared lib
@import 'foundation/helper.scss';
@import 'foundation/toastr.scss';
@import 'foundation/redactor.scss';
@import 'foundation/spinner.scss';
@import 'foundation/career-site.scss';
@import 'responsive-img.scss';

// core lib
@import 'core-components-style.scss';
@import 'date-picker.scss';

// wr-components lib
@import 'fonts.scss';
@import 'redactor-modal-box.scss';
@import 'asset-usages-virtual-scroll.scss';

// Components

/* include border and padding in element width and height */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Wrap new scrollbar properties in @supports rule for browsers without `::-webkit-scrollbar-*` support */
/* This way chrome won't override `::-webkit-scrollbar-*` selectors */
@supports not selector(::-webkit-scrollbar) {
  html,
  body {
    scrollbar-width: auto;
    scrollbar-color: $neutral--7 $neutral--9;
  }
}

// Chrome, Edge, Safari, Opera scrollbar
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: $neutral--7;
  border-radius: 5px;
}
html::-webkit-scrollbar-thumb:hover,
body::-webkit-scrollbar-thumb:hover {
  background-color: $neutral--6;
}

html::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background-color: $neutral--9;
  border-radius: 5px;
}

//thiner for the rest of the app
@supports not selector(::-webkit-scrollbar) {
  html body .wr-app,
  html body wr-overlay {
    scrollbar-width: thin;
    scrollbar-color: $neutral--7 $neutral--9;
  }
}

// Chrome, Edge, Safari, Opera scrollbar
html body .wr-app *::-webkit-scrollbar,
html body wr-overlay *::-webkit-scrollbar {
  // same as thin for firefox
  width: 7px;
  height: 6px;
}

html body .wr-app *::-webkit-scrollbar-thumb,
html body wr-overlay *::-webkit-scrollbar-thumb {
  background-color: $neutral--7;
  border-radius: 5px;
}
html body .wr-app *::-webkit-scrollbar-thumb:hover,
html body wr-overlay *::-webkit-scrollbar-thumb:hover {
  background-color: $neutral--6;
}

html body .wr-app *::-webkit-scrollbar-track,
html body wr-overlay *::-webkit-scrollbar-track {
  background-color: $neutral--9;
  border-radius: 5px;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  // to make 1rem correspond with 10px, easier calculations
  font-size: 62.5%;
  height: 100vh;
  // max-width: 100vw;

  // Selection
  ::-moz-selection {
    background-color: $primary--5;
    color: $neutral--10;
  }

  ::selection {
    background-color: $primary--5;
    color: $neutral--10;
  }

  body {
    @include txt--caption;
    color: $neutral--2;
    background-color: $mpleo--background;
    position: relative;
    width: 100vw;
    // To be resolved : causes issues with redactor but prettier scrollbar below the navbar
    // height: 100vh;
    // overflow: hidden;
    // Solution
    // min-height: 100vh;
    min-height: 100%; // for absolute panel in layouts
    overflow-x: hidden;

    p {
      margin: 0;
    }

    &.dialog-open,
    &.sm-nav-open {
      overflow: hidden;
    }

    .tox-tinymce-aux {
      z-index: 25;
    }
    :last-child.tox-tinymce-aux {
      // for tinymce otherwise some lists go under it
      // targetting last child to narrow it down to dialog since they get added last to the dom it should be the last item, not bulletproof
      z-index: 802; // 801 = dialog z-index
    }

    // we want the switch holdings btn text to wrap
    .nav__container__left {
      .holding {
        .btn__content {
          white-space: normal;

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
        }
      }
    }
  }

  wr-root {
    height: 100%;
  }
}

.wr-app.secNavDisplayed {
  main {
    height: calc(100vh - (#{$navbar-height} * 2)) !important; // minus navbar
  }

  .col-layout {
    .sticky {
      .col-layout {
        &__left-panel,
        &__right-panel {
          &__content {
            top: calc(#{$navbar-height} * 2) !important; // navbars
          }
        }
      }
    }

    .scrollable {
      top: calc(#{$navbar-height} * 2) !important; // navbars
      height: calc(100vh - (#{$navbar-height} * 2)) !important; // minus navbars
    }
  }
}

// To use on a form element (input, select, ...)
// If you use bs rows, use it on the col
.form-item:not(:last-of-type) {
  @include m--side(bottom, 5);
}

// ngBootstrap and native overrides
.tooltip {
  z-index: 1101;
  white-space: break-spaces;
  max-width: 50vw;

  &-inner,
  & > .arrow {
    display: none;
  }

  &-inner {
    @include txt--caption;
    @include p(2);
    color: $neutral--10;
    background-color: $neutral--0;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    a {
      text-decoration: underline;
    }
  }

  &.bs-tooltip-top .arrow::before {
    border-top-color: $neutral--0;
  }

  &.bs-tooltip-right .arrow::before {
    border-right-color: $neutral--0;
  }

  &.bs-tooltip-bottom .arrow::before {
    border-bottom-color: $neutral--0;
  }

  &.bs-tooltip-left .arrow::before {
    border-left-color: $neutral--0;
  }
}

// old reboot.scss
a:hover {
  text-decoration: underline;
}

a,
.default-link {
  cursor: pointer;
  color: $primary--5;
  @include transition(color);
  text-decoration: none;

  &:hover {
    color: $primary--2;
  }
}

.btn-link {
  text-decoration: none;
}

label {
  margin-bottom: 0;
}

.page-title {
  @include txt--display;
  color: $neutral--2;
}

.section-title {
  @include txt--content--lg--bold;
  color: $neutral--2;
}
.modal__section-title {
  @include txt--content--bold;
  color: $neutral--3;
}

// helpful

.overflow {
  &--hidden {
    overflow: hidden;
  }
}

.cursor {
  &-default {
    // using important here, no point using default except overwriting another
    cursor: default !important;
  }

  &-pointer {
    cursor: pointer;
  }

  &-move {
    cursor: move;
  }

  .invisible {
    visibility: hidden;
  }
}

.invisible {
  visibility: hidden;
}

.txt-decoration {
  &--hover {
    &--underline {
      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  &--underline {
    text-decoration: underline !important;
  }

  &--none {
    text-decoration: none !important;
  }
}

.txt--truncate {
  max-width: 100%;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin txt--truncate {
  max-width: 100%;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.txt--uppercase {
  text-transform: uppercase;
}

.txt--line-through {
  text-decoration-line: line-through;
  text-decoration-color: inherit;
}

.txt--disabled {
  color: $neutral--5;
}

.m--b--intercom {
  margin-bottom: $m--b--intercom;
}

.whitespace {
  &--nowrap {
    white-space: nowrap;
  }
}

.flex-grow {
  &-2 {
    -webkit-box-flex: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
  }

  &-3 {
    -webkit-box-flex: 3;
    -ms-flex-positive: 3;
    flex-grow: 3;
  }
}

.full-opacity {
  opacity: 1 !important;
}

.mandatory {
  color: $danger--8;
  margin-right: 5px;
}

.list-style-none {
  @include p(0);

  &,
  & li {
    list-style-type: none;
  }
}

.reset-min-width {
  min-width: 1px;
}

.bullet-sticker {
  width: $spacing--3;
  height: $spacing--3;
  @include bor-rad(4);

  &--sm {
    width: $spacing--2;
    height: $spacing--2;
  }

  &--lg {
    width: $spacing--4;
    height: $spacing--4;
  }
}

// override default checkboxes
input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  @include bor($neutral--6);
  border-radius: 0.3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  width: 1.8rem !important;
  height: 1.8rem !important;
  cursor: pointer;
  background-color: $neutral--9;
  overflow: hidden;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  @include m--side(right, 3);

  &:checked {
    @include bor($primary--6);
    background-color: $primary--6 !important;

    &::after {
      color: $neutral--10;
      @include font-awesome;
      content: '\f00c';
      font-weight: 900;
      font-size: 1.1rem;
      position: absolute;
      line-height: 1.2;
    }
  }

  &:focus {
    @include bor--focus;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    background-color: $neutral--8;

    &:checked {
      @include bor($neutral--6);
      background-color: $neutral--5 !important;
    }
  }
}
.select__wrapper .select__option__container wr-checkbox .checkbox__container {
  color: $neutral--4;
}

// Foundation
.bg {
  @include add-deep-modifier(background-color, $color);
}

.txt {
  @include add-deep-modifier(color, $color);

  &--hover {
    @each $theme, $values in $color {
      &--#{$theme} {
        @each $key, $value in $values {
          &--#{$key} {
            &:hover {
              color: $value !important;
            }
          }
        }
      }
    }
  }

  &--important {
    @each $theme, $values in $color {
      &--#{$theme} {
        @each $key, $value in $values {
          &--#{$key} {
            color: $value !important;
          }
        }
      }
    }
  }

  &--caption {
    @include txt--caption;

    &--bold {
      @include txt--caption--bold;
    }

    &--sm {
      @include txt--caption;
    }
  }

  &--content {
    @include txt--content;

    &--bold {
      @include txt--content--bold;
    }

    &--lg {
      @include txt--content--lg;

      &--bold {
        @include txt--content--lg--bold;
      }
    }
  }

  &--title {
    @include txt--title;

    &--xs {
      @include txt--title--xs;

      &--bold {
        @include txt--title--xs--bold;
      }
    }

    &--sm {
      @include txt--title--xs--bold;
    }
  }

  &--display {
    @include txt--display;
  }
}

@include custom-icons-gen;

.custom-icon.thumb-down {
  transform: scale(-1, 1);
}

.custom-icon.lucca {
  background: url('./../images/lucca.svg') no-repeat center center;
}

.ico {
  vertical-align: middle;
  @include transition(color, background-color);
  $ico: &;

  &--xs {
    @include icon--size('xs');

    &#{$ico} {
      @include icon--color--modifier();
    }
  }

  &--sm {
    @include icon--size('sm');

    &#{$ico} {
      @include icon--color--modifier();
    }
  }

  &--md {
    @include icon--size('md');

    &#{$ico} {
      @include icon--color--modifier();
    }
  }

  &--lg,
  &--xl {
    display: block;
    @include icon--size('lg');
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    text-align: center;
    // effective vertical centering if only one line of text is expected
    line-height: 3.5rem;

    &#{$ico} {
      @include default-color(true);
      @include lg-custom-color();
    }
  }

  &--xl {
    @include icon--size('xl');
    width: 3.8rem;
    height: 3.8rem;
    line-height: 3.8rem;
  }

  &--xxl {
    @include icon--size('xxl');
    width: 4rem;
    height: 4rem;
    line-height: 4rem;

    &#{$ico} {
      @include icon--color--modifier();
    }
  }

  &--menu-icon {
    @include icon--size('menu-icon');

    &#{$ico} {
      @include icon--color--modifier();
    }
  }
}

.icon,
.ico {
  &.spin {
    &::before {
      display: block;
      -webkit-animation: wr-spin 2s linear infinite;
      animation: wr-spin 2s linear infinite;
    }
  }

  &.reverse {
    &::before {
      display: block;
      -webkit-transform: scale(-1, 1);
      -ms-transform: scale(-1, 1);
      transform: scale(-1, 1);
    }
  }
}

@-webkit-keyframes wr-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes wr-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.candidate-without-name {
  color: $warning--5 !important;
  background-color: rgba($warning--10, 0.3) !important;
}

//can be used with values (recommanded) or classic parameters
.transition {
  &--none {
    @include transition(all $duration--0);
  }

  &--fast {
    @include transition(all $duration--1);
  }

  &--default {
    @include transition(all $duration--2);
  }

  &--slow {
    @include transition(all $duration--3);
  }
}

.sh {
  &--sm {
    @include sh--sm;

    &--primary {
      @include sh--sm--primary;
    }
  }

  &--md {
    @include sh--md;

    &--primary {
      @include sh--md--primary;
    }
  }

  &--lg {
    @include sh--lg;

    &--primary {
      @include sh--lg--primary;
    }
  }
}

.bor {
  @include add-deep-modifier(
    border-color,
    $color,
    (
      border-width: 1px,
      border-style: solid,
    )
  );

  &-rad {
    @include add-modifier(border-radius, $radius);

    &--top-right {
      @include add-modifier(border-top-right-radius, $radius);
    }

    &--top-left {
      @include add-modifier(border-top-left-radius, $radius);
    }

    &--bottom-right {
      @include add-modifier(border-bottom-right-radius, $radius);
    }

    &--bottom-left {
      @include add-modifier(border-bottom-left-radius, $radius);
    }
  }

  &--default {
    @include bor;
  }

  &--focus {
    // you can't directly change the width of the border in focus, otherwise the input will shift
    // increase border width with a box-shadow
    // solution only valid for inputs, however
    @include bor($primary--6);
    -webkit-box-shadow: 0 0 0 1px $primary--6, 0 0 0 4px $primary--9;
    box-shadow: 0 0 0 1px $primary--6, 0 0 0 4px $primary--9;
  }

  &--t {
    @include add-deep-modifier(
      border-top-color,
      $color,
      (
        border-top-width: 1px,
        border-top-style: solid,
      )
    );

    &--default {
      @include bor--side(top);
    }
  }

  &--r {
    @include add-deep-modifier(
      border-right-color,
      $color,
      (
        border-right-width: 1px,
        border-right-style: solid,
      )
    );

    &--default {
      @include bor--side(right);
    }
  }

  &--b {
    @include add-deep-modifier(
      border-bottom-color,
      $color,
      (
        border-bottom-width: 1px,
        border-bottom-style: solid,
      )
    );

    &--default {
      @include bor--side(bottom);
    }
  }

  &--l {
    @include add-deep-modifier(
      border-left-color,
      $color,
      (
        border-left-width: 1px,
        border-left-style: solid,
      )
    );

    &--default {
      @include bor--side(left);
    }
  }

  &--y {
    @include add-deep-modifier(
      border-top-color,
      $color,
      (
        border-top-width: 1px,
        border-top-style: solid,
      )
    );
    @include add-deep-modifier(
      border-bottom-color,
      $color,
      (
        border-bottom-width: 1px,
        border-bottom-style: solid,
      )
    );

    &--default {
      @include bor--side(top);
      @include bor--side(bottom);
    }
  }

  &--x {
    @include add-deep-modifier(
      border-right-color,
      $color,
      (
        border-right-width: 1px,
        border-right-style: solid,
      )
    );
    @include add-deep-modifier(
      border-left-color,
      $color,
      (
        border-left-width: 1px,
        border-left-style: solid,
      )
    );

    &--default {
      @include bor--side(right);
      @include bor--side(left);
    }
  }
}

.m {
  @include add-modifier(margin, $spacing);

  &--t {
    @include add-modifier(margin-top, $spacing);
  }

  &--r {
    @include add-modifier(margin-right, $spacing);
  }

  &--b {
    @include add-modifier(margin-bottom, $spacing);
  }

  &--l {
    @include add-modifier(margin-left, $spacing);
  }

  &--y {
    @include add-modifier(margin-top, $spacing);
    @include add-modifier(margin-bottom, $spacing);
  }

  &--x {
    @include add-modifier(margin-right, $spacing);
    @include add-modifier(margin-left, $spacing);
  }

  @each $key, $value in $media {
    @media (min-width: $value) {
      &--#{$key} {
        @include add-modifier(margin, $spacing);
      }

      &--t {
        &--#{$key} {
          @include add-modifier(margin-top, $spacing);
        }
      }

      &--r {
        &--#{$key} {
          @include add-modifier(margin-right, $spacing);
        }
      }

      &--b {
        &--#{$key} {
          @include add-modifier(margin-bottom, $spacing);
        }
      }

      &--l {
        &--#{$key} {
          @include add-modifier(margin-left, $spacing);
        }
      }

      &--y {
        &--#{$key} {
          @include add-modifier(margin-top, $spacing);
          @include add-modifier(margin-bottom, $spacing);
        }
      }

      &--x {
        &--#{$key} {
          @include add-modifier(margin-right, $spacing);
          @include add-modifier(margin-left, $spacing);
        }
      }
    }
  }
}

.p {
  @include add-modifier(padding, $spacing);

  &--t {
    @include add-modifier(padding-top, $spacing);

    @each $key, $val in $spacing {
      &--#{$key}--reverse {
        @include p--side--reverse(top, $key);
      }
    }
  }

  &--r {
    @include add-modifier(padding-right, $spacing);

    @each $key, $val in $spacing {
      &--#{$key}--reverse {
        @include p--side--reverse(right, $key);
      }
    }
  }

  &--b {
    @include add-modifier(padding-bottom, $spacing);

    @each $key, $val in $spacing {
      &--#{$key}--reverse {
        @include p--side--reverse(bottom, $key);
      }
    }
  }

  &--l {
    @include add-modifier(padding-left, $spacing);

    @each $key, $val in $spacing {
      &--#{$key}--reverse {
        @include p--side--reverse(left, $key);
      }
    }
  }

  &--y {
    @include add-modifier(padding-top, $spacing);
    @include add-modifier(padding-bottom, $spacing);
  }

  &--x {
    @include add-modifier(padding-right, $spacing);
    @include add-modifier(padding-left, $spacing);
  }

  @each $key, $value in $media {
    @media (min-width: $value) {
      &--#{$key} {
        @include add-modifier(padding, $spacing);
      }

      &--t {
        &--#{$key} {
          @include add-modifier(padding-top, $spacing);
        }
      }

      &--r {
        &--#{$key} {
          @include add-modifier(padding-right, $spacing);
        }
      }

      &--b {
        &--#{$key} {
          @include add-modifier(padding-bottom, $spacing);
        }
      }

      &--l {
        &--#{$key} {
          @include add-modifier(padding-left, $spacing);
        }
      }

      &--y {
        &--#{$key} {
          @include add-modifier(padding-top, $spacing);
          @include add-modifier(padding-bottom, $spacing);
        }
      }

      &--x {
        &--#{$key} {
          @include add-modifier(padding-right, $spacing);
          @include add-modifier(padding-left, $spacing);
        }
      }
    }
  }
}

.height {
  &--caption--sm {
    height: 1.5rem;
  }

  &--caption,
  &--content,
  &--content--bold {
    height: 1.6rem;
  }

  &--content--lg {
    height: 1.9rem;
  }

  &--display {
    height: 4rem;
  }

  @include add-modifier(height, $spacing);
}

// Drag and drop preview
.cdk {
  &-drag {
    position: relative;

    &-preview {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      @include sh--md;
      @include bor-rad(3);
      background-color: $neutral--10;
      opacity: 0.85;
      border: 2px solid $primary--8;
      overflow: hidden;
    }

    &-placeholder {
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        @include bor-rad(3);
        border: 1px dashed $primary--9;
        background-color: $primary--10;
        z-index: 1;
        -webkit-box-shadow: inset 0px 1px 1px add-alpha($neutral--0, '3'), inset 0px 2px 2px add-alpha($neutral--0, '3'),
          inset 0px 4px 4px add-alpha($neutral--0, '2');
        box-shadow: inset 0px 1px 1px add-alpha($neutral--0, '3'), inset 0px 2px 2px add-alpha($neutral--0, '3'),
          inset 0px 4px 4px add-alpha($neutral--0, '2');
      }

      & > * {
        opacity: 0;
      }
    }

    &-animating {
      @include transition(transform $duration--1);
    }
  }

  &-drop-list-dragging .cdk-drag {
    -webkit-transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1);
    transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1);
    -o-transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1), -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1);
  }
}

tr.cdk-drag-placeholder {
  display: block;
}

// Mentions
.mention-link {
  color: $primary--5 !important;
}

//Style links in inline-msg component content
.inline-msg {
  @each $color-theme, $option-map in $color {
    &--#{$color-theme} {
      .inline-msg__content a {
        text-decoration: underline;
        color: map-get($option-map, '2');

        &:hover {
          color: map-get($option-map, '1');
        }
      }

      &--ghost {
        .inline-msg__content a {
          color: map-get($option-map, '3');

          &:hover {
            color: map-get($option-map, '2');
          }
        }
      }
    }
  }
}
.inline-msg__content a {
  text-decoration: underline !important;
}
.inline {
  display: inline;
}

body mention-list,
body .dropdown-menu.mention-menu {
  // z-index: 23;
  // they can be in a modal > WR-812
  z-index: 804;
}

body .dropdown-menu.mention-menu {
  display: block !important;
  position: absolute;
  background-color: $neutral--10;
  @include bor-rad(2);
  @include bor;
  @include p(5);
  @include m(0);
  min-width: calc(#{$spacing--2} * 10);
  max-width: calc(#{$spacing--7} * 10);
  max-height: calc(#{$spacing--11} * 2);
  overflow: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
  @include txt--content;
  @include transition(opacity $duration--1, margin-top);
  -webkit-transition-delay: 0.5ms;
  -o-transition-delay: 0.5ms;
  transition-delay: 0.5ms;
  height: auto;
  opacity: 1;
  visibility: visible;

  &[hidden] {
    margin-top: -#{$spacing--2};
    height: 1px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
  }

  .mention-active {
    .mention-item {
      color: $primary--2;
      background-color: $primary--10;
    }
  }

  .mention-item {
    color: $neutral--3;
    @include bor-rad(2);
    @include transition(color, background-color);
    @include txt--content;
    padding: $spacing--3;
    border: none;
    @include txt--truncate;

    &:hover {
      color: $primary--2;
      background-color: $primary--10;
    }
  }
}

.innerHTML-mail {
  word-break: break-word;

  figure,
  img {
    max-width: 100%;
    height: auto;
  }

  table {
    max-width: 100%;
    width: auto !important;
  }

  // conversation
  div {
    blockquote {
      @include m--y(3);
      @include p--side(left, 5);
      @include bor--side(left);
    }
  }

  &.one-line {
    &,
    & * {
      // txt--caption
      font-size: 1.3rem !important;
      font-weight: normal !important;
      text-align: left;
      @include p(0);
    }

    figure,
    img {
      display: none;
    }
  }
}

.coopmi-desc__content {
  @include description;

  h2,
  h3 {
    @include section-title;
  }
}

// virtual scroll specific styles
wr-holding-switch-dialog {
  cdk-virtual-scroll-viewport {
    .cdk-virtual-scroll-content-wrapper {
      @include p--side(left, 5);
      width: 100%;
    }
  }
}

wr-candidate-list {
  cdk-virtual-scroll-viewport {
    .cdk-virtual-scroll-content-wrapper {
      right: 0;
    }
  }
}

#intercom-frame,
.intercom-lightweight-app {
  display: none !important;
  visibility: hidden !important;
}

.txt-infos:not(:empty) {
  display: block;
  @include txt--caption;
  @include p--side(bottom, 2);
  color: $neutral--5;
}

// START | modal custom footer
.dialog__modal__footer {
  &,
  & > div[footer] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-flow: column-reverse wrap;
    flex-flow: column-reverse wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    wr-btn {
      &:not(:last-of-type) {
        @include m--side(top, 3);
      }
    }
  }
}

@media (min-width: $media--sm) {
  .dialog__modal__footer {
    &,
    & > div[footer] {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;

      wr-btn {
        @include m--side(bottom, 3);
        &:not(:last-of-type) {
          @include m--side(top, 0);
        }
        &:not(:first-of-type) {
          @include m--side(left, 3);
        }
      }
    }
  }
}
// END | modal custom footer

.stats-tooltip {
  span {
    padding-left: $spacing--3;
  }
}

@media (min-width: $media--sm) {
  // html {
  // body {
  // to resolve bug of scrollbar overflowing on content
  // padding-right: 6px;
  // }
  // }

  .tooltip {
    &-inner,
    & > .arrow {
      display: block;
    }
  }
}

@media (min-width: $media--md) {
  #intercom-frame,
  .intercom-lightweight-app {
    display: block !important;
    visibility: visible !important;
  }
}

// Exceptions for the chrome extension
.extension {
  .tooltip {
    &-inner,
    & > .arrow {
      display: block;
    }
  }
}

// ------------------------------------------------------------------
// START angular material table style
// ------------------------------------------------------------------

wr-angular-table,
wr-table {
  .table__Tmpl__Applications {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    max-width: calc(10 * (#{$spacing--9}));
  }

  .table__Tmpl__ApplicationStates {
    line-height: 1.8rem;
  }
}

wr-angular-table .mat-sort-header-container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
wr-angular-table .mat {
  &-sort {
    &-header {
      &-container {
        justify-content: flex-start;
      }
      &-arrow {
        @include p--side(left, 3);
        -webkit-transform: translateY(0px) !important;
        -ms-transform: translateY(0px) !important;
        transform: translateY(0px) !important;
      }

      // We want to have the same UI to sort between tables
      // Hide default arrow as its composed of left, right and middle
      &-stem,
      &-pointer-left,
      &-pointer-right,
      &-pointer-middle {
        display: none;
      }

      &:hover {
        .mat-sort-header-container:not(.mat-sort-header-sorted) {
          .mat-sort-header-arrow {
            opacity: 1;
            .mat-sort-header-indicator::before {
              @include font-awesome;
              content: '\f0dc';
              font-weight: 900;
              top: 1px;
              position: absolute;
              font-size: $icon--size--sm;
              color: $neutral--5;
            }
          }
        }
      }
      &-container:not(.mat-sort-header-sorted) {
        .mat-sort-header-arrow .mat-sort-header-indicator::before {
          color: $neutral--3;
        }
      }
      // custom ascending arrow
      &[aria-sort='ascending'] {
        .mat-sort-header-arrow {
          .mat-sort-header-indicator {
            &::before {
              @include font-awesome;
              content: '\f0de';
              font-weight: 900;
              position: absolute;
              font-size: $icon--size--sm;
              color: $neutral--3;
              top: 3px;
            }
          }
        }
      }

      // custom descending arrow
      &[aria-sort='descending'] {
        .mat-sort-header-arrow {
          .mat-sort-header-indicator {
            &::before {
              @include font-awesome;
              content: '\f0dd';
              font-weight: 900;
              position: absolute;
              font-size: $icon--size--sm;
              color: $neutral--3;
              top: -12px;
            }
          }
        }
      }
    }
  }
  &-header {
    &-cell {
      // /!\ copied from angular-table.component.scss,
      // had to make it a global style since the cdk will remove it from its natural DOM positioning
      &.cdk-drag {
        color: $neutral--1;
        @include txt--content--bold;
        @include bor--side(top, $primary--9);
        @include bor--side(bottom, $primary--10);

        padding: $spacing--5 !important;
        color: $neutral--4;
        background-color: $primary--11;
        white-space: nowrap;

        &:first-child {
          border-left: none;
        }

        cursor: pointer;
      }
    }
    &-cell.total,
    &-header-cell.total {
      p {
        font-weight: 500;
      }
    }
  }
}
/*
text-decoration: underline cannot be removed from a child.
So to be able to prevent a child from being styled with it,
we have to apply the text decoration directly to the children.
*/
wr-angular-table .cell-link {
  &:hover {
    text-decoration: none;
    p,
    span {
      text-decoration: underline;
    }
    .no-link-style {
      p,
      span {
        text-decoration: none;
      }
    }
  }
}
// ------------------------------------------------------------------
// END angular material table style
// ------------------------------------------------------------------

// ------------------------------------------------------------------
// START | styles.scss moved here, didnt check yet if the fixes were relevant
// ------------------------------------------------------------------
*:focus {
  outline: 0;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: not-allowed;
  background-color: #eeeeee;
  opacity: 1;
}

//  Redactor styles
.redactor-box {
  border-style: none !important;
}

// Collapse panel group
.collapse__panel__group__first {
  wr-collapse-panel {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-style: none;
  }
}

.collapse__panel__group__item {
  wr-collapse-panel {
    border-radius: 0;
    border-bottom-style: none;
  }
}

.collapse__panel__group__last {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.integration-tooltip {
  width: 300px;
  white-space: pre-line;
}

.loading-msg {
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: $primary--1;
  font-weight: 500;
  font-size: 1.4rem;
}

// ------------------------------------------------------------------------
// END | styles.scss moved here, didnt check yet if the fixes were relevant
// ------------------------------------------------------------------------

// removing style of nested labels in wr-select, wr-multiselect
wr-select,
wr-multi-select {
  wr-input .input {
    &:focus,
    &.focused,
    &:active {
      outline: none !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      border-color: $bor--default-color !important;
    }
  }
}

.btext--success {
  background-color: $change--added-0;
  p,
  span {
    background-color: $change--added-0;
  }
}
.btext--danger {
  background-color: $change--removed--0;
  p,
  span {
    background-color: $change--removed--0;
  }
}
