@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

/* include border and padding in element width and height */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  // Firefox scrollbar (the emerging W3C standard)
  scrollbar-width: thin;
  scrollbar-color: darken(#e0e3eb, 3%) #e9ebf1;
}

// Chrome, Edge, Safari, Opera scrollbar
*::-webkit-scrollbar {
  // same as thin for firefox
  width: 7px;
  height: 6px;
}

*::-webkit-scrollbar {
  background-color: #e9ebf1;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: darken(#e0e3eb, 3%);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #bfc5d3;
}

html {
  // to make 1rem correspond with 10px, easier calculations
  font-size: 62.5%;
  position: relative;
  min-height: 100%;
  font-family: "Roboto";

  // Selection
  ::-moz-selection {
    background-color: #4c88ff;
    color: #fdfdfd;
  }

  ::selection {
    background-color: #4c88ff;
    color: #fdfdfd;
  }
}

body {
  margin-bottom: 60px;
  color: #3a4359;
  background-color: #f2f5f9;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: normal;
  height: 100%;
}

app-root {
  .loading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 10px;
    width: 25%;
    min-width: 75px;
    max-width: 125px;

    &__container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 10px;
      width: 100%;
      height: 100%;
    }

    svg {
      overflow: visible;
      max-width: 100%;
      max-height: 100%;
    }

    &__text,
    &__round,
    .dashed--circle {
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;

      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }

    &__text {
      text-align: center;
      margin-top: 15px;

      -webkit-animation-duration: 1.8s;
      animation-duration: 1.8s;

      -webkit-animation-name: opacity;
      animation-name: opacity;

      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    &__circle {
      fill: rgba(#b7d7ff, 0.6);

      &.dashed--circle {
        -webkit-animation-name: rotate;
        animation-name: rotate;

        -webkit-transform-origin: center;
        -ms-transform-origin: center;
        transform-origin: center;

        -webkit-animation-duration: 2s;
        animation-duration: 2s;

        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
      }
    }

    &__round {
      -webkit-animation-name: rotate;
      animation-name: rotate;

      // because the round isnt centered on its circle
      -webkit-transform-origin: 49% 49%;
      -ms-transform-origin: 49% 49%;
      transform-origin: 49% 49%;

      &.round {
        &--orange {
          -webkit-animation-duration: 2s;
          animation-duration: 2s;

          -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        }
        &--pink {
          -webkit-animation-duration: 1.8s;
          animation-duration: 1.8s;

          -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
        }
      }
    }
  }
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
